import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Switch } from "@mui/material";
import useViewport from "../../../../hooks/useViewport";
import { Button, Card, Col } from "react-bootstrap";
import Users from "../Users";
import InviteNewUser from "./InviteNewUser";
import UserDetail from "./UserDetail";
import { useProfile } from "../../../../context/UserProfileContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useGeneral } from "../../../../context/GeneralContext";
import { Auth } from "aws-amplify";

const UsersPanel = (props) => {
  const [isInviteNewUser, setIsInviteNewUser] = useState(false);
  const [personalUserId, setPersonalUserId] = useState(null);
  const { children, value, index, next, prev, ...other } = props;
  const { width: screenWidth } = useViewport();
  const inviteNewUserRef = useRef(null);
  const userDetailRef = useRef(null);
  const { data = {} } = useProfile();
  const navigate = useNavigate();
  const [isAllowed, setIsAllowed] = useState(true);
  const { setAlertModal } = useGeneral();
  const [userData, setUserData] = useState("");

  const handleInviteUserSubmit = async () => {
    const res = await inviteNewUserRef.current.inviteNewUser();
    if (res) {
      setIsInviteNewUser(false);
    }
  };

  const handelChangeUserSubmit = async () => {
    const res = await userDetailRef.current.saveChagnes();
    if (res) setPersonalUserId(null);
  };

  const showAlert = useCallback(
    (title, content) => {
      setAlertModal({ show: true, title, content });
    },
    [setAlertModal]
  );

  const getSettings = useCallback(async () => {
    try {
      const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/`;
      const token = await Auth.currentSession();

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      });
      if (response.status === 200) {
        setUserData(response.data);
      } else {
        setAlertModal({
          show: true,
          title: "Error",
          content: "Error Getting Settings",
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.signOut()
          .then(() => {
            navigate("/");
          })
          .catch((error) => {
            console.log("error signing out: ", error);
          });
      } else {
        setAlertModal({
          show: true,
          title: "Error",
          content: "Error Getting Settings",
        });
      }
    }
  }, []);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const getUserInfo = useCallback(
    (token) => {
      var url =
        process.env.REACT_APP_NODE_API_URL +
        "/api/users/allowAgentsToViewUsers";
      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          if (response.status === 200) {
            setIsAllowed(response.data.allowAgentsToViewUsers);
          } else {
            showAlert("Error", "Error Getting User Info");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut()
              .then(() => {
                navigate("/admin/login");
              })
              .catch((error) => {
                console.log("Error signing out: ", error);
              });
          } else {
            showAlert("Error", "Error Getting User Info");
          }
        });
    },
    [navigate, showAlert]
  );

  useEffect(() => {
    async function getTokenForUserInfo() {
      try {
        const token = await Auth.currentSession();
        if (!token) {
          navigate("/admin/login");
        }
        getUserInfo(token.getIdToken().getJwtToken());
      } catch (error) {
        navigate("/admin/login");
      }
    }
    getTokenForUserInfo();
  }, [getUserInfo, navigate]);

  const handleAllowView = async (e) => {
    setIsAllowed(e.target.checked);

    try {
      const token = await Auth.currentSession();
      const response = await axios.patch(
        `${process.env.REACT_APP_NODE_API_URL}/api/users/allowAgentsToViewUsers`,
        { allowAgentsToViewUsers: e.target.checked },
        {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setAlertModal({
          show: true,
          title: "Saved",
          content: "Setting Updated Successfully",
        });
      } else {
        throw new Error("Error Updating Setting");
      }
    } catch (error) {
      handleAuthError(error);
    }
  };

  const handleAuthError = (error) => {
    if (error?.response?.status === 401) {
      Auth.signOut()
        .then(() => {
          navigate("/");
        })
        .catch((signOutError) => {
          console.log("Error signing out:", signOutError);
        });
    } else {
      setAlertModal({
        show: true,
        title: "Error",
        content: "Error Updating Setting",
      });
    }
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="h-100"
    >
      {value === index && (
        <>
          {["admin", "superadmin"].includes(data.role) && !userData.adminId && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="px-4 px-lg-5 pt-4"
            >
              <div>Allow agents to view users in the company</div>
              <Switch onChange={handleAllowView} checked={isAllowed} />
            </Box>
          )}

          <Box className="px-4 px-lg-5 pt-3">
            <Card style={{ border: "1px solid #EAEAEA", color: "#5f5f5f" }}>
              <Card.Header as={"h5"}>
                {isInviteNewUser
                  ? "NEW USER"
                  : personalUserId
                  ? "PERSONAL INFORMATION"
                  : "USERS"}
              </Card.Header>
              <Card.Body>
                {isInviteNewUser ? (
                  <InviteNewUser ref={inviteNewUserRef} />
                ) : personalUserId ? (
                  <UserDetail
                    ref={userDetailRef}
                    agentId={personalUserId}
                    setPersonalUserId={setPersonalUserId}
                  />
                ) : (
                  <Users
                    setIsInviteNewUser={setIsInviteNewUser}
                    setPersonalUserId={setPersonalUserId}
                  />
                )}
              </Card.Body>
            </Card>
          </Box>
          {screenWidth > 755 ? (
            <div className="position-sticky bottom-0 bg-white py-3 px-4 px-lg-5 d-flex justify-content-between">
              <Col xs="auto">
                {isInviteNewUser ? (
                  <Button
                    className={`px-5 tab-footer-cancel-button`}
                    onClick={() => setIsInviteNewUser(false)}
                  >
                    {"Cancel"}
                  </Button>
                ) : personalUserId ? (
                  <Button
                    className={`px-5 tab-footer-cancel-button`}
                    onClick={() => setPersonalUserId(null)}
                  >
                    {"Cancel"}
                  </Button>
                ) : (
                  <Button className={`px-5 tab-footer-button`} onClick={prev}>
                    {"< Back"}
                  </Button>
                )}
              </Col>
              {isInviteNewUser && (
                <Col xs="auto gap-2 d-flex">
                  <Button
                    className={`px-5 tab-footer-button`}
                    onClick={handleInviteUserSubmit}
                  >
                    {"Invite User"}
                  </Button>
                </Col>
              )}
              {personalUserId && (
                <Col xs="auto gap-2 d-flex">
                  <Button
                    className={`px-5 tab-footer-button`}
                    onClick={handelChangeUserSubmit}
                  >
                    {"Save Changes"}
                  </Button>
                </Col>
              )}
              {/* <Col xs="auto gap-2 d-flex">
                <Button className={`px-5 tab-footer-button`} onClick={next}>
                  {"Continue >"}
                </Button>
              </Col> */}
            </div>
          ) : (
            <div className="d-flex flex-column gap-1 position-sticky bottom-0 bg-white py-3 px-4 px-lg-5">
              {isInviteNewUser && (
                <Col xs={12}>
                  <Button
                    className="tab-footer-button"
                    style={{ width: "100%" }}
                    onClick={handleInviteUserSubmit}
                  >
                    {"Invite User"}
                  </Button>
                </Col>
              )}
              {personalUserId && (
                <Col xs={12}>
                  <Button
                    className="tab-footer-button"
                    style={{ width: "100%" }}
                    onClick={handelChangeUserSubmit}
                  >
                    {"Save Changes"}
                  </Button>
                </Col>
              )}
              <Col xs={12}>
                {isInviteNewUser ? (
                  <Button
                    className="tab-footer-cancel-button"
                    onClick={() => setIsInviteNewUser(false)}
                    style={{ width: "100%" }}
                  >
                    {"Cancel"}
                  </Button>
                ) : personalUserId ? (
                  <Button
                    className="tab-footer-cancel-button"
                    onClick={() => setPersonalUserId(null)}
                    style={{ width: "100%" }}
                  >
                    {"Cancel"}
                  </Button>
                ) : (
                  <Button
                    className="tab-footer-button"
                    onClick={prev}
                    style={{ width: "100%" }}
                  >
                    {"< Back"}
                  </Button>
                )}
              </Col>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UsersPanel;
