import React, { useState, useEffect } from "react";
import { Col, Row, Toast, Tooltip } from "react-bootstrap";

import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { useProperty } from "../../../context/PropertyContext";
import styled from "styled-components";
import OnlineTag from "../../../components/OnlineTag";
import cameraImage from "../../../assests/images/camera-1.png";
import fullScreenImage from "../../../assests/images/full-screen.png";
import placeholderImage from "../../../assests/images/1-placeholder-X2.png";

import { Loader } from "@mantine/core";

import Camera3dEye from "../components/Camera3dEye";
import InfoBanner from "../../../components/Banners/InfoBanner";
import useViewport from "../../../hooks/useViewport";

const StyledCard = styled.div`
  display: flex;
  padding: 0;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
`;

const CameraFeed = () => {
  const { cameras, CameraUrl, cameraLoading, showToast, setShowToast } =
    useProperty();

  const [reloadIndex, setReloadIndex] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const { width: screenWidth } = useViewport();

  const handleFullScreen = (e, index) => {
    const player = document.querySelectorAll(".react-player")[index];
    player?.requestFullscreen();
  };

  let camerasAvailable = cameras.length > 0;

  const renderNoCameras = () => {
    return (
      <div className="content-div d-flex justify-content-center m-4">
        <h3>No Cameras Available</h3>
      </div>
    );
  };

  return !camerasAvailable ? (
    renderNoCameras()
  ) : (
    <div className="content-div px-4 px-lg-5">
      <div className=" d-flex flex-column pt-4 gap-3">
        <InfoBanner>
          <span style={{ fontSize: screenWidth < 755 ? "10px" : "16px" }}>
            The camera stream might take up to 30 seconds to load. Thanks for
            your patience!
          </span>
        </InfoBanner>
        <Row>
          {cameras.map((element, index) => {
            const is3deyeCam = element.make === "ezvizdirect";
            const cameraId = element._id;
            const cameraObject = CameraUrl.find((obj) =>
              obj.hasOwnProperty(cameraId)
            );

            const theCameraUrl = cameraObject
              ? cameraObject[cameraId]
              : "Stream error";
            const isValidUrl = theCameraUrl && theCameraUrl !== "Stream error";
            const currentCameraUrl = isValidUrl ? theCameraUrl : "Stream error";

            return is3deyeCam ? (
              <Col key={element._id} xs={12} md={6} xxl>
                <StyledCard className="mb-4 p-2 d-flex flex-column p-3">
                  <div
                    className="d-flex justify-content-between w-100 align-items-center"
                    key={element._id}
                  >
                    <div className="d-flex align-items-center">
                      <p
                        style={{
                          color: "#979797",
                          fontSize: "13px",
                          textTransform: "uppercase",
                        }}
                      >
                        {" "}
                        CAMERA {element.name}{" "}
                      </p>
                    </div>
                  </div>
                  <Camera3dEye camera={element} updateTrigger={updateTrigger} />
                </StyledCard>
              </Col>
            ) : (
              <Col key={element._id} xs={12} md={6} xxl={4}>
                <StyledCard className="p-3">
                  <div
                    className="d-flex justify-content-between w-100 align-items-center p-2"
                    key={element._id}
                  >
                    <div className="d-flex gap-2">
                      <img
                        src={cameraImage}
                        alt="screen"
                        className=""
                        width={24}
                        height={24}
                      />
                      <h5> {element.name} </h5>
                    </div>
                    <div className="d-flex" style={{ gap: "10px" }}>
                      {/* <Link to="#" onClick={(e) => handleReload(index)}>
                          <img
                            src={reload}
                            alt="screen"
                            width={23}
                            height={23}
                          />
                        </Link> */}
                      <Link to="#" onClick={(e) => handleFullScreen(e, index)}>
                        <img
                          src={fullScreenImage}
                          alt="screen"
                          className=""
                          width={24}
                          height={24}
                        />
                      </Link>
                    </div>
                  </div>
                  <div>
                    {!is3deyeCam &&
                      cameraLoading &&
                      theCameraUrl === "Stream error" && (
                        <div className="position-relative">
                          <img
                            src={placeholderImage}
                            alt="screen"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "0 0 4px 4px",
                            }}
                          />
                          <div className="position-absolute top-50 bottom-50 end-50 start-50">
                            <Loader />
                          </div>
                          <OnlineTag status={"offline"} />
                        </div>
                      )}
                    {!cameraLoading &&
                      theCameraUrl === "Stream error" &&
                      !is3deyeCam && (
                        <div className="position-relative">
                          <img
                            src={placeholderImage}
                            alt="screen"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "0 0 4px 4px",
                            }}
                          />
                          <OnlineTag status={"offline"} />
                        </div>
                      )}
                    {is3deyeCam && <Camera3dEye camera={element} />}
                    {isValidUrl && !is3deyeCam && (
                      <div className="position-relative p-0 m-0">
                        <ReactPlayer
                          key={`${cameraId}-${
                            reloadIndex === index ? Date.now() : ""
                          }`}
                          className="react-player"
                          url={currentCameraUrl}
                          style={{
                            margin: "0px",
                            padding: "0px",
                            borderRadius: "4px",
                          }}
                          playing
                          onError={(e) => console.log(e)}
                        />

                        <OnlineTag status={"online"} />
                      </div>
                    )}
                  </div>
                </StyledCard>
                <Toast
                  key={showToast[element._id]}
                  show={showToast[element._id]}
                  onClose={() =>
                    setShowToast((prev) => ({
                      ...prev,
                      [element._id]: false,
                    }))
                  }
                  style={{ width: "100%" }}
                >
                  <Toast.Header>
                    <strong className="me-auto">Alert</strong>
                    <small>
                      <span style={{ color: "black", fontWeight: "bold" }}>
                        {element.name}
                      </span>{" "}
                      is not available right now
                    </small>
                  </Toast.Header>
                </Toast>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default CameraFeed;
