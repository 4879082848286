export const design = {
  deleteMainColor: "#5271FF"
}

export const keypadInstructions = {
  switchbot: {
    step2_text: "Enter the code on the keypad and press the Check Button. Wait for a beep, then open the door.",
    keypad: '/images/switchbot/keypad.png',
    kpOpen: '/images/switchbot/kp-open.png',
    kpClose: '/images/switchbot/kp-lock.png'
  },
  yale: {
    step2_text: "Press the Yale button on top of the keypad, enter the code and press the Check button. Wait for a beep, then open the door.",
    keypad: '/images/yale/keypad.png',
    kpOpen: '/images/yale/kp-lock.png',
    kpClose: '/images/yale/kp-open.svg'
  },
  august: {
    step2_text: "Enter the code on the keypad and press the August button.",
    keypad: '/images/august/keypad.png',
    kpOpen: '/images/august/kp-lock.png',
    kpClose: '/images/august/kp-lock.png'
  }
};

export const bookingOptions = [
  { value: "scheduled", label: "Scheduled" },
  { value: "show", label: "Show" },
  { value: "missed", label: "No show" },
  { value: "rescheduled", label: "Rescheduled" },
  { value: "cancelled", label: "Cancelled" },
];

export const outcomeOptions = [
  { value: "", label: "Outcome", color: "" },
  { value: "interested", label: "Interested", color: "scheduled"},
  { value: "not interested", label: "Not Interested", color: "cancelled"},
  { value: "send application", label: "Application Sent", color: "rescheduled"},
  { value: "follow up sent", label: "Follow Up Sent", color: "missed"},
  { value: "applied", label: "Applied", color: "show"},
];

export const deviceActions = {
  "LOCK_LOCK": {
    "device": "LOCK",
    "action": "LOCK"
  },
  "LOCK_UNLOCK": {
    "device": "LOCK",
    "action": "UNLOCK"
  },
  "KEYPAD_LOCK": {
    "device": "KEYPAD",
    "action": "LOCK"
  },
  "KEYPAD_UNLOCK": {
    "device": "KEYPAD",
    "action": "UNLOCK"
  },
  "KEYPAD_LOAD": {
    device: "KEYPAD",
    action: "LOAD"
  },
  "KEYPAD_DELETE": {
    device: "KEYPAD",
    action: "DELETE"
  },
  "KEYPAD_UPDATE": {
    device: "KEYPAD",
    action: "UPDATE"
  }
}

export const augustId = {
  autorelock: "Autorelock",
  manuallock: "Manual Lock"
}

export const MAX_NUMBER_CODES = 90

export const CUSTOMER_SUPP_PHONE = "(310) 598-5553"

