import React from "react";
import { Spinner } from "react-bootstrap";
import { IoIosAlert } from "react-icons/io";

const Loading = ({ children }) => {
  return (
    <p className="bg-primary bg-opacity-25 d-flex gap-2 align-items-center justify-content-center rounded-3 text-body-secondary py-2 text-bg-secondary fw-bold">
      <Spinner animation="border" role="status" variant="primary"></Spinner>
      loading...
    </p>
  );
};

export default Loading;
