import React from "react";
import styled from "styled-components";
import { Button, Carousel } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import MobilePropertyDetails from "./Mobile-PropertyDetails";
import useViewport from "../../hooks/useViewport";
import PublicLayout from "../../layouts/public";
import { useQuery } from "@tanstack/react-query";
import { getPublicProperty } from "../../api/properties";
import removeUndefinedWord from "../../helper/updateString";
import LoaderRing from "../../components/LoaderRing";
import getImageUrl from "../../helper/checkImageFormat";

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 3px;
`;

const StyledLink = styled(Link)`
  display: flex;
  height: 25px;
  justify-content: center;
  align-items: center;
  ${"" /* gap: 8px; */}

  white-space: nowrap;
  
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.16px;
  text-decoration: underline;
  color: #5271ff;

  &:hover,
  &:focus {
    color: #4143a7;
  }
  &:disabled {
    color: #bdbebf;
    background-color: #eaeaeb;
  }
  & > svg {
    height: 16px;
    width: 16px;
  }
`;

const Card = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 5px;
  background: #ffffff;
  border: solid 1px rgb(153, 153, 153, 0.5);
  margin-top: 20px;
  box-shadow: 0px 5px 5px 0px rgba(153, 153, 153, 0.5);
`;

const CardSection = styled.div`
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;

const StyledSpan = styled.span`
  font-size: 12px;
  
  font-weight: 400;
  color: #4c4d52;
  text-transform: uppercase;
  letter-spacing: 0.22px;
  margin: 8px 0;
`;

const PropertyDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const path = location.pathname;
  const isTourProperty = path.includes("tour-property");
  const { width: screenWidth } = useViewport();
  const {
    data: property,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["property", propertyId],
    queryFn: async () => {
      const res = await getPublicProperty(propertyId);
      return res;
    },
  });

  if (isLoading)
    return (
      <div className="d-flex h-100 align-items-centerr justify-content-center">
        <LoaderRing height="100" width="100" />
      </div>
    );

  return (
    <PublicLayout>
      <div className="d-flex flex-column gap-3 px-4 my-4">
        {isTourProperty && (
          <Button onClick={() => navigate(-1)} className="w-content bg-transparent border-0 text-secondary p-0 m-0">X</Button>
        )}
        <Row>
          <Col xl={6} lg={6} className="mb-4 mb-xl-0">
            {property?.images && property?.images?.length > 0 ? (
              <Carousel interval={null}>
                {property.images.map((image, index) => (
                  <Carousel.Item key={index}>
                    <img
                      className="w-100 rounded-3"
                      src={getImageUrl(image)}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = require("../../assests/images/1-placeholder.png");
                      }}
                      alt={`property-${index}`}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <img
                className="w-100 rounded-3"
                src={require("../../assests/images/1-placeholder.png")}
                alt="placeholder"
              />
            )}
          </Col>
          <Col xl={6} lg={6}>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column align-items-start gap-1 align-self-stretch border-bottom">
                <div className="d-flex align-items-start justify-content-between gap-2 align-self-stretch">
                  <div>
                    <h3 className="main-address-head">
                      {removeUndefinedWord(property.shortAddress)}
                      {property.unit && `, Unit ${property.unit}`}
                    </h3>
                    <h3 className="main-address-head mb-4">
                      {property.city && property.city}
                      {property.state && `, ${property.state}`}
                      {property.zipCode && `, ${property.zipCode}`}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column align-items-start gap-2 ">
                <div className="d-flex flex-wrap align-items-start justify-content-between align-self-stretch border-bottom pb-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="tags-prop">Rent</span>
                    <span className="tags-body text-center">
                      {property.price ? `$${property.price}` : "N/A"}
                    </span>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <span className="tags-prop">Deposit</span>
                    <span className="tags-body text-center">
                      ${property.deposit ? property.deposit : "N/A"}
                    </span>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <span className="tags-prop">Beds</span>
                    <span className="tags-body text-center">
                      {typeof property.bedrooms === "number"
                        ? `${property.bedrooms}`
                        : "N/A"}
                    </span>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <span className="tags-prop">Baths</span>
                    <span className="tags-body text-center">
                      {property.bathrooms ? `${property.bathrooms}` : "N/A"}
                    </span>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <span className="tags-prop">SQFT</span>
                    <span className="tags-body text-center">
                      {property.sqft ? `${property.sqft}` : "N/A"}
                    </span>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <span className="tags-prop">Available</span>
                    <span className="tags-body text-center">
                      {property.dateAvailableTs
                        ? moment(property.dateAvailableTs).format("MM/DD/YY")
                        : "-"}
                    </span>
                  </div>
                </div>
                <DescriptionContainer>
                  {property.propertyDescription &&
                  property.propertyDescription.length > 0 ? (
                    <p className="desc-body">{property.propertyDescription}</p>
                  ) : null}
                </DescriptionContainer>
              </div>
            </div>
          </Col>
        </Row>
        {screenWidth < 755 ? (
          <MobilePropertyDetails property={property} />
        ) : (
          <>
            <Row className="">
              <Col xl={8} className="mb-3 mb-xl-0 pe-2">
                <Card className="h-100">
                  <h4 className="fs-6 color-gray fw-semibold">Features & Amenities</h4>
                  <CardSection>
                    <StyledSpan className="tags-xs">
                      Floor Plan Highlights
                    </StyledSpan>
                    {property?.amenitiesAndFeatures &&
                    property?.amenitiesAndFeatures?.floorPlanHighlights
                      ?.length > 0 ? (
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={4}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <ul>
                            {property?.amenitiesAndFeatures?.floorPlanHighlights
                              .slice(0, 5)
                              .map((option, index) => {
                                return (
                                  <li className="listed-items" key={index}>
                                    {option}
                                  </li>
                                );
                              })}
                          </ul>
                        </Col>
                        <Col
                          xl={4}
                          className="d-flex justify-content-start align-items-center p-xl-0"
                        >
                          <ul>
                            {property?.amenitiesAndFeatures?.floorPlanHighlights
                              .slice(5, 10)
                              .map((option, index) => {
                                return (
                                  <li className="listed-items" key={index}>
                                    {option}
                                  </li>
                                );
                              })}
                          </ul>
                        </Col>
                        <Col
                          xl={4}
                          className="d-flex justify-content-start align-items-center p-xl-0"
                        >
                          <ul>
                            {property?.amenitiesAndFeatures?.floorPlanHighlights
                              .slice(10, 16)
                              .map((option, index) => {
                                return (
                                  <li className="listed-items" key={index}>
                                    {option}
                                  </li>
                                );
                              })}
                          </ul>
                        </Col>
                      </Row>
                    ) : (
                      <span className="listed-items">N/A</span>
                    )}
                  </CardSection>
                  <CardSection>
                    <StyledSpan className="tags-xs">
                      Kitchen Features
                    </StyledSpan>
                    {property?.amenitiesAndFeatures &&
                    property?.amenitiesAndFeatures?.kitchenFeatures?.length >
                      0 ? (
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={4}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <ul>
                            {property?.amenitiesAndFeatures?.kitchenFeatures
                              .slice(0, 3)
                              .map((option, index) => {
                                return (
                                  <li className="listed-items" key={index}>
                                    {option}
                                  </li>
                                );
                              })}
                          </ul>
                        </Col>
                        <Col
                          xl={4}
                          className="d-flex justify-content-start align-items-center p-xl-0"
                        >
                          <ul>
                            {property?.amenitiesAndFeatures?.kitchenFeatures
                              .slice(3, 6)
                              .map((option, index) => {
                                return (
                                  <li className="listed-items" key={index}>
                                    {option}
                                  </li>
                                );
                              })}
                          </ul>
                        </Col>
                        <Col
                          xl={4}
                          className="d-flex justify-content-start align-items-center p-xl-0"
                        >
                          <ul>
                            {property?.amenitiesAndFeatures?.kitchenFeatures
                              .slice(6, 8)
                              .map((option, index) => {
                                return (
                                  <li className="listed-items" key={index}>
                                    {option}
                                  </li>
                                );
                              })}
                          </ul>
                        </Col>
                      </Row>
                    ) : (
                      <span className="listed-items">N/A</span>
                    )}
                  </CardSection>
                  <CardSection>
                    <StyledSpan className="tags-xs">
                      Building Features
                    </StyledSpan>
                    {property?.amenitiesAndFeatures &&
                    property?.amenitiesAndFeatures?.buildingFeatures?.length >
                      0 ? (
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={4}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <ul>
                            {property?.amenitiesAndFeatures?.buildingFeatures
                              .slice(0, 3)
                              .map((option, index) => {
                                return (
                                  <li className="listed-items" key={index}>
                                    {option}
                                  </li>
                                );
                              })}
                          </ul>
                        </Col>
                        <Col
                          xl={4}
                          className="d-flex justify-content-start align-items-center p-xl-0"
                        >
                          <ul>
                            {property?.amenitiesAndFeatures?.buildingFeatures
                              .slice(3, 6)
                              .map((option, index) => {
                                return (
                                  <li className="listed-items" key={index}>
                                    {option}
                                  </li>
                                );
                              })}
                          </ul>
                        </Col>
                        <Col
                          xl={4}
                          className="d-flex justify-content-start align-items-center p-xl-0"
                        >
                          <ul>
                            {property?.amenitiesAndFeatures?.buildingFeatures
                              .slice(6, 10)
                              .map((option, index) => {
                                return (
                                  <li className="listed-items" key={index}>
                                    {option}
                                  </li>
                                );
                              })}
                          </ul>
                        </Col>
                      </Row>
                    ) : (
                      <span className="listed-items">N/A</span>
                    )}
                  </CardSection>
                </Card>
              </Col>
              <Col xl={4} className="ps-2">
                <Card className="h-100">
                  <h4 className="fs-6 color-gray fw-semibold">Pet Policies</h4>
                  <CardSection>
                    <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                      <Col
                        xl={6}
                        className="d-flex justify-content-start align-items-center"
                      >
                        <StyledSpan className="body-xs-b">
                          Pets Allowed?
                        </StyledSpan>
                      </Col>
                      <Col
                        xl={6}
                        className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                      >
                        <span className="card-body-text">
                          {property?.pets && property?.pets.allowed
                            ? "Yes"
                            : property?.pets && !property?.pets?.allowed
                            ? "No"
                            : "N/A"}
                        </span>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                      <Col
                        xl={6}
                        className="d-flex justify-content-start align-items-center"
                      >
                        <StyledSpan className="body-xs-b">
                          Pet Deposit
                        </StyledSpan>
                      </Col>
                      <Col
                        xl={6}
                        className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                      >
                        <span className="card-body-text">
                          {property?.pets && property?.pets.deposit
                            ? `$${property?.pets.deposit}`
                            : "N/A"}
                        </span>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                      <Col
                        xl={6}
                        className="d-flex justify-content-start align-items-center"
                      >
                        <StyledSpan className="body-xs-b">
                          Monthly Pet Rent
                        </StyledSpan>
                      </Col>
                      <Col
                        xl={6}
                        className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                      >
                        <span className="card-body-text">
                          {property?.pets && property.pets.monthlyPetRent
                            ? `$${property.pets.monthlyPetRent}`
                            : "N/A"}
                        </span>
                      </Col>
                    </Row>
                  </CardSection>
                  <CardSection>
                    <h4 className="card-subheaders">Dog Policies</h4>
                    <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                      <Col
                        xl={6}
                        className="d-flex justify-content-start align-items-center"
                      >
                        <StyledSpan className="body-xs-b">Pet Limit</StyledSpan>
                      </Col>
                      <Col
                        xl={6}
                        className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                      >
                        <span className="card-body-text">
                          {property.pets && property.pets.dogs.allowed
                            ? `${property.pets.dogs.maxAllowed}`
                            : property.pets && !property.pets.dogs.allowed
                            ? "N/A"
                            : "N/A"}
                        </span>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                      <Col
                        xl={6}
                        className="d-flex justify-content-start align-items-center"
                      >
                        <StyledSpan className="body-xs-b">
                          Weight Limit
                        </StyledSpan>
                      </Col>
                      <Col
                        xl={6}
                        className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                      >
                        <span className="card-body-text">
                          {property.pets && property.pets.dogs.allowed
                            ? `${property.pets.dogs.weight} lbs`
                            : property.pets && !property.pets.dogs.allowed
                            ? "N/A"
                            : "N/A"}
                        </span>
                      </Col>
                    </Row>
                  </CardSection>
                  <CardSection>
                    <h4 className="card-subheaders">Cat Policies</h4>
                    <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                      <Col
                        xl={6}
                        className="d-flex justify-content-start align-items-center"
                      >
                        <StyledSpan className="body-xs-b">Pet Limit</StyledSpan>
                      </Col>
                      <Col
                        xl={6}
                        className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                      >
                        <span className="card-body-text">
                          {property.pets && property.pets.cats.allowed
                            ? `${property.pets.cats.maxAllowed}`
                            : property.pets && !property.pets.cats.allowed
                            ? "N/A"
                            : "N/A"}
                        </span>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                      <Col
                        xl={6}
                        className="d-flex justify-content-start align-items-center"
                      >
                        <StyledSpan className="body-xs-b">
                          Weight Limit
                        </StyledSpan>
                      </Col>
                      <Col
                        xl={6}
                        className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                      >
                        <span className="card-body-text">
                          {property.pets && property.pets.cats.allowed
                            ? `${property.pets.cats.weight} lbs`
                            : property.pets && !property.pets.cats.allowed
                            ? "N/A"
                            : "N/A"}
                        </span>
                      </Col>
                    </Row>
                  </CardSection>
                  <CardSection>
                    <StyledSpan className="tags-xs">Notes</StyledSpan>
                    <span className="card-body-text">
                      {property.pets && property.pets.notes
                        ? property.pets.notes
                        : "N/A"}
                    </span>
                  </CardSection>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xl={8} className="pe-2">
                <div className="d-flex flex-column gap-3 h-100">
                  <Card className="h-100">
                    <h4 className="fs-6 color-gray fw-semibold">Lease Options</h4>
                    <CardSection>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">
                            Lease Term(s)
                          </StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.leaseTermOptions &&
                            property.leaseTermOptions.length > 0
                              ? property.leaseTermOptions.join(", ")
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                    </CardSection>
                    <CardSection>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">
                            Listed Date
                          </StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.listedTs
                              ? moment(property.listedTs).format("MM/DD/YY")
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                    </CardSection>
                    <CardSection>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">
                            Available
                          </StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.dateAvailableTs
                              ? moment(property.dateAvailableTs).format(
                                  "MM/DD/YY"
                                )
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                    </CardSection>
                    {property?.status && property?.status === "rented" && (
                      <CardSection>
                        <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                          <Col
                            xl={6}
                            className="d-flex justify-content-start align-items-center"
                          >
                            <StyledSpan className="body-xs-b">
                              Rented Date
                            </StyledSpan>
                          </Col>
                          <Col
                            xl={6}
                            className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                          >
                            <span className="card-body-text">
                              {property.rentedDate
                                ? moment(property.rentedDate).format("MM/DD/YY")
                                : "N/A"}
                            </span>
                          </Col>
                        </Row>
                      </CardSection>
                    )}
                    <CardSection>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">
                            Application URL
                          </StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          {property.rentalApplicationForm &&
                          property.rentalApplicationForm.url ? (
                            <StyledLink to={property.rentalApplicationForm.url}>
                              Application Form
                            </StyledLink>
                          ) : (
                            <span className="card-body-text">N/A</span>
                          )}
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b mt-3">
                            Application Fee
                          </StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text mt-3">
                            {property.rentalApplicationForm &&
                            property.rentalApplicationForm.fee
                              ? `$${property.rentalApplicationForm.fee}`
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                    </CardSection>
                  </Card>
                  <Card className="h-100 mb-3 mb-xl-0">
                    <h4 className="fs-6 color-gray fw-semibold">
                      Property Management Contacts{" "}
                    </h4>
                    <CardSection>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">
                            Leasing Agent
                          </StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.leasingAgent &&
                            (property.leasingAgent.firstName ||
                              property.leasingAgent.lastName)
                              ? `${property.leasingAgent.firstName || ""}${
                                  property.leasingAgent.firstName &&
                                  property.leasingAgent.lastName
                                    ? " "
                                    : ""
                                }${property?.leasingAgent?.lastName || ""}`
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">Phone</StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          {property.leasingAgent &&
                          property.leasingAgent.phone ? (
                            <StyledLink className="card-body-text">
                              {property.leasingAgent.phone}
                            </StyledLink>
                          ) : (
                            <span className="card-body-text">N/A</span>
                          )}
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">Email</StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          {property.leasingAgent &&
                          property.leasingAgent.email ? (
                            <StyledLink className="card-body-text">
                              {property.leasingAgent.email}
                            </StyledLink>
                          ) : (
                            <span className="card-body-text">N/A</span>
                          )}
                        </Col>
                      </Row>
                    </CardSection>
                    <CardSection>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <h4 className="card-subheaders">Property Manager</h4>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.propertyManager &&
                            (property.propertyManager.firstName ||
                              property.propertyManager.lastName)
                              ? `${property.propertyManager.firstName || ""}${
                                  property.propertyManager.firstName &&
                                  property.propertyManager.lastName
                                    ? " "
                                    : ""
                                }${property?.propertyManager?.lastName || ""}`
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">
                            On Site?
                          </StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.propertyManager &&
                            property.propertyManager.onSite
                              ? "Yes"
                              : property?.propertyManager &&
                                !property?.propertyManager?.onSite
                              ? "No"
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">Phone</StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          {property.propertyManager &&
                          property.propertyManager.phone ? (
                            <StyledLink className="card-body-text">
                              {property.propertyManager.phone}
                            </StyledLink>
                          ) : (
                            <span className="card-body-text">N/A</span>
                          )}
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">Email</StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          {property.propertyManager &&
                          property.propertyManager.email ? (
                            <StyledLink className="card-body-text">
                              {property.propertyManager.email}
                            </StyledLink>
                          ) : (
                            <span className="card-body-text">N/A</span>
                          )}
                        </Col>
                      </Row>
                    </CardSection>
                    <CardSection>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <h4 className="card-subheaders">
                            Maintenance Manager
                          </h4>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.maintenanceManager &&
                            (property.maintenanceManager.firstName ||
                              property.maintenanceManager.lastName)
                              ? `${
                                  property.maintenanceManager.firstName || ""
                                }${
                                  property.maintenanceManager.firstName &&
                                  property.maintenanceManager.lastName
                                    ? " "
                                    : ""
                                }${
                                  property?.maintenanceManager?.lastName || ""
                                }`
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">
                            On Site?
                          </StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.maintenanceManager &&
                            property.maintenanceManager.onSite
                              ? "Yes"
                              : property?.maintenanceManager &&
                                !property?.maintenanceManager?.onSite
                              ? "No"
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">Phone</StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          {property.maintenanceManager &&
                          property.maintenanceManager.phone ? (
                            <StyledLink className="card-body-text">
                              {property.maintenanceManager.phone}
                            </StyledLink>
                          ) : (
                            <span className="card-body-text">N/A</span>
                          )}
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">Email</StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          {property.maintenanceManager &&
                          property.maintenanceManager.email ? (
                            <StyledLink className="card-body-text">
                              {property.maintenanceManager.email}
                            </StyledLink>
                          ) : (
                            <span className="card-body-text">N/A</span>
                          )}
                        </Col>
                      </Row>
                    </CardSection>
                    <CardSection>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <h4 className="card-subheaders">Management Co.</h4>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.company && property.company.name
                              ? property.company.name
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">
                            Company Website
                          </StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          {property?.company && property?.company?.website ? (
                            <StyledLink
                              className="card-body-text"
                              to={property.company.website}
                              target="_blank"
                            >
                              {property?.company?.website}
                            </StyledLink>
                          ) : (
                            <span className="card-body-text">N/A</span>
                          )}
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">
                            Company Phone
                          </StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          {property.company && property.company.phone ? (
                            <StyledLink className="card-body-text">
                              {property.company.phone}
                            </StyledLink>
                          ) : (
                            <span className="card-body-text">N/A</span>
                          )}
                        </Col>
                      </Row>
                    </CardSection>
                  </Card>
                </div>
              </Col>
              <Col xl={4} className="ps-2">
                <div className="d-flex flex-column gap-3 h-100">
                  <Card className="h-100">
                    <h4 className="fs-6 color-gray fw-semibold">Utilities</h4>
                    <CardSection>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">Water</StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.utilities && property.utilities.water
                              ? property.utilities.water
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">
                            Electricity
                          </StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.utilities &&
                            property.utilities.electricity
                              ? property.utilities.electricity
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">Gas</StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.utilities && property.utilities.gas
                              ? property.utilities.gas
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">Trash</StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.utilities && property.utilities.trash
                              ? property.utilities.trash
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">Sewage</StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.utilities && property.utilities.sewage
                              ? property.utilities.sewage
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                    </CardSection>
                    <CardSection>
                      <StyledSpan className="tags-xs">Notes</StyledSpan>
                      <span className="card-body-text">
                        {property.utilities && property.utilities.notes
                          ? property.utilities.notes
                          : "N/A"}
                      </span>
                    </CardSection>
                  </Card>

                  <Card className="h-100">
                    <h4 className="fs-6 color-gray fw-semibold">Parking</h4>
                    <CardSection>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">
                            Parking Type
                          </StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.parking && property.parking.type
                              ? property.parking.type
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">
                            Parking Spaces
                          </StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.parking && property.parking.spacesNumber
                              ? property.parking.spacesNumber
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                        <Col
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <StyledSpan className="body-xs-b">
                            Monthly Cost Per Space
                          </StyledSpan>
                        </Col>
                        <Col
                          xl={6}
                          className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                        >
                          <span className="card-body-text">
                            {property.parking &&
                            property.parking.monthlyCostPerSpace
                              ? `$${property.parking.monthlyCostPerSpace}`
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                    </CardSection>
                    <CardSection>
                      <StyledSpan className="tags-xs">Notes</StyledSpan>
                      <span className="card-body-text">
                        {property.parking && property.parking.notes
                          ? property.parking.notes
                          : "N/A"}
                      </span>
                    </CardSection>
                  </Card>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </PublicLayout>
  );
};

export default PropertyDetails;
