import React, { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import copy from "copy-to-clipboard";
import { FiCopy } from "react-icons/fi";
import { propertyBookingLink } from "../../../utils/Properties";

const CopyButton = ({ property, iconColor, iconSize, ...props }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const link = await propertyBookingLink(property);
    copy(link);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <button
      className="bg-transparent border-0 p-0 py-2 position-relative"
      onClick={handleClick}
      {...props}
    >
      <FiCopy color={iconColor ?? "#5271FF"} size={iconSize} />
      {isCopied && (
        <div className="text-black z-1 xs-text rounded-5 d-flex justify-content-center align-items-center gap-1 py-1 px-3 text-nowrap link-copied">
          <FaCheckCircle className="text-success" /> Property link copied!
        </div>
      )}
    </button>
  );
};

export default CopyButton;
