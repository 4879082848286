import React, { useEffect, useRef, useState } from "react";
import { outcomeOptions } from "../../../constants";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import UnstyledButton from "../../../components/UnstyledButton";

const SelectBookingOutcome = ({
  value,
  isLoading,
  updateStatus,
  bookingId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(
    outcomeOptions.find((opt) => opt.value === value) ?? outcomeOptions[0]
  );

  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div
      className={`position-relative ${
        isLoading ? "pe-none opacity-50" : ""
      } border rounded-5 h-content px-2 col-lg-8 showing-status-${
        selected.color ?? selected.value
      }`}
      ref={selectRef}
    >
      <UnstyledButton
        onClick={() => setIsOpen(!isOpen)}
        className={`cursor-pointer d-flex gap-1 py-1 align-items-center booking-option bg-transparent ${selected.color} w-100`}
      >
        <span className="flex-grow-1 text-truncate text-start small-text">
          {selected.label}{" "}
        </span>
        {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
      </UnstyledButton>

      {isOpen && (
        <div className="position-absolute w-full bg-white shadow rounded z-3">
          {outcomeOptions.map((option) => (
            <span
              key={option.value}
              onClick={() => {
                setSelected(option);
                setIsOpen(false);
                updateStatus({ newStatus: option.value, bookingId });
              }}
              className={`p-2 hover:bg-gray-100 cursor-pointer d-flex gap-2 align-items-center booking-option ${option.color}`}
            >
              {option.label}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectBookingOutcome;
