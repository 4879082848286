import { Box } from "@mui/material";
import { Auth } from "aws-amplify";
import axios from "axios";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styled from "styled-components";
import { Button, Card, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useGeneral } from "../../../../context/GeneralContext";
import { useNavigate } from "react-router-dom";
import InputTextComponent from "../../../../components/InputTextComponent";
import InputSelectComponent from "../../../../components/InputSelectComponent";
import { format, getTimezoneOffset, toZonedTime } from "date-fns-tz";
import {
  user_booking_timezone,
  USER_SETTINGS_BOOKINS,
} from "../../../../static/Data";
import useViewport from "../../../../hooks/useViewport";
import UnstyledButton from "../../../../components/UnstyledButton";
import InputCheckbox from "../../../../components/InputCheckbox";
import moment from "moment";
import InputSwitchComponent from "../../../../components/InputSwitchComponent";
import InputTextAreaComponent from "../../../../components/InputTextAreaComponent";
import LoaderRing from "../../../../components/LoaderRing";
import { Center, Loader } from "@mantine/core";
import { StyledGrayButton } from "../SettingTab";
import InputPhoneNumberComponent from "../../../../components/InputPhoneNumberComponent";

export const FormTitle = styled.h4({
  color: "#5f5f5f",
});
export const StyledLabel = styled.label`
  color: #5f5f5f;

  font-size: 16px;
  font-weight: ${(props) =>
    props.variantType === "primary"
      ? "700"
      : props.variantType === "secondary"
      ? "400"
      : "700"};
  line-height: 140%;
  letter-spacing: 0.16px;
  padding-bottom: 0px;

  /* disabled styles */
  ${(props) =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;
const daysOfWeek = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const calendarlists = [
  {
    png: "google-calendar.png",
    name: "Google Calendar",
    formName: "google",
  },
  {
    png: "outlook-calendar.png",
    name: "Outlook Calendar",
    formName: "outlook",
  },
  {
    png: "apple-calendar.png",
    name: "Apple Calendar",
    formName: "apple",
  },
];
const CalendarPanel = forwardRef((props, ref) => {
  const { children, value, index, next, prev, setIsDirty, ...other } = props;
  const [userData, setUserData] = useState("");
  const [currentRole, setCurrentRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setAlertModal } = useGeneral();
  const [currentTime, setCurrentTime] = useState(null);
  const [timezone, setTimezone] = useState(0);
  const { width: screenWidth } = useViewport();
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();

  const formMethods = useForm({ defaultValues: USER_SETTINGS_BOOKINS });
  const {
    handleSubmit,
    register,
    trigger,
    setValue,
    getValues,
    formState: { errors, isDirty, isValid, dirtyFields },
    setError,
    watch,
    reset,
  } = formMethods;

  const calcDatesOptions = (firstValue, step, limit = 1440) => {
    let options = [];
    for (let i = firstValue; i < limit; i += step) {
      options.push({
        id: i,
        description: moment()
          .startOf("day")
          .add(i, "minutes")
          .format("hh:mm a"),
      });
    }
    return options;
  };

  useEffect(() => {
    const newTimezone =
      getTimezoneOffset(watch("bookings.timezoneLocation"), new Date()) /
      60 /
      60 /
      1000;
    const newTime = toZonedTime(new Date(), watch("bookings.timezoneLocation"));
    setTimezone(newTimezone);
    setCurrentTime(newTime);
  }, [watch("bookings.timezoneLocation")]);

  const filterUserSettingData = (receivedData) => {
    const filteredData = {};

    for (const key in USER_SETTINGS_BOOKINS) {
      if (receivedData.hasOwnProperty(key)) {
        filteredData[key] = receivedData[key];
      }
    }
    return filteredData;
  };
  const getSettings = useCallback(async () => {
    setIsLoading(true);

    try {
      const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/`;
      const token = await Auth.currentSession();

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      });
      setIsLoading(false);
      if (response.status === 200) {
        setUserData(response.data);
        reset(filterUserSettingData(response.data.settings));
        setCurrentRole(response.data.role);
        setCurrentTime(
          toZonedTime(
            new Date(),
            response.data.settings.bookings.timezoneLocation
          )
        );
        setTimezone(response.data.settings.bookings.timezone);
      } else {
        setAlertModal({
          show: true,
          title: "Error",
          content: "Error Getting Settings",
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 401) {
        Auth.signOut()
          .then(() => {
            navigate("/");
          })
          .catch((error) => {
            console.log("error signing out: ", error);
          });
      } else {
        setAlertModal({
          show: true,
          title: "Error",
          content: "Error Getting Settings",
        });
      }
    }
  }, []);

  useImperativeHandle(ref, () => ({
    saveForm() {
      onSubmit(getValues());
    },
  }));

  function formatPhoneNumber(phoneNumber) {
    // Remove dashes and convert to a string
    if (phoneNumber) {
      const cleanedNumber = phoneNumber.replace(/-/g, "");

      // Return the formatted number with a leading '+'
      return `+${cleanedNumber}`;
    }
    return "";
  }

  const onSubmit = async (data) => {
    try {
      setIsSaving(true);
      const token = await Auth.currentSession();
      const phoneNumber = watch("customerSupportPhone");
      const response = await axios.patch(
        `${process.env.REACT_APP_NODE_API_URL}/api/users/settings/calendar`,
        {
          data: {
            ...data,
            customerSupportPhone: formatPhoneNumber(phoneNumber),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        }
      );
      setIsSaving(false);
      if (response.status === 200) {
        setTimezone(response.data.bookings.timezone);
        setAlertModal({
          show: true,
          title: "Saved",
          content: "Settings Updated Successfully",
        });
        setIsDirty(false);

        // getSettings();
      } else {
        throw new Error("Failed to update settings");
      }
    } catch (error) {
      setIsSaving(false);
      console.error("Error occurred:", error);
      if (error?.response?.status === 401) {
        try {
          await Auth.signOut();
          navigate("/");
        } catch (signOutError) {
          console.error("Error signing out: ", signOutError);
        }
      } else {
        setAlertModal({
          show: true,
          title: "Error",
          content: "Error Updating Settings",
        });
      }
    }
  };

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  useEffect(() => {
    getSettings();
    // getStripeSub();
  }, [getSettings]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {isLoading === true ? (
        <>
          <Center h={100}>
            <Loader />
          </Center>
        </>
      ) : (
        value === index && (
          <>
            <Box className="px-4 px-lg-5 pt-4">
              <Card style={{ border: "1px solid #EAEAEA", color: "#5f5f5f" }}>
                <Card.Header as="h5">CALENDAR</Card.Header>
                <Card.Body>
                  <form>
                    <div className="d-flex flex-column gap-3">
                      <InputPhoneNumberComponent
                        required={true}
                        label="Customer Support Phone"
                        name="customerSupportPhone"
                        register={register}
                        trigger={trigger}
                        setValue={setValue}
                        watch={watch}
                        error={errors}
                        placeholder="1-xxx-xxx-xxxx"
                      />
                      {/* <FormTitle>Timezone</FormTitle>
                      <div className="d-flex">
                        <InputSelectComponent
                          label="Location"
                          name="bookings.timezoneLocation"
                          register={register}
                          error={errors}
                          options={user_booking_timezone}
                          selectedOption="Select"
                          placeholder="Select"
                        />
                      </div> */}
                      {/* <div className="d-flex justify-content-between ">
                        <div>
                          <StyledLabel>GMT {timezone}</StyledLabel>
                        </div>
                        <p className="pe-4">
                          <strong>Current Time:</strong>{" "}
                          {currentTime
                            ? format(currentTime, "hh:mm aa")
                            : "12:00 AM"}
                        </p>
                      </div> */}
                      <FormTitle>Availability</FormTitle>

                      {daysOfWeek.map((day) => {
                        return (
                          <div className="row px-2 gap-3" key={day}>
                            <InputCheckbox
                              name={`bookings.dow.${day}.available`}
                              register={register}
                              option={{
                                name:
                                  day.charAt(0).toUpperCase() + day.slice(1),
                              }}
                              error={errors}
                            />
                            <div className="col-12 d-flex flex-column gap-3">
                              {!watch(`bookings.dow.${day}.available`) ? (
                                ""
                              ) : (
                                <div>
                                  {watch(
                                    `bookings.dow.${day}.availability`
                                  ).map(({ startTime, stopTime }, index) => {
                                    const nextStartTimePath = `bookings.dow.${day}.availability.${
                                      index + 1
                                    }.startTime`;
                                    const prevStopTimePath = `bookings.dow.${day}.availability.${
                                      index - 1
                                    }.stopTime`;

                                    const nextStartTime =
                                      watch(nextStartTimePath);

                                    const prevStopTime =
                                      index > 0
                                        ? watch(prevStopTimePath)
                                        : null;

                                    return (
                                      <div
                                        className="row align-items-center pb-1"
                                        key={index}
                                      >
                                        <div className="col">
                                          <InputSelectComponent
                                            name={`bookings.dow.${day}.availability.${index}.startTime`}
                                            register={register}
                                            error={errors}
                                            options={calcDatesOptions(
                                              prevStopTime
                                                ? parseInt(prevStopTime) + 15
                                                : 0,
                                              15,
                                              parseInt(stopTime)
                                            )}
                                            placeholder="Select"
                                          />
                                        </div>
                                        <span className="col-1 fw-bold fs-4 text-center">
                                          -
                                        </span>
                                        <div className="col">
                                          <InputSelectComponent
                                            name={`bookings.dow.${day}.availability.${index}.stopTime`}
                                            register={register}
                                            error={errors}
                                            options={calcDatesOptions(
                                              parseInt(startTime) + 15,
                                              15,
                                              nextStartTime
                                                ? parseInt(nextStartTime)
                                                : undefined
                                            )}
                                            selectedOption="Select"
                                            placeholder="Select"
                                          />
                                        </div>
                                        <UnstyledButton
                                          type="button"
                                          className="col-1 fw-bold fs-4"
                                          onClick={() =>
                                            setValue(
                                              `bookings.dow.${day}.availability`,
                                              [
                                                ...watch(
                                                  `bookings.dow.${day}.availability`
                                                ),
                                                {
                                                  startTime:
                                                    parseInt(stopTime) + 15,
                                                  stopTime:
                                                    parseInt(stopTime) + 30,
                                                },
                                              ]
                                            )
                                          }
                                        >
                                          +
                                        </UnstyledButton>
                                        <UnstyledButton
                                          className="col-1 fw-bold"
                                          onClick={() =>
                                            setValue(
                                              `bookings.dow.${day}.availability`,
                                              watch(
                                                `bookings.dow.${day}.availability`
                                              ).filter((_, i) => i !== index)
                                            )
                                          }
                                          disabled={
                                            watch(
                                              `bookings.dow.${day}.availability`
                                            ).length === 1
                                          }
                                        >
                                          X
                                        </UnstyledButton>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      {/* <div className="d-flex flex-column gap-1">
                        <StyledLabel>Limit Number of Tours Per Day</StyledLabel>
                        <InputSelectComponent
                          name="bookings.limitToursPerDay"
                          register={register}
                          error={errors}
                          options={Array.from({ length: 6 }, (_, i) => {
                            return {
                              id: (i + 1) * 10,
                              description: `${(i + 1) * 10} people`,
                            };
                          })}
                          selectedOption="Select"
                          placeholder="Select"
                        />
                      </div> */}
                      {/* <div className="d-flex justify-content-between align-items-center">
                        <StyledLabel className="body col-9">
                          Allow 2 People to Book at the Same Time
                        </StyledLabel>
                        <InputSwitchComponent
                          register={register}
                          watch={watch}
                          name="bookings.allowTwoPeople"
                        />
                      </div> */}

                      {/* <div className="d-flex flex-column gap-3">
                        <FormTitle>SYNC CALENDAR</FormTitle>
                        <div className="d-flex flex-column gap-1 px-2">
                          <StyledLabel style={{ fontSize: "17px" }}>
                            Connnect your Delet Calendar with:
                          </StyledLabel>
                          {calendarlists.map((calendar) => {
                            return (
                              <div
                                className="d-flex justify-content-between align-items-center"
                                key={calendar.formName}
                              >
                                <StyledLabel className="body col-9 d-flex gap-3 align-items-center">
                                  <div
                                    style={{
                                      width: "46px",
                                      height: "46px",
                                      background: `url("images/${calendar.png}") no-repeat center center`,
                                      backgroundSize: "contain",
                                      flex: "none",
                                      order: 0,
                                      flexGrow: 0,
                                    }}
                                  ></div>
                                  {calendar.name}
                                </StyledLabel>
                                <InputSwitchComponent
                                  register={register}
                                  watch={watch}
                                  name={`bookings.syncCalendar.${calendar.formName}`}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div> */}
                    </div>
                  </form>
                </Card.Body>
              </Card>
            </Box>
            {screenWidth > 755 ? (
              <div className="position-sticky bottom-0 bg-white py-3 px-4 d-flex justify-content-between">
                <Col xs="auto">
                  <Button className={`px-5 tab-footer-button`} onClick={prev}>
                    {"< Back"}
                  </Button>
                </Col>
                <Col xs="auto gap-2 d-flex">
                  <Button
                    as={StyledGrayButton}
                    className="px-4"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save and close
                  </Button>
                  <Button className={`px-5 tab-footer-button`} onClick={next}>
                    {"Continue >"}
                  </Button>
                </Col>
              </div>
            ) : (
              <div className="d-flex flex-column gap-1 position-sticky bottom-0 bg-white py-3 px-4">
                <Col xs={12}>
                  <Button
                    className="tab-footer-button "
                    style={{ width: "100%" }}
                    onClick={next}
                    // onClick={handleSubmit(onSubmit)}
                  >
                    {"Continue >"}
                  </Button>
                </Col>
                <Col xs={12}>
                  <Button
                    as={StyledGrayButton}
                    className="px-4 "
                    onClick={handleSubmit(onSubmit)}
                    style={{ width: "100%" }}
                  >
                    Save and close
                  </Button>
                </Col>
                <Col xs={12}>
                  <Button
                    className="tab-footer-button"
                    onClick={prev}
                    style={{ width: "100%" }}
                  >
                    {"< Back"}
                  </Button>
                </Col>
              </div>
            )}
          </>
        )
      )}
      {isSaving && (
        <div className="modal-outside">
          <LoaderRing height="100" width="100" />
        </div>
      )}
    </div>
  );
});

export default CalendarPanel;
