import "./Leads.scss";

import React, { useEffect, useState } from "react";
import useViewport from "../../hooks/useViewport";

import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";

import Sidebar from "../../components/SideBar";
import getImageUrl from "../../helper/checkImageFormat";
import { Center, Loader } from "@mantine/core";
import InfoBanner from "../../components/Banners/InfoBanner";
import { format } from "date-fns";
import SelectBookingStatus from "./components/SelectBookingStatus";
import { updateBooking } from "../../api/bookings";
import PageHeader from "../../components/Headers/PageHeader";
import { IoIosArrowForward } from "react-icons/io";
import SelectBookingOutcome from "./components/SelectBookingOutcome";

const LeadsDetails = () => {
  const { width: screenWidth } = useViewport();
  // alert modal
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Alert");
  const [alertContent, setAlertContent] = useState("");

  const [timezone, setTimezone] = useState(0);

  const [loading, setLoader] = useState(false);

  const [list, setList] = useState([]);
  const [leadImage, setLeadImage] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    async function getTimezone() {
      try {
        const token = await Auth.currentSession();
        const response = await axios.get(
          `${process.env.REACT_APP_NODE_API_URL}/api/users/timezone`,
          {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          }
        );
        if (response.status === 200) {
          setTimezone(response.data.timezone);
        } else {
          setShowAlert(true);
          setAlertTitle("Error");
          setAlertContent("Error getting timezone");
        }
      } catch (error) {
        console.log(error);
        setShowAlert(true);
        setAlertTitle("Error");
        setAlertContent("Error getting timezone");
      }
    }
    getTimezone();
  }, []);

  useEffect(() => {
    if (timezone) {
      getPropertyList();
    }
  }, [timezone]);

  const getPropertyList = () => {
    Auth.currentSession()
      .then((token) => {
        axios
          .get(
            `${process.env.REACT_APP_NODE_API_URL}/api/contact/${params.id}`,
            {
              headers: {
                Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              if (response.data.contact.document) {
                setLeadImage(response.data.contact.document);
              }
              setList(response.data);
              setLoader(true);
            } else {
              setShowAlert(true);
              setAlertTitle("Alert");
              setAlertContent("Error getting Lead");
            }
          })
          .catch((error) => {
            setShowAlert(true);
            setAlertTitle("Alert");
            setAlertContent("Error getting Lead");
          });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              console.log("Error signing out: ", error);
            });
        } else {
          setShowAlert(true);
          setAlertTitle("Alert");
          setAlertContent("Error getting Lead");
        }
      });
  };

  const updateBookingField = ({ field, value, bookingId }) => {
    setList((prev) => {
      const newList = { ...prev };
      const bookingIndex = newList.bookings.findIndex(
        (booking) => booking._id === bookingId
      );
      newList.bookings[bookingIndex][field] = value;
      return newList;
    });
  };

  const updateOutcomeStatus = async ({ newStatus, bookingId, type }) => {
    try {
      const token = await Auth.currentSession();
      const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/${type}/${bookingId}`;
      await axios.patch(
        url,
        { newStatus },
        {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        }
      );

      updateBookingField({ field: type, value: newStatus, bookingId });
    } catch (error) {
      console.error("Error updating outcome status:", error);
    }
  };

  const handleKeyDown = async (e, bookingId, notes) => {
    const { key } = e;
    if (key === "Enter") {
      e.preventDefault();
      await updateBooking(bookingId, { notes });
    }
  };

  function autoResize(textarea) {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  return (
    <Sidebar>
      <PageHeader>
        <div className="d-flex align-items-center gap-2">
          <h1 className="fs-4 mb-0">Leads</h1>
          <IoIosArrowForward width={12} height={7} />
          <span className="color-gray fw-medium">
            {list?.contact?.firstName} {list?.contact?.lastName}
          </span>
        </div>
      </PageHeader>

      {loading === false ? (
        <Center h={100}>
          <Loader />
        </Center>
      ) : (
        <div className="py-4 px-3 px-lg-5 row row-gap-4">
          <div className="d-flex flex-column flex-lg-row gap-3">
            <div className="card-shadow col-xl-3 d-flex flex-column rounded-bottom-3">
              <h4 className="form-header px-3 color-gray rounded-top-3">
                ID DOCUMENT
              </h4>
              <div className="id-img-wrap">
                <img
                  src={getImageUrl(leadImage)}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = require("../../assests/images/ID.svg");
                  }}
                  alt="ID document"
                  width="100%"
                />
              </div>
            </div>

            <div className="card-shadow d-flex flex-column flex-grow-1 rounded-bottom-3">
              <header className="form-header rounded-top-3">
                <p className="ms-4 pt-0 color-gray">PERSONAL INFORMATION</p>
              </header>

              <ul className="list-unstyled mx-3">
                <li className="d-flex py-2 border-bottom px-3 w-100 justify-content-between align-items-center text-secondary fw-medium">
                  <span>First Name</span>
                  <span>{list?.contact?.firstName}</span>
                </li>
                <li className="d-flex py-2 border-bottom px-3 w-100 justify-content-between align-items-center text-secondary fw-medium">
                  <span>Last Name</span>
                  <span>{list?.contact?.lastName}</span>
                </li>
                <li className="d-flex py-2 border-bottom px-3 w-100 justify-content-between align-items-center text-secondary fw-medium">
                  <span>Mobile</span>
                  <span>{list?.contact?.phone}</span>
                </li>
                <li className="d-flex py-2 px-3 w-100 justify-content-between align-items-center text-secondary fw-medium">
                  <span>Email</span>
                  <span>{list?.contact?.email}</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12">
            <InfoBanner>
              <span
                className={
                  screenWidth < 755 ? "banner-text-mobile" : "banner-text"
                }
              >
                Always check and confirm that the ID provided is correct and
                matches the other given details
              </span>
            </InfoBanner>
          </div>

          <header className="col-12 text-uppercase fw-medium">
            {`${list?.contact?.firstName} ${list?.contact?.lastName}'s Showings`}
          </header>

          <div className="col-12">
            {list.bookings.map((booking) => (
              <div className="d-flex flex-column flex-lg-row flex-lg-wrap gap-3 gap-lg-0 card-shadow rounded-3 py-3">
                <span className="col-3 d-none d-lg-block fw-semibold border-bottom pb-3 ps-3 pt-3">
                  Tour Date
                </span>
                <span className="col-3 d-none d-lg-block fw-semibold border-bottom">
                  One Time Access Code
                </span>
                <span className="col-3 d-none d-lg-block fw-semibold border-bottom">
                  Showing Status
                </span>
                <span className="col-3 d-none d-lg-block fw-semibold border-bottom">
                  Outcome
                </span>

                <p className="col-lg-3 ps-3 pt-3">
                  <h4 className="titles d-lg-none">TOUR DATE</h4>
                  <span className="content d-block">
                    {format(
                      new Date(booking.startTime * 1000),
                      "MMMM dd, yyyy. hh:mm a"
                    )}
                  </span>
                </p>

                <div className="d-flex flex-column col-lg-3 pt-3 ps-3">
                  <div className="">
                    <h4 className="titles d-lg-none">ONE TIME ACCESS CODE</h4>
                    <p>
                      <span className="content-access">
                        {booking.passCode ? (
                          <div>{booking.passCode} </div>
                        ) : (
                          "No Code"
                        )}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="d-none d-lg-flex flex-column gap-1 col-lg-3 pt-3">
                  <span className="titles d-lg-none">Showing Status</span>
                  <SelectBookingStatus
                    value={booking.status}
                    isLoading={false}
                    updateStatus={({ newStatus, bookingId }) =>
                      updateOutcomeStatus({
                        newStatus,
                        bookingId,
                        type: "status",
                      })
                    }
                    bookingId={booking._id}
                  />
                </div>

                <div className="d-flex flex-column gap-1 col-lg-3 pt-3 ps-3 ps-lg-0">
                  <span className="titles d-lg-none">OUTCOME</span>
                  <SelectBookingOutcome
                    value={booking.outcome}
                    isLoading={false}
                    updateStatus={({ newStatus, bookingId }) =>
                      updateOutcomeStatus({
                        newStatus,
                        bookingId,
                        type: "outcome",
                      })
                    }
                    bookingId={booking._id}
                  />
                </div>

                <div className="d-none d-lg-block col-12 position-relative p-3">
                  <div class="form-floating">
                    <textarea
                      className="form-control text-secondary fw-normal overflow-hidden"
                      id={`notes ${booking._id}`}
                      placeholder="Add notes here..."
                      value={booking.notes}
                      onChange={(e) => {
                        updateBookingField({
                          field: "notes",
                          value: e.target.value,
                          bookingId: booking._id,
                        });
                        autoResize(e.target);
                      }}
                      onKeyDown={(e) =>
                        handleKeyDown(e, booking._id, booking.notes)
                      }
                    ></textarea>
                    <label
                      className="text-black bg-transparent fw-medium"
                      for={`notes ${booking._id}`}
                    >
                      Notes:{" "}
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {showAlert && (
        <Modal show={showAlert}>
          <Modal.Header>
            <Modal.Title>{alertTitle}</Modal.Title>
            <button
              className="btn-close"
              aria-label="Close"
              onClick={() => setShowAlert(false)}
            ></button>
          </Modal.Header>
          <Modal.Body>{alertContent}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAlert(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Sidebar>
  );
};

export default LeadsDetails;
