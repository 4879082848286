import "./Settings.scss";

import React, { useEffect, useState } from "react";
import { Col, Row, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/SideBar";
import Tabs from "../../components/Tabs";
import styled from "styled-components";
import SettingTab from "./Tabs/SettingTab";
import PageHeader from "../../components/Headers/PageHeader";
import NotificationsButton from "../../components/Notifications/NotificationsButton";

const RowContainer = styled(Row)`
  .tab-content {
    padding: 0;
  }
`;
const Account = () => {
  const [activeTab, setActiveTab] = useState("settings");
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("activeTab");
    if (!query) window.history.pushState({}, "", `/account?activeTab=settings`);
    setActiveTab(query ?? "settings");
  }, [location.search]);

  const handleTabChange = (eventKey) => {
    window.history.pushState({}, "", `/account?activeTab=${eventKey}`);
    setActiveTab(eventKey);
  };

  return (
    <Sidebar>
      <PageHeader>
        <div className="d-flex justify-content-between">
          <h1 className="color-black fs-4">Account</h1>
          <NotificationsButton />
        </div>
      </PageHeader>

      <RowContainer className="mx-0" style={{}}>
        <Tabs
          defaultActiveKey={activeTab}
          onSelect={handleTabChange}
          className="px-4 px-lg-5 bg-secondary-subtle"
        >
          <Tab eventKey="settings" title="Settings">
            <SettingTab />
          </Tab>
        </Tabs>
      </RowContainer>
    </Sidebar>
  );
};

export default Account;
