import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import InputTextComponent from "../../../../components/InputTextComponent";
import InputPhoneNumberComponent from "../../../../components/InputPhoneNumberComponent";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";
import InputPassword from "./Password";
import styled from "styled-components";
import { useGeneral } from "../../../../context/GeneralContext";
import { USER_SETTING_AGENT_PROFILE } from "../../../../static/Data";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Center, Loader } from "@mantine/core";
import { useProfile } from "../../../../context/UserProfileContext";
import { emailPattern } from "../../utils";

const StyledInputText = styled.input`
  color: #000000;
  
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.16px;
  border: 1px solid #bdbebf;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  ${(props) =>
    props.textAtBeginning &&
    ` padding-left: 25px;
    `}
  box-sizing: border-box;
  &:focus {
    border: 1px solid #5d5fef;
    box-shadow: 0px 0px 4px rgba(93, 95, 239, 0.25);
  }
  /* disabled styles */
  &:disabled {
    background-color: #eaeaeb;
    border: 1px solid #eaeaeb;
  }
  /* disabled placeholder */
  &:disabled::placeholder {
    color: #bdbfbf;
  }
`;

const StyledLabel = styled.label`
  color: #5f5f5f;
  
  font-size: 16px;
  font-weight: ${(props) =>
    props.labelType === "primary"
      ? "700"
      : props.labelType === "secondary"
      ? "400"
      : "700"};
  line-height: 140%;
  letter-spacing: 0.16px;

  ${(props) =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const InviteNewUser = forwardRef((props, ref) => {
  const formMethods = useForm({
    defaultValues: USER_SETTING_AGENT_PROFILE,
  });

  const [isDirtyPassword, setIsDirtyPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [password, setPassword] = useState("");
  const { setAlertModal } = useGeneral();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { data = {}, error } = useProfile();

  const {
    handleSubmit,
    register,
    trigger,
    setValue,
    getValues,
    formState: { errors, isDirty, isValid, dirtyFields },
    setError,
    watch,
    reset,
  } = formMethods;

  function formatPhoneNumber(phoneNumber) {
    // Remove dashes and convert to a string
    const cleanedNumber = phoneNumber.replace(/-/g, "");

    // Return the formatted number with a leading '+'
    return `+${cleanedNumber}`;
  }
  useImperativeHandle(ref, () => ({
    async inviteNewUser() {
      return await onSubmit(getValues());
    },
  }));

  const showAlert = (title, content) => {
    setAlertModal({ show: true, title, content });
  };

  const onSubmit = async (data) => {
    try {
      await trigger();

      if (!isValid) {
        showAlert("Error", "Please input the fields correctly");
        return false;
      }

      if (!isValidPassword) {
        showAlert(
          "Error",
          "Please input the password correctly and confirm it"
        );
        return false;
      }

      const phoneNumber = watch("phone");
      const formattedPhone = formatPhoneNumber(phoneNumber);

      const baseURL = process.env.REACT_APP_NODE_API_URL;
      if (!baseURL) {
        throw new Error("API URL not configured in environment variables");
      }

      const url = `${baseURL}/api/users/agent/create`;
      setIsLoading(true);

      const token = await Auth.currentSession();
      const response = await axios.post(
        url,
        { ...data, phone: formattedPhone, password },
        {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        }
      );

      setIsLoading(false);

      if ([200, 201].includes(response.status)) {
        showAlert("Success", "User Created Successfully");
      } else {
        showAlert("Error", "Error Creating Agent User");
      }

      return true;
    } catch (error) {
      setIsLoading(false);

      if (error?.response?.status === 401) {
        Auth.signOut()
          .then(() => navigate("/admin/login"))
          .catch((err) => console.error("Error signing out:", err));
      } else {
        const errorMsg =
          error.response?.data?.message || "Error Creating Agent User";
        if (errorMsg.includes("User already exists"))
          showAlert("Error", "Invitation Faild : User already exists");
        else showAlert("Error", errorMsg);
      }

      return false;
    }
  };

  return (
    <>
      {isLoading ? (
        <Center h={100}>
          <Loader />
        </Center>
      ) : (
        <div className="d-flex flex-column gap-3">
          <InputContainer className="w-100">
            <StyledLabel className="pb-1" disabled>
              Company Name
            </StyledLabel>
            <StyledInputText
              className="form-control"
              type="text"
              disabled
              placeholder={data?.company?.name ?? ""}
            />
          </InputContainer>
          <InputTextComponent
            required={true}
            label="First Name"
            name="firstName"
            register={register}
            trigger={trigger}
            setValue={setValue}
            error={errors}
            placeholder="Enter first name"
          />
          <InputTextComponent
            required={true}
            label="Last Name"
            name="lastName"
            register={register}
            trigger={trigger}
            setValue={setValue}
            error={errors}
            placeholder="Enter last name"
          />
          <InputPhoneNumberComponent
            required={true}
            label="Phone Number"
            name="phone"
            register={register}
            trigger={trigger}
            watch={watch}
            setValue={setValue}
            error={errors}
            placeholder="1-xxx-xxx-xxxx"
          />
          <InputTextComponent
            required={true}
            register={register}
            trigger={trigger}
            setValue={setValue}
            requiredField={{
              required: "This field is required",
              pattern: emailPattern,
            }}
            label="Email"
            name="email"
            error={errors}
            placeholder="example@email.com"
          />
          <InputPassword
            setIsDirtyPassword={setIsDirtyPassword}
            setIsValidPassword={setIsValidPassword}
            setNewPassword={setPassword}
          />
          <AutocompleteSelect
            required={true}
            register={register}
            trigger={trigger}
            setValue={setValue}
            label="Role"
            name="role"
            placeholder="Select"
            selectedOption="Select"
            options={[
              { id: "agent", description: "Agent - Agent cannot invite new users" },
              {
                id: "admin",
                description: "Admin - Admin can invite new users",
              },
            ]}
            error={errors}
          />
        </div>
      )}
    </>
  );
});

export default InviteNewUser;
