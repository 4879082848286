import React from "react";
import Sidebar from "../../components/SideBar";
import NotificationsTable from "./components/NotificationsTable";
import PageHeader from "../../components/Headers/PageHeader";

const Notifications = () => {
  return (
    <Sidebar>
      <PageHeader>
        <h1 className="fs-4">Notifications</h1>
      </PageHeader>
      <div className="px-4 px-lg-5 py-3">
        <NotificationsTable />
      </div>
    </Sidebar>
  );
};

export default Notifications;
