import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoCameraOutline } from "react-icons/io5";
import { Button } from "react-bootstrap";

const StyledInputImages = styled.div`
  border: 1px dashed #919295;
  border-radius: 6px;
  padding: 10px;
  text-align: center;
  background-color: ${(props) =>
    props.isDragOver ? "#f0f0f0" : "transparent"};
  border-color: ${(props) => (props.isDragOver ? "#5d5fef" : "#99999980")};
  transition: all 0.3s ease;
`;

const StyledPreview = styled.div`
  margin: 10px;
  padding: 5px;
  display: inline-block;
  max-width: 140px;
  position: relative;
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const StyledUploadButton = styled.label`
  display: flex;
  border-radius: 100%;
  padding: 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #5d5fef;
`;

const StyledLabel = styled.label`
  color: #5f5f5f;
  
  font-size: 16px;
  font-weight: ${(props) =>
    props.variantType === "primary"
      ? "700"
      : props.variantType === "secondary"
      ? "400"
      : "700"};
  line-height: 140%;
  letter-spacing: 0.16px;
  padding-bottom: 0px;

  /* disabled styles */
  ${(props) =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;
const StyledCentredSelector = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 25px 0;
`;

const InputImages = ({ description, name, watch, setValue, register, disabled = false }) => {
  const [image, setImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputKey, setInputKey] = useState(0);
  // eslint-disable-next-line
  const [isDragOver, setIsDragOver] = useState(false);

  const files = watch(name);

  const handleImageUpload = (event) => {
    const newFile = event.target.files[0];
    const maxSize = 258 * 1024 * 1024;

    if (!newFile) return;

    if (!newFile.type.includes("image/")) {
      setErrorMessage("Only images are allowed.");
      return;
    }

    if (newFile.size > maxSize) {
      setErrorMessage("File size exceeds the 258MB limit.");
      return;
    }

    setErrorMessage("");

    const newImage = URL.createObjectURL(newFile);
    setImage(newImage);
    setValue(name, newFile, { shouldValidate: true, shouldDirty: true });
    setInputKey((prevKey) => prevKey + 1);
  };

  const handleDeleteImage = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setImage(null);
    setValue(name, null, { shouldValidate: true, shouldDirty: true }); // Clear the form state
  };

  useEffect(() => {
    if (files && files.length > 0) {
      const initialImage = files;
      if (typeof initialImage === "string") {
        setImage(process.env.REACT_APP_IMAGE_PATH + initialImage);
      } else if (initialImage instanceof File) {
        const imageUrl = URL.createObjectURL(initialImage);
        setImage(imageUrl);
      }
    }
  }, [files]);

  const handleDrop = (event) => {
    if(disabled) return ;
    event.preventDefault();
    setIsDragOver(false);

    const droppedFile = event.dataTransfer.files[0];

    if (!droppedFile) return;

    const syntheticEvent = {
      target: {
        files: [droppedFile],
      },
    };

    handleImageUpload(syntheticEvent);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  return (
    <>
      <StyledLabel htmlFor="InputImages">{description}</StyledLabel>
      <StyledInputImages
        isDragOver={isDragOver}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {image ? (
          <StyledPreview>
            <StyledImage src={image} alt="Uploaded" />
            <Button
              onClick={handleDeleteImage}
              className="text-delet-blue no-bg border-0"
              disabled = {disabled}
            >
              Remove
            </Button>
          </StyledPreview>
        ) : (
          <div>
            <StyledCentredSelector>
              <StyledUploadButton htmlFor="uploadImageButton">
                <IoCameraOutline color="white" size={24} />
              </StyledUploadButton>
              <input
                key={inputKey}
                id="uploadImageButton"
                className="d-none"
                type="file"
                accept="image/*"
                {...register(name)}
                onChange={handleImageUpload}
                disabled = {disabled}
              />
              <span className="body-s py-2">
                <span className="text-delet-blue">Drag</span> and{" "}
                <span className="text-delet-blue">drop</span> Files
              </span>
              <span className="body-xs" style={{ color: "#919295" }}>
                Only PNGs or JPGs Max. 258MB
              </span>
            </StyledCentredSelector>
          </div>
        )}
      </StyledInputImages>
      {errorMessage && <p className="error">{errorMessage}</p>}
    </>
  );
};

export default InputImages;
