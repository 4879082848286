import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoCameraOutline } from "react-icons/io5";
import getImageUrl from "../helper/checkImageFormat";

const StyledInputImages = styled.div`
  border: 2px dashed #919295;
  border-radius: 6px;
  padding: 10px;
  text-align: center;
  background-color: ${(props) =>
    props.isDragOver ? "#f0f0f0" : "transparent"};
  border-color: ${(props) => (props.isDragOver ? "#5d5fef" : "#99999980")};
  transition: all 0.3s ease;
`;

const StyledPreview = styled.div({
  margin: "10px",
  padding: "5px",
  display: "inline-block",
  maxWidth: "140px",
  position: "relative",
});

const StyledImage = styled.img({
  maxWidth: "100%",
  height: "auto",
});

const StyledUploadButton = styled.label({
  display: "flex",
  borderRadius: "100%",
  padding: "16px 16px",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "16px",
  fontWeight: "600",
  letterSpacing: "0.16px",
  backgroundColor: "#5D5FEF",
  color: "#FFFFFF",
  cursor: "pointer",
  marginBottom: "15px", // Added margin for spacing between button and text
});

const StyledButtonDelete = styled.button({
  position: "absolute",
  top: "-10px",
  right: "-10px",
  width: "20px",
  height: "20px",
  border: "1px solid #ccc",
  borderRadius: "50%",
  backgroundColor: "#fff",
  color: "#ccc",
  fontSize: "14px",
  lineHeight: "1",
  textAlign: "center",
  cursor: "pointer",
});

const StyledCentredSelector = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "2px",
  padding: "30px 0px", // Added padding for vertical spacing
});

const InputImages = ({ description, name, watch, setValue, register }) => {
  const [images, setImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputKey, setInputKey] = useState(0);
  const [isDragOver, setIsDragOver] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const files = watch(name);

  const handleImageUpload = (event) => {
    const newFiles = Array.from(event.target.files);
    const maxSize = 258 * 1024 * 1024;

    for (let file of newFiles) {
      if (!file.type.includes("image/")) {
        setErrorMessage("Only images are allowed.");
        return;
      }

      if (file.size > maxSize) {
        setErrorMessage("File size exceeds the 258MB limit.");
        return;
      }
    }

    setErrorMessage("");

    // Filter out duplicate files
    const uniqueFiles = newFiles.filter(
      (newFile) =>
        !files.some((existingFile) => existingFile.name === newFile.name)
    );

    const newImages = uniqueFiles.map((file) => URL.createObjectURL(file));
    setImages((prevImages) => [...prevImages, ...newImages]);
    setValue(name, [...files, ...uniqueFiles], {
      shouldValidate: true,
      shouldDirty: true,
    });
    setInputKey((prevKey) => prevKey + 1);
  };

  const handleDeleteImage = (event, index) => {
    event.preventDefault();
    event.stopPropagation();

    const newImages = images.filter((_, i) => i !== index);
    const newFiles = files.filter((_, i) => i !== index);
    setImages(newImages);
    setValue(name, newFiles, { shouldValidate: true, shouldDirty: true });
  };

  useEffect(() => {
    if (images.length === 0 && files) {
      const initialImages = files.map((file) =>
        typeof file === "string" ? getImageUrl(file) : URL.createObjectURL(file)
      );
      setImages(initialImages);
    }
  }, [files, images.length]);

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    const droppedFiles = Array.from(event.dataTransfer.files);

    // Filter out duplicate files
    const uniqueFiles = droppedFiles.filter(
      (file) => !files.some((existingFile) => existingFile.name === file.name)
    );

    if (uniqueFiles.length > 0) {
      handleImageUpload({ target: { files: uniqueFiles } }); // Pass unique files directly
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDropImage = (index) => {
    if (draggedIndex === null) return;

    const reorderedImages = [...images];
    const [movedImage] = reorderedImages.splice(draggedIndex, 1);
    reorderedImages.splice(index, 0, movedImage);
    setImages(reorderedImages);

    const newFiles = [...files];
    const [movedFile] = newFiles.splice(draggedIndex, 1);
    newFiles.splice(index, 0, movedFile);
    setValue(name, newFiles, { shouldValidate: true, shouldDirty: true }); // Update form state

    setDraggedIndex(null);
  };

  return (
    <>
      <label htmlFor="InputImages">{description}</label>
      <StyledInputImages
        isDragOver={isDragOver}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <StyledCentredSelector>
          <StyledUploadButton htmlFor="uploadImageButton">
            <IoCameraOutline color="white" size={30} />
          </StyledUploadButton>
          <input
            key={inputKey}
            id="uploadImageButton"
            className="d-none"
            type="file"
            accept="image/*"
            multiple
            {...register(name)} // Register the input
            onChange={handleImageUpload}
          />
          <span className="body-s py-2" style={{ fontFamily: "Poppins" }}>
            <span style={{ color: "#5271FF", fontWeight: "700" }}>Drag</span>{" "}
            and{" "}
            <span style={{ color: "#5271FF", fontWeight: "700" }}>drop</span>{" "}
            Files
          </span>
          <span
            className="body-xs"
            style={{ color: "#919295", fontFamily: "Poppins" }}
          >
            Only PNGs or JPGs Max. 258MB
          </span>
        </StyledCentredSelector>
        <div>
          {images.map((image, index) => (
            <StyledPreview
              key={index}
              onDrop={() => handleDropImage(index)}
              onDragOver={(e) => e.preventDefault()}
            >
              <StyledButtonDelete
                onClick={(event) => handleDeleteImage(event, index)}
              >
                X
              </StyledButtonDelete>
              <StyledImage
                draggable
                onDragStart={() => handleDragStart(index)}
                src={image}
                alt={`Image ${index + 1}`}
              />
            </StyledPreview>
          ))}
        </div>
      </StyledInputImages>
      {errorMessage && <p className="error">{errorMessage}</p>}
    </>
  );
};

export default InputImages;
