import { Auth } from "aws-amplify";
import axios from "axios";
import {
  hasLength,
  isEmail,
  isNotEmpty,
  matchesField,
  useForm,
} from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Button,
  Title,
  Text,
  Image,
  Container,
  SimpleGrid,
  Stepper,
  Stack,
  Group,
  Center,
  Space,
  Box,
  Progress,
  Modal,
  Flex,
} from "@mantine/core";
import logo from "../../assests/images/logo-dark.svg";
import { useState } from "react";
import classes from "../Auth/SignUp.module.css";
import { Link, useNavigate } from "react-router-dom";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import { CUSTOMER_SUPP_PHONE } from "../../constants";

function SignUp() {
  const [active, setActive] = useState(0);
  const [error, setError] = useState({ show: false, message: "" });
  const [success, setSuccess] = useState({ show: false, message: "" });
  const navigate = useNavigate();
  const requirements = [
    { re: /[0-9]/, label: "Includes number" },
    { re: /[a-z]/, label: "Includes lowercase letter" },
    { re: /[A-Z]/, label: "Includes uppercase letter" },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
  ];

  const isValidPassword = (password) =>
    requirements.find((regex) => !regex.re.test(password)) === undefined;

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
    },

    validate: {
      firstName:
        active === 0 &&
        hasLength({ min: 2, max: 100 }, "Name must be 2-100 characters long"),
      lastName:
        active === 0 &&
        hasLength(
          { min: 2, max: 100 },
          "Last name must be 2-100 characters long"
        ),
      code: (value) =>
        active === 1 &&
        value.length !== 6 &&
        "Confirmation code must be 6 characters long",
      email: active === 0 && isEmail("Invalid email"),
      password: (value) =>
        active === 0 && (isValidPassword(value) ? null : "Invalid password"),
      confirmPassword:
        active === 0 &&
        isNotEmpty("Confirm password") &&
        matchesField("password", "Passwords are not the same"),
    },
  });
  const sendConfirmationEmail = async (email) => {
    if (!email.includes("delet.com")) {
      setError({
        show: true,
        message: "Email must be associated with a delet employee",
      });
      return;
    }
    let url = process.env.REACT_APP_NODE_API_URL + "/api/users/request-code";
    await axios
      .post(url, { email })
      .then(async (response) => {
        setSuccess({ show: true, message: response?.data?.message });
        setActive(active + 1);
      })
      .catch((error) => {
        console.log(error.response);
        setError({ show: true, message: error?.response?.data?.message });
      });
  };

  const nextStep = async () => {
    if (form.validate().hasErrors) {
      return;
    }
    if (active < 1) {
      await sendConfirmationEmail(form.values.email);
    }
  };

  function PasswordRequirement({ meets, label }) {
    return (
      <Text component="div" c={meets ? "teal" : "red"} mt={5} size="sm">
        <Center inline>
          {meets ? (
            <CiCircleCheck size="0.9rem" stroke={1.5} />
          ) : (
            <CiCircleRemove size="0.9rem" stroke={1.5} />
          )}
          <Box ml={7}>{label}</Box>
        </Center>
      </Text>
    );
  }

  function getStrength(password) {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
      if (!requirement.re.test(password)) {
        multiplier += 1;
      }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
  }

  const strength = getStrength(form.values.password);
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(form.values.password)}
    />
  ));
  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ section: { transitionDuration: "0ms" } }}
        value={
          form.values.password.length > 0 && index === 0
            ? 100
            : strength >= ((index + 1) / 4) * 100
            ? 100
            : 0
        }
        color={strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"}
        key={index}
        size={4}
      />
    ));

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.validate().hasErrors) {
      return;
    }
    try {
      let url =
        process.env.REACT_APP_NODE_API_URL + "/api/users/superadmin/register";
      await axios
        .post(url, {
          ...form.values,
          phone: form?.values?.phone?.replace(/[^\d+]/g, ""),
        })
        .then(async (response) => {
          console.log(response);
          await Auth.signIn(form.values.email, form.values.password);
          navigate("/admin/user/list");
        })
        .then(() => {
          nextStep();
        })
        .catch((error) => {
          console.log(error.response);
          setError({ show: true, message: error?.response?.data?.message });
        });
    } catch (error) {
        console.log("Error",error);
    }
  };

  return (
    <Container fluid>
      <Flex
        justify={"space-around"}
        align={"center"}
        className={classes.contentSuperadmin}
        gap={20}
      >
        <Container style={{ width: "500px" }}>
          <SimpleGrid
            dir="row"
            mb={"xl"}
            w={"100%"}
            mx="auto"
            className="text-center"
          >
            <Title className="text-center">
              <Image src={logo} alt="Logo" w={100} className="mx-auto" />
              <span className="text-center fs-3 fw-bold">Admin</span>
            </Title>
          </SimpleGrid>
          <Title className={classes.title} order={3} classNames="text-center">
            Create your account
          </Title>
          <Space h="xl" />
          <form onSubmit={handleSubmit} className={classes.form}>
            <Box active={active} size="xs">
              <Stepper active={active} size="xs">
                <Stepper.Step label="Details">
                  <TextInput
                    label="First name"
                    placeholder="Your name"
                    size="md"
                    mt="md"
                    {...form.getInputProps("firstName")}
                  />
                  <TextInput
                    label="Last name"
                    placeholder="Your last name"
                    size="md"
                    mt="md"
                    {...form.getInputProps("lastName")}
                  />
                  <TextInput
                    withAsterisk
                    type={"email"}
                    label="Email"
                    placeholder="name@company.com"
                    size="md"
                    mt="md"
                    {...form.getInputProps("email")}
                  />
                  <PasswordInput
                    withAsterisk
                    label="Password"
                    placeholder="Secure password"
                    size="md"
                    mt="md"
                    {...form.getInputProps("password")}
                  />
                  {form.values.password.length ? (
                    <>
                      <Group gap={5} grow mt="xs" mb="md">
                        {bars}
                      </Group>

                      <PasswordRequirement
                        label="Has at least 6 characters"
                        meets={form.values.password.length > 5}
                      />
                      {checks}
                    </>
                  ) : null}
                  <PasswordInput
                    withAsterisk
                    label="Confirm password"
                    placeholder="Repeat your password"
                    size="md"
                    mt="md"
                    {...form.getInputProps("confirmPassword")}
                  />
                </Stepper.Step>
                <Stepper.Step label="Confirmation">
                  <TextInput
                    withAsterisk
                    type={"text"}
                    label="Confirmation code"
                    placeholder="Enter code"
                    size="md"
                    mt="md"
                    maxLength={6}
                    {...form.getInputProps("code")}
                  />
                </Stepper.Step>
                <Stepper.Completed>
                  <Container my={50}>
                    <Stack align="center">
                      <Title ta={"center"}>Welcome!</Title>
                      <Space h="xl" />
                      <Container>
                        <Button
                          ta={"center"}
                          size="md"
                          onClick={() => navigate("/property/add")}
                        >
                          Create your first property
                        </Button>
                      </Container>
                      <Space h="xl" />

                      <Text ta="center">
                        If you have any further question please contact us!
                      </Text>
                      <Text ta="center">
                        Email: sales@delet.com or call {CUSTOMER_SUPP_PHONE}
                      </Text>
                    </Stack>
                  </Container>
                </Stepper.Completed>
              </Stepper>
            </Box>
            {active === 0 && (
              <Text size="sm" ta={"end"}>
                Already have an account? <Link to={"/admin/login"}>Log in</Link>
              </Text>
            )}
            <Group justify="flex-end" mt="xl">
              {active === 0 && <Button onClick={nextStep}>Next step</Button>}
              {/* {active > 0 && (
              <Button variant="default" onClick={prevStep}>Back</Button>
            )} */}
              {active === 1 && <Button type="submit">Sign up</Button>}
            </Group>
          </form>
        </Container>
      </Flex>

      <Modal
        size="sm"
        title="Error Signing Up"
        opened={error.show}
        onClose={() => setError({ show: false, message: "" })}
        centered
      >
        {error.message}
      </Modal>
      <Modal
        size="sm"
        title="Signing Up success"
        opened={success.show}
        onClose={() => setSuccess({ show: false, message: "" })}
        centered
      >
        {success.message}
      </Modal>
    </Container>
  );
}

export default SignUp;
