import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  getNotificationsList,
  patchReadNotification,
} from "../../../api/notifications";
import { useInView } from "react-intersection-observer";
import NotificationCard from "./NotificationCard";
import { SocketContext } from "../../../context/SocketContext";
import { Nav } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import "./styles.scss";
import LeadsFilter from "../../Leads/components/LeadsFilter";
import { format } from "date-fns-tz";

const tabs = ["all", "unread", "locks"];

const NotificationsTable = ({ hideFilters = false }) => {
  const socket = useContext(SocketContext);
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentFilter = searchParams.get("filter") || "all";

  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    selectedProperties: null,
    search: "",
  });

  const memoizedFilters = useMemo(() => filters, [filters]);
  const queryKey = [
    "notifications",
    currentFilter,
    memoizedFilters.startDate,
    memoizedFilters.endDate,
    memoizedFilters.selectedProperties,
  ];
  const {
    data: notifications,
    isLoading,
    isFetchingNextPage,
    isError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: queryKey,
    queryFn: ({ pageParam = 1 }) =>
      getNotificationsList(pageParam, {
        filter: currentFilter,
        ...memoizedFilters,
      }),
    getNextPageParam: (lastPage, pages) =>
      lastPage.hasMore ? pages.length + 1 : undefined,
  });

  const { mutateAsync: markAsRead } = useMutation({
    mutationFn: ({ notificationId, pageIndex, notificationIndex }) =>
      patchReadNotification(notificationId),
    onSuccess: async (_, { notificationId, pageIndex, notificationIndex }) => {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      queryClient.setQueryData(queryKey, (oldData) => ({
        ...oldData,
        pages: oldData.pages.map((page, pIndex) =>
          pIndex === pageIndex
            ? {
                ...page,
                data: page.data.map((item, iIndex) =>
                  iIndex === notificationIndex ? { ...item, read: true } : item
                ),
              }
            : page
        ),
      }));

      queryClient.setQueryData(["unread-notifications-count"], (prevCount) => {
        if (prevCount) {
          return {
            ...prevCount,
            count: prevCount.count - 1,
          };
        }
      });
    },
    onError: () => console.error("Notification not updated"),
  });

  const handleNavClick = (filter) => (event) => {
    event.preventDefault();
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("filter", filter);
      return newParams;
    });
  };

  const handleApplyFilters = ({ selectedProperties, startDate, endDate }) => {
    const newFilters = {};
    if (startDate)
      newFilters.startDate = format(new Date(startDate), "yyyy-MM-dd");
    if (endDate) newFilters.endDate = format(new Date(endDate), "yyyy-MM-dd");
    if (selectedProperties)
      newFilters.selectedProperties = selectedProperties
        ?.map((property) => property._id)
        .join();

    setFilters(newFilters);
  };

  const handleResetFilters = () => {
    setFilters({
      startDate: null,
      endDate: null,
      selectedProperties: null,
    });
  };

  const handleReset = () => {
    setFilters({
      ...filters,
      search: "",
    });
  };

  const observer = useInView({
    onChange: (inView) => {
      if (inView && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  useEffect(() => {
    socket.on("newNotification", (notification) => {
      console.log("NEW NOTIFICATION: ", notification);
    });

    return () => {
      socket.off("newNotification");
    };
  }, [socket]);

  return (
    <div className="d-flex flex-column">
      {!hideFilters && (
        <>
          <LeadsFilter
            handleReset={handleReset}
            handleResetFilters={handleResetFilters}
            handleApplyFilters={handleApplyFilters}
          />
          <div className="py-2">
            <Nav
              variant="underline"
              defaultActiveKey="/home"
              className="border-bottom gap-0"
            >
              {tabs.map((tab) => (
                <Nav.Item>
                  <Nav.Link
                    href="#"
                    className={`text-uppercase py-0 px-3 ${
                      currentFilter !== tab ? "text-secondary" : ""
                    }`}
                    active={currentFilter === tab}
                    onClick={handleNavClick(tab)}
                  >
                    {tab}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
        </>
      )}
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          getting notifications...
        </div>
      ) : isError ? (
        <div>Error getting notifications</div>
      ) : notifications.pages?.[0]?.data?.length > 0 ? (
        notifications.pages.map((notificationsPage, page) => {
          return (
            <>
              {notificationsPage.data.map((notification, index) => (
                <NotificationCard
                  key={notification._id}
                  notification={notification}
                  page={page}
                  ref={
                    page === notifications.pages.length - 1 &&
                    index === notificationsPage.data.length - 1
                      ? observer.ref
                      : undefined
                  }
                  index={index}
                  markAsRead={markAsRead}
                />
              ))}
              {isFetchingNextPage && <div>Getting more notifications...</div>}
            </>
          );
        })
      ) : (
        <div>No notifications found</div>
      )}
    </div>
  );
};

export default NotificationsTable;
