import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { Auth } from "aws-amplify";

import { BiBriefcase, BiCamera, BiHomeCircle, BiUser } from "react-icons/bi";
import { Link, NavLink, useNavigate } from "react-router-dom";

import LeftArrow from "../../../assests/images/left-arrow.png";
import Logo from "../../../assests/images/Logo.svg";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { Button } from "react-bootstrap";

const Sidebar = ({ children }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const handleLogout = async () => {
    try {
      await Auth.signOut();
      navigate("/admin/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="mobile-header">
        <div className="mobile-header-wrap">
          <div className="panel-expand-control">
            <a
              href="#!"
              onClick={toggle}
              className="d-block d-md-none hamburger-icon"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
            </a>
          </div>
          <div className="logo d-block d-md-none">
            <NavLink to="/" className="link">
              <img src={Logo} alt="Logo" width="115" height="27" />
            </NavLink>
          </div>
        </div>
        <div className={isOpen ? "sidebar  sidebar-active" : "sidebar"}>
          <div className="sidebar-top pt-3 pt-xl-5">
            <a
              href="#!"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
              </svg>
            </a>
            <div className="p-3">
              <NavLink to="/admin/user/list">
                <img src={Logo} alt="Logo" width="115" height="27" />
              </NavLink>
            </div>
            <ul className="d-flex flex-column justify-content-between justify-content-xxl-start h-100 pt-3 pt-xxl-5">
              <li>
                <NavLink
                  to="/admin/user/list"
                  className="d-flex align-items-center gap-3 w-100 p-3 position-relative"
                >
                  <span>
                    <BiHomeCircle name="home-circle" color="grey" size={25} />
                    <i className="bx bx-hot" />
                  </span>
                  <span className="link-text">Users</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/hardware/list" className="d-flex align-items-center gap-3 w-100 p-3 position-relative">
                  <BiCamera name="camera" color="grey" size={25} />

                  <span className="link-text">Hardware</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/kit/list"
                  className="d-flex align-items-center gap-3 w-100 p-3 position-relative"
                >
                  <BiBriefcase name="briefcase" color="grey" size={25} />
                  <span className="link-text">Kit</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/orders"
                  className="d-flex align-items-center gap-3 w-100 p-3 position-relative"
                >
                  <BiBriefcase name="briefcase" color="grey" size={25} />
                  <span className="link-text">Orders</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/admin/invoices"
                  className="d-flex align-items-center gap-3 w-100 p-3 position-relative"
                >
                  <LiaFileInvoiceDollarSolid
                    name="briefcase"
                    color="grey"
                    size={25}
                  />

                  <span className="link-text">Invoices</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/admin/shipment/list"
                  className="d-flex align-items-center gap-3 w-100 p-3 position-relative"
                >
                  <LiaFileInvoiceDollarSolid
                    name="briefcase"
                    color="grey"
                    size={25}
                  />
                  <span className="link-text">Shipments</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/admin/account/list"
                  className="d-flex align-items-center gap-3 w-100 p-3 position-relative"
                >
                  <BiBriefcase name="briefcase" color="grey" size={25} />
                  <span className="link-text">Accounts</span>
                </NavLink>
              </li>

              <li>
                <Button onClick={handleLogout} className="d-flex align-items-center gap-3 w-100 p-3 position-relative">
                  <BiUser name="user" color="grey" size={25} />
                  <span className="link-text">Logout</span>
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <main className={isOpen ? "main-left" : ""}>{children}</main>
    </>
  );
};

export default Sidebar;
