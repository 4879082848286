import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledButton = styled(Link)`
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    
    white-space: nowrap;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.16px;
    text-decoration: underline;
    color:${(props) => {
        if (props.variantType === 'primary') return '#5D5FEF';
        if (props.variantType === 'secondary') return '#000000';
        return '#FFFFFF';
    }};

    &:hover,
    &:focus {
        color:${(props) => {
        if (props.variantType === 'primary') return '#4143A7';
        if (props.variantType === 'secondary') return '#919295';
        return '#FFFFFF';
    }};
    }
    &:disabled {
        color: #BDBEBF;
        background-color: ${(props) => {
        if (props.variantType === 'primary') return '#EAEAEB';
        if (props.variantType === 'secondary') return '#FFFFFF';
        return '#EAEAEB';
    }};
    }
    & > svg {
        height: 16px;
        width: 16px;
    }
`


/**
 * variantType: primary, secondary
 * icon: ReactElement
 * disable: Bool
 */
const ButtonUnderlineText = (props) => {
    const { children, variantType } = props

    return (
        <StyledButton variantType={variantType} {...props} >
            {props.icon}
            {children}
        </StyledButton >
    );
}

export default ButtonUnderlineText;