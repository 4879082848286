import React from "react";
import styled from "styled-components";

const StyledSelect = styled.select`
  height: 40px;
  color: #66676b;
  width: 100%;
  border-radius: 4px;
  border-color: #bdbebf;
  padding: 8px 24px 8px 8px;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' fill='%23000'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 24px 24px;

  &:disabled {
    height: 40px;
    width: 100%;
    border-radius: 4px;
    border: #eaeaeb;
    background-color: #eaeaeb;
    color: #bdbfbf;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' fill='%23bdbfbf'/%3E%3C/svg%3E");
  }
`;

const StyledLabel = styled.label`
  color: #66676b;
  font-size: 16px;
  font-weight: ${(props) =>
    props.variantType === "primary"
      ? "700"
      : props.variantType === "secondary"
      ? "400"
      : "700"};
  line-height: 140%;
  letter-spacing: 0.16px;
  padding-bottom: 0px;

  /* disabled styles */
  ${(props) =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;

const AutocompleteSelect = ({
  register,
  trigger,
  required = false,
  setValue,
  disabled = false,
  name,
  values,
  options,
  selectedOption,
  placeholder,
  // inputRef,
  error = {},
  ...props
}) => {
  const handleTrigger = (event) => {
    const value = event.target.value;
    // Set the value and mark the field as dirty
    setValue(name, value, { shouldValidate: true, shouldDirty: true });
    // Trigger validation
    trigger(name);
  };

  return (
    <fieldset className="w-100">
      <StyledLabel
        variantType={props?.variantType}
        htmlFor={props?.name}
        disabled={disabled}
      >
        {props?.label}
      </StyledLabel>
      <StyledSelect
        {...(required
          ? register(name, { required: "This field is required" })
          : register(name))}
        onChange={handleTrigger}
        onBlur={() => trigger(name)}
        // ref={inputRef}
        {...props}
      >
        <option value="">{placeholder}</option>
        {options.map((value, index) => (
          <option
            key={index}
            value={typeof value === "string" ? value : value.id}
          >
            {typeof value === "string" ? value : value.description}
          </option>
        ))}
      </StyledSelect>
      {error[name] && error[name].message && (
        <p className="error">{error[name].message}</p>
      )}
    </fieldset>
  );
};

export default AutocompleteSelect;
