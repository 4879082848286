import { Box, Tab, Tabs } from "@mui/material";
import styled from "styled-components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ProfilePanel from "./components/ProfilePanel";
import CalendarPanel from "./components/CalendarPanel";
import IntegrationsPanel from "./components/IntegrationsPanel";
import UsersPanel from "./components/UsersPanel";
import NotificationsPanel from "./components/NotificationsPanel";
import CommunicationPanel from "./components/CommunicationPanel";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import { useGeneral } from "../../../context/GeneralContext";
import Alert from "../../../components/Alert";
import axios from "axios";
import { Auth } from "aws-amplify";
import { useProfile } from "../../../context/UserProfileContext";
import { Center, Loader } from "@mantine/core";

export const StyledGrayButton = styled.button`
  background-color: gray !important;
  border-color: gray !important;
  transition: border 0.3s;

  &:hover {
    border-color: white !important; /* Border disappears on hover */
  }
`;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const subTabs = [
  {
    value: 0,
    name: "profile",
  },
  {
    value: 1,
    name: "calendar",
  },
  {
    value: 2,
    name: "users",
  },
  {
    value: 3,
    name: "integrations",
  },
  {
    value: 4,
    name: "notifications",
  },
  {
    value: 5,
    name: "communications",
  },
];

const SettingTab = () => {
  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();
  const [isDirty, setIsDirty] = useState(false);
  const { setAlertModal } = useGeneral();
  const [proceed, setProceed] = useState();
  const [navigateTo, setNavigateTo] = useState();
  const navigate = useNavigate();
  const [isLoad, setIsLoading] = useState(false);
  const [isAllowed, setIsAllowed] = useState(true);
  const { data = {}, refetch, isLoading } = useProfile();
  const numberOfRefs = 2;
  const childTabRefs = useRef(
    Array.from({ length: numberOfRefs }, () => React.createRef())
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const subTab = queryParams.get("subTab");
    if (!subTab)
      window.history.pushState(
        {},
        "",
        `/account?activeTab=settings&subTab=profile`
      );
    if (subTabs.find((tab) => tab.name === subTab))
      setTabValue(subTabs.find((tab) => tab.name === subTab).value);
  }, [location.search]);

  useEffect(() => {
    window.history.pushState(
      {},
      "",
      `/account?activeTab=settings&subTab=${
        subTabs.find((tab) => tab.value === tabValue).name
      }`
    );
  }, [tabValue]);

  const showAlert = useCallback(
    (title, content) => {
      setAlertModal({ show: true, title, content });
    },
    [setAlertModal]
  );

  const getUserInfo = useCallback(
    (token) => {
      refetch();
      var url =
        process.env.REACT_APP_NODE_API_URL +
        "/api/users/allowAgentsToViewUsers";
      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          if (response.status === 200) {
            setIsAllowed(response.data.allowAgentsToViewUsers);
            setIsLoading(true);
          } else {
            showAlert("Error", "Error Getting User Info");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut()
              .then(() => {
                navigate("/admin/login");
              })
              .catch((error) => {
                console.log("Error signing out: ", error);
              });
          } else {
            showAlert("Error", "Error Getting User Info");
          }
        });
    },
    [navigate, showAlert]
  );

  useEffect(() => {
    async function getTokenForUserInfo() {
      try {
        const token = await Auth.currentSession();
        if (!token) {
          navigate("/admin/login");
        }
        getUserInfo(token.getIdToken().getJwtToken());
      } catch (error) {
        navigate("/admin/login");
      }
    }
    getTokenForUserInfo();
  }, [getUserInfo, navigate]);

  const handleChange = (event, newValue) => {
    if (isDirty) {
      setProceed(true);
      setNavigateTo(
        `/account?activeTab=settings&subTab=${
          subTabs.find((tab) => tab.value === newValue).name
        }`
      );
      return;
    }
    setTabValue(newValue);
  };

  const handleNavigation = ({ nextLocation }) => {
    if (isDirty) {
      setProceed(true);
      setNavigateTo(nextLocation.pathname);
      return true;
    }
    return false;
  };

  useBlocker(handleNavigation);

  const saveFormData = () => {
    childTabRefs.current[tabValue].current.saveForm();
  };

  const next = () => {
    if (isDirty) {
      setProceed(true);
      const nextTab =
        data.role === "agent"
          ? isAllowed
            ? tabValue === 2
              ? 0
              : tabValue + 1
            : tabValue === 1
            ? 0
            : tabValue + 1
          : tabValue === 2
          ? 0
          : tabValue + 1;

      setNavigateTo(
        `/account?activeTab=settings&subTab=${
          subTabs.find((tab) => tab.value === nextTab).name
        }`
      );
      return;
    }
    setTabValue((prev) =>
      data.role === "agent"
        ? isAllowed
          ? prev === 2
            ? 0
            : prev + 1
          : prev === 1
          ? 0
          : prev + 1
        : prev === 2
        ? 0
        : prev + 1
    );
  };

  const prev = () => {
    if (isDirty) {
      setProceed(true);
      const nextTab = tabValue === 0 ? 1 : tabValue - 1;
      setNavigateTo(
        `/account?activeTab=settings&subTab=${
          subTabs.find((tab) => tab.value === nextTab).name
        }`
      );
      return;
    }
    setTabValue((prev) => (prev === 0 ? 1 : prev - 1));
  };

  return (
    <>
      {isLoad === false || isLoading ? (
        <>
          <Center h={100}>
            <Loader />
          </Center>
        </>
      ) : (
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="">
            <Tabs
              value={tabValue}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              className="px-4 px-lg-5"
            >
              <Tab label="PROFILE" {...a11yProps(0)} />
              <Tab label="CALENDAR" {...a11yProps(1)} />
              {data.role === "agent" ? (
                isAllowed ? (
                  <Tab
                    label="ACCESIBILITY"
                    className="d-none d-lg-block"
                    {...a11yProps(2)}
                  />
                ) : (
                  ""
                )
              ) : (
                <Tab
                  label="ACCESIBILITY"
                  className="d-none d-lg-block"
                  {...a11yProps(2)}
                />
              )}
            </Tabs>
          </Box>
          <ProfilePanel
            value={tabValue}
            index={0}
            next={next}
            prev={prev}
            setIsDirty={setIsDirty}
            ref={childTabRefs.current[0]}
          ></ProfilePanel>
          <CalendarPanel
            value={tabValue}
            index={1}
            next={next}
            prev={prev}
            setIsDirty={setIsDirty}
            ref={childTabRefs.current[1]}
          ></CalendarPanel>
          <UsersPanel
            value={tabValue}
            index={2}
            next={next}
            prev={prev}
          ></UsersPanel>
        </Box>
      )}
      {proceed && (
        <Alert
          show={proceed}
          setShow={() => {
            saveFormData();
            setProceed(false);
          }}
          navigateTo={navigateTo}
          title={"You have unsaved changes. Are you sure you want to exit?"}
          content={"All unsaved changes will be lost."}
        />
      )}
    </>
  );
};

export default SettingTab;
