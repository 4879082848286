import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";

import axios from "axios";
import { Col, Row, Tab, OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { Auth } from "aws-amplify";

import Sidebar from "../../components/SideBar";
import { ChevronRight } from "react-feather";
import Details from "./tabs/Details";
import Tabs from "../../components/Tabs";
import LockButton from "../../components/LockButton";
import DoorConfirmation from "./components/DoorConfirmation";
import System from "./tabs/System";
import CameraFeed from "./tabs/CameraFeed";
import Leads from "./tabs/Leads";
import { useGeneral } from "../../context/GeneralContext";
import { useProperty } from "../../context/PropertyContext";
import UseHttp from "../../hooks/UseHttp";
import LoaderRing from "../../components/LoaderRing";
import removeUndefinedWord from "../../helper/updateString";
import { MdRefresh } from "react-icons/md";
import Users from "./tabs/Users";
import useUser from "../../hooks/useCurrentUser";
import DoorStatus from "./components/DoorStatus";
import LockButtonMobile from "./../../components/LockButtonMobile";
import { BiSolidDoorOpen } from "react-icons/bi";
import MobileTabs from "../../components/MobileTabs";
import MobileDoorStatus from "./components/Mobile/Mobile-DoorStatus";
import MobileBatteryStatus from "./components/Mobile/Mobile-BatteryStatus";
import BatteryStatus from "./components/BatteryStatus";
import { ImPower } from "react-icons/im";
import PageHeader from "../../components/Headers/PageHeader";

const PropertiesList = () => {
  const [currentLock, setCurrentLock] = useState(undefined); // used to track if the property has a lock
  const [lockStatus, setLockStatus] = useState(false); // used to send a command to lock or unlock
  const [doorStatus, setDoorStatus] = useState("");
  const [bridgeStatus, setBridgeStatus] = useState("offline");
  const [batteryStatus, setBatteryStatus] = useState(0);
  const [vendor, setVendor] = useState("");
  const [lockUncalibrated, setLockUncalibrated] = useState(false); // if the lock is uncalibrated
  const [showLockConfirmation, setShowLockConfirmation] = useState(false); // used to control lock modal display
  const [loadingLockState, setLoadingLockState] = useState(false); // used when locking/unlocking the lock.
  const [isRefreshing, setIsRefreshing] = useState(false); // used when fetching lock status
  const [lockError, setLockError] = useState("");
  const [screenWidth, setScreenWidth] = useState(() => window.innerWidth);

  const params = useParams();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("details");
  const { setAlertModal } = useGeneral();
  const { userData } = useUser();
  const {
    property,
    setProperty,
    setCameras,
    setCameraUrl,
    setShowToast,
    leadsCount,
  } = useProperty();

  const {
    isLoading: propertyLoading,
    error: propertyError,
    requestData: handleGetProperty,
  } = UseHttp();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Checking if query params including activeTab
    const params = new URLSearchParams(location.search);
    const activeTabParam = params.get("activeTab");

    if (activeTabParam) {
      setActiveTab(activeTabParam);
    }
  }, [location.search]);

  // Run useEffect once to get Property details.
  useEffect(() => {
    const id = params.id; // Get the property id from the URL in the browser

    if (id) {
      getPropertyByID(id);
      if (!window.location.href.includes("localhost")) {
        ReactGA.initialize(`${process.env.REACT_APP_GA}`);
      }
    }
  }, []);

  // Check lock status if currentLock gets updated
  useEffect(() => {
    doorStatusApi();
  }, [currentLock, property.kit]);

  const handleTabChange = (eventKey) => {
    // Update the URL with the active tab query parameter
    window.history.pushState(
      {},
      "",
      `/property/${params.id}?activeTab=${eventKey}`
    );
    setActiveTab(eventKey);
  };

  const loadHardware = (hardware) => {
    // Load Cameras
    let cameras = hardware.filter((hw) => hw.category === "camera");
    setCameras(cameras);

    const newArray = cameras.map((obj) => ({ [obj._id]: "Stream error" }));

    setCameraUrl(() => newArray);

    const toastObj = {};

    cameras.forEach((element) => {
      toastObj[element._id] = false;
    });

    setShowToast(toastObj);

    // Check if the property has a lock
    let lock = hardware.find((hw) => hw.category === "lock");
    setCurrentLock(lock);
  };

  function getPropertyByID(id) {
    setProperty({});
    setCameras([]);

    const processData = (data) => {
      setProperty(data);
      if (data.kit) {
        if (data.kit.hardware) {
          loadHardware(data.kit.hardware);
        }
      }
    };

    Auth.currentSession().then((token) => {
      const requestConfig = {
        url: `${process.env.REACT_APP_NODE_API_URL}/api/property/test/${id}`,
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      };
      handleGetProperty(requestConfig, processData);
    });
  }

  useEffect(() => {
    if (propertyError) {
      const showAlert = () =>
        setAlertModal({
          show: true,
          title: propertyError.title,
          content: propertyError.content,
        });
      showAlert();
    }
  }, [propertyError]);

  /* 
        To-Do : Revame the logic to handle error condition better 
    */
  function doorStatusApi() {
    // While the button is refreshing
    if (property.kit) {
      setIsRefreshing(true);
      setLoadingLockState(true);

      Auth.currentSession().then((token) => {
        const lock_status_url = `${process.env.REACT_APP_NODE_API_URL}/api/lock/${currentLock?._id}/status`;
        const options = {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        };

        axios
          .get(lock_status_url, options)
          .then((response) => {
            let buttonColor = "";
            if (response.status === 200) {
              setDoorStatus(response.data.doorStatus);
              setBatteryStatus(response.data.batteryStatus);

              setBridgeStatus(response.data.bridgeStatus);
              setVendor(response.data.vendor);
              if (response.data.lockStatus === "Open") {
                setLockStatus(true);
                buttonColor = "bg-green";
                setLockUncalibrated(false);
              } else if (response.data.lockStatus === "Locked") {
                setLockStatus(false);
                buttonColor = "bg-red";
                setLockUncalibrated(false);
              } else if (
                response.data.lockStatus === "uncalibrated|locked" ||
                response.data.lockStatus === "uncalibrated|unlocked"
              ) {
                setLockUncalibrated(true);
              }
              setLockError("");
            }
            setIsRefreshing(false);

            if (response.status >= 490 && response.status <= 494) {
              console.error(response.data);
              setLockError(response.data);
            }
          })
          .catch((error) => {
            //setLockError(error.response.data.message ?? 'This property has no associated lock');
            console.error("ERROR: ", error);
          })
          .finally(() => {
            setIsRefreshing(false);
            setLoadingLockState(false);
          });
      });
    } else {
      setBridgeStatus("offline");
    }
  }

  const handleLock = async (action) => {
    setShowLockConfirmation(false); // Hide Lock confirmation modal

    try {
      setLoadingLockState(true); // Locking/unlocking in process

      const lock_action_url = `${process.env.REACT_APP_NODE_API_URL}/api/lock/${currentLock._id}/${action}`;

      const token = await Auth.currentSession();
      const options = {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      };

      const response = await axios.put(lock_action_url, {}, options);
      setLoadingLockState(false); // Locking/unlocking no longer in process

      // Successful locking/unlocking
      if (response.status === 200) {
        if (response.data.status === "unlocked") {
          setLockStatus(true);
        } else if (response.data.status === "locked") {
          setLockStatus(false);
        }
        setLockError(""); // No error
      } else {
        throw new Error("Lock not connected. Please contact support.");
      }
    } catch (error) {
      setLoadingLockState(false);

      console.error("ERROR: ", error);
      if (
        error.response &&
        error.response.status &&
        error.response.status === 491
      ) {
        // Hub Offline
        setLockError("Hub is offline.");
      } else {
        // Other errors
        setLockError("Lock not connected. Please contact support.");
      }
    }
  };

  return (
    <>
      <Sidebar>
        {screenWidth < 755 ? (
          <div className="property-head">
            <div>
              <div className=" p-3 d-flex align-items-center justify-content-center bg-delet-header">
                <div className="d-flex align-items-center">
                  <span className="body-mobile py-1" style={{ color: "white" }}>
                    {property.shortAddress}
                    {property?.unit &&
                      property?.unit !== "" &&
                      `, Unit ${property.unit}`}
                  </span>
                </div>
              </div>
            </div>
            <Row
              className="d-flex flex-column align-items-top justify-content-center bg-delet-pink pb-4 pt-4 m-0"
              style={{
                paddingLeft: 32,
                paddingRight: 32,
              }}
            >
              {/* Lock button and Edit property button */}
              <Col className="d-flex gap-3 justify-content-end">
                <div className="d-flex gap-3 justify-content-end align-items-center">
                  {currentLock && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="refresh" className="status-tooltip">
                          Refresh lock status
                        </Tooltip>
                      }
                    >
                      <div className="rounded-circle align-items-center justify-content-center p-2  bg-delet-secondary">
                        <span>
                          <MdRefresh
                            style={{
                              width: "25px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            color="white"
                            onClick={() => doorStatusApi()}
                            className={`${isRefreshing ? "rotate" : ""}`}
                          />
                        </span>
                      </div>
                    </OverlayTrigger>
                  )}

                  <MobileDoorStatus
                    status={doorStatus}
                    bridgeStatus={bridgeStatus}
                  />
                  <MobileBatteryStatus
                    level={batteryStatus}
                    bridgeStatus={bridgeStatus}
                    vendor={vendor}
                  />
                  <LockButtonMobile
                    status={lockStatus}
                    bridgeStatus={bridgeStatus}
                    lockUncalibrated={lockUncalibrated}
                    loading={loadingLockState}
                    isRefreshing={isRefreshing}
                    lockError={lockError}
                    currentLock={currentLock}
                    onClick={() => setShowLockConfirmation(true)}
                  />
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <PageHeader>
            <div className="d-flex justify-content-between flex-wrap">
              <div className="d-flex align-items-center flex-wrap flex-xl-nowrap justify-content-center justify-content-xl-start">
                <h2 className="prop-header">Properties</h2>
                <ChevronRight className="mx-2" color="#434449" />
                <div className="d-flex align-items-center ">
                  <span className="py-1 prop-subheader">
                    {removeUndefinedWord(property?.shortAddress)}
                    {property?.unit &&
                      property?.unit !== "" &&
                      `, Unit ${property.unit}`}
                  </span>
                </div>
              </div>

              <div className="d-flex gap-3 justify-content-end align-items-center">
                {currentLock && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="refresh" className="status-tooltip">
                        Refresh lock status
                      </Tooltip>
                    }
                  >
                    <span
                      className="rounded-circle d-flex align-items-center justify-content-center bg-delet-secondary"
                      style={{ height: "55px", width: "55px" }}
                    >
                      <MdRefresh
                        style={{
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                          color: "#FFFFFF",
                          // marginTop: "10px",
                        }}
                        onClick={() => doorStatusApi()}
                        className={`${isRefreshing ? "rotate" : ""}`}
                      />
                    </span>
                  </OverlayTrigger>
                )}

                <DoorStatus status={doorStatus} bridgeStatus={bridgeStatus} />
                <BatteryStatus
                  level={batteryStatus}
                  bridgeStatus={bridgeStatus}
                  vendor={vendor}
                />

                <LockButton
                  status={lockStatus}
                  bridgeStatus={bridgeStatus}
                  lockUncalibrated={lockUncalibrated}
                  loading={loadingLockState}
                  isRefreshing={isRefreshing}
                  lockError={lockError}
                  currentLock={currentLock}
                  onClick={() => setShowLockConfirmation(true)}
                />
              </div>
            </div>
          </PageHeader>
        )}

        {propertyLoading && !propertyError ? (
          <div className="d-flex justify-content-center">
            <LoaderRing height="100" width="100" />
          </div>
        ) : screenWidth < 755 ? (
          <div>
            <MobileTabs
              defaultActiveKey={activeTab}
              onSelect={handleTabChange}
              style={{ paddingLeft: "10px", backgroundColor: "#EAEAEB" }}
            >
              <Tab
                eventKey="details"
                title={screenWidth > 755 ? "Property Details" : "Details"}
                className="container-div"
              >
                <Details />
              </Tab>
              <Tab
                eventKey="cameras"
                title={screenWidth > 755 ? "Camera Feed" : "Cameras"}
              >
                <CameraFeed />
              </Tab>
              <Tab eventKey="leads" title={`Leads `}>
                <Leads />
              </Tab>
              {userData?.role !== "agent" && (
                <Tab
                  eventKey="system"
                  title={
                    screenWidth > 755
                      ? "Delet System Settings"
                      : "System Settings"
                  }
                >
                  <System reloadHardware={loadHardware} />
                </Tab>
              )}
            </MobileTabs>
          </div>
        ) : (
          <div>
            <Tabs
              defaultActiveKey={activeTab}
              onSelect={handleTabChange}
              className="bg-secondary-subtle px-4 px-lg-5"
            >
              <Tab
                eventKey="details"
                title="Property Details"
                className="container-div"
              >
                <Details />
              </Tab>
              <Tab eventKey="cameras" title="Camera Feed">
                <CameraFeed />
              </Tab>
              <Tab
                eventKey="leads"
                title={`Leads (${leadsCount.all ? leadsCount.all : 0})`}
              >
                <Leads property={property.address} />
              </Tab>
              {userData?.role !== "agent" && (
                <Tab eventKey="system" title="Delet System Settings" className="fw-bold">
                  <System reloadHardware={loadHardware} />
                </Tab>
              )}
            </Tabs>
          </div>
        )}
      </Sidebar>

      {/* {lockStatus ? 'Yes, Lock Door' : 'Yes, Unlock Door'} */}
      <DoorConfirmation
        show={showLockConfirmation}
        status={lockStatus}
        loading={loadingLockState}
        onCancel={() => setShowLockConfirmation(false)}
        onConfirm={() =>
          lockStatus ? handleLock("lock") : handleLock("unlock")
        }
        address={removeUndefinedWord(property?.address)}
        unit={property?.unit}
      />
    </>
  );
};

export default PropertiesList;
