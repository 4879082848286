import 'react-datepicker/dist/react-datepicker.css';
import { Home, Users, Calendar, Settings, LogOut } from 'react-feather';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import copy from 'copy-to-clipboard';
import {
  Button,
  Modal,
} from 'react-bootstrap';
import {
  NavLink,
  useNavigate,
} from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import User from '../assests/images/ID.svg';
import Logo from '../assests/images/Logo.svg';
import PlaceHolder from '../assests/images/1-placeholder-X2.png'
import getImageUrl from '../helper/checkImageFormat';
import { BiBriefcase } from 'react-icons/bi';
import { useQueryClient } from '@tanstack/react-query';
import { useProfile } from '../context/UserProfileContext';
import { LuMenu } from 'react-icons/lu';


const Sidebar = ({ children, disabled }) => {
  // alert modal
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [showcopyToClipboard, setShowcopyToClipboard] = useState(false);
  const sidebarRef = useRef(null);

  const queryClient = useQueryClient();

  const { data = {}, isLoading, error } = useProfile()
  useEffect(() => {
    const hubListener = Hub.listen('auth', ({ payload: { event } }) => {
      // eslint-disable-next-line default-case
      switch (event) {
        case 'signOut':
          // Clear the user data from React Query cache
          queryClient.removeQueries(['userData']);
          break;
        case 'signIn':
          // Refetch user data
          queryClient.invalidateQueries(['userData']);
          break;
      }
    });

    return () => {
      hubListener();
    };
  }, [queryClient]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await Auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };
  const copyToClipboard = async () => {
    try {
      const authData = await Auth.currentSession()
      const userId = authData.getIdToken().payload['custom:_id']
      var link = process.env.REACT_APP_URL + "/book/list/" + userId;
      copy(link);
      setShowcopyToClipboard(true)
    } catch (error) {
      console.log(error);
    }

  }
  return (
    <>
      <div className='mobile-header'>
        <div className='mobile-header-wrap'>
          <div className='panel-expand-control'>
            <Button onClick={toggle} className="bg-transparent border-0 p-0 d-flex justify-content-center align-items-center h-100 w-100 d-sm-none">
              <LuMenu size={22} />
            </Button>
          </div>

          <NavLink to='/' className="link d-flex justify-content-center align-items-center d-md-none ps-1">
            <img src={Logo} alt="Logo" width={screenWidth <= 755 ? "70" : "115"} />
          </NavLink>

        </div>
        <div className={`${isOpen ? 'sidebar  sidebar-active' : 'sidebar'} py-5`} ref={sidebarRef}>

          <a href="#!" className="btn-close" aria-label="Close" onClick={toggle}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" /></svg>
          </a>
          <div className="ps-3 pb-3">
            <NavLink to='/property/list' className="link">
              <img src={Logo} alt="Logo" width="115" height="27" />
            </NavLink>
          </div>
          <ul className='d-flex flex-column justify-content-between justify-content-xxl-start h-100 pt-3'>
            <li className='position-relative'>
              <NavLink to='/property/list?page=0&limit=5' className="d-flex align-items-center gap-3 w-100 p-3 position-relative">
                <Home width='25px' height='25px' />
                <span className=''>Properties</span>
              </NavLink>
            </li>
            <li>
              <NavLink to='/leads' className="d-flex align-items-center gap-3 w-100 p-3 w-100 h-100">
                <Users width='25px' height='25px' />
                <span className='link-text'>Leads</span>
              </NavLink>
            </li>
            <li>
              <NavLink to='/showings' className="d-flex align-items-center gap-3 w-100 p-3">
                <Calendar width='25px' height='25px' />
                <span>Bookings</span>
              </NavLink>
            </li>
            <li>
              <NavLink to='/kits' className="d-flex align-items-center gap-3 w-100 p-3">
                <BiBriefcase name="briefcase" size={25} />
                <span className='link-text'>Kits</span>
              </NavLink>
            </li>
            <li>
              <NavLink to='/account' className="d-flex align-items-center gap-3 w-100 p-3">
                <Settings width='25px' height='25px' />
                <span className='link-text'>Account</span>
              </NavLink>
            </li>
            <li>
              <a href="" onClick={handleLogout} className="d-flex align-items-center gap-3 w-100 p-3">
                <LogOut width='25px' height='25px' />
                <span className='link-text'>Logout</span>
              </a>
            </li>
            <li className='d-flex flex-column gap-2 flex-grow-1 justify-content-end'>
              <button type="button" className="py-4 border-0 d-flex flex-column justify-content-center align-items-center gap-2 text-white px-3 fs-7 w-100 bg-secondary bg-opacity-25 border-bottom border-1 border-white share-calendar opacity-75" onClick={copyToClipboard}>
                <Calendar />
                <span className="fs-6 fw-bold">Share calendar</span>
              </button>
              <div className="d-flex py-3 gap-2 justify-content-center align-items-center flex-wrap" >
                <img
                  src={data?.company?.idImage ? getImageUrl(data.company.idImage) : PlaceHolder}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = User;
                  }}
                  alt='company-logo'
                  className="rounded-circle"
                  style={{ width: "40px", height: "40px", objectFit: "cover" }}
                />
                <p className='d-flex flex-column text-center text-white fs-7 fw-bold lh-1 text-break' style={{ maxWidth: "60%" }}>
                  {data?.company?.name ?? ''}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <main className={`${isOpen ? 'main-left' : ''} main`}>
        {children}
        {showcopyToClipboard ?
          <Modal show={showcopyToClipboard} style={{ zIndex: 9999 }}>
            <Modal.Header>
              <Modal.Title>Copied</Modal.Title>
              <button className="btn-close" aria-label="Close" onClick={() => setShowcopyToClipboard(false)}></button>
            </Modal.Header>
            <Modal.Body>Calendar link copied. You can send this link to leads, so that they can create appointments.</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowcopyToClipboard(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          : ''
        }
      </main>

    </>
  );
};

export default Sidebar;