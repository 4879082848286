import React, { useEffect, useState } from "react";
import Sidebar from "../../components/SideBar";
import styled from "styled-components";
import DetailsTabPanel from "./components/DetailsTabPanel";
import { Box, Tab, Tabs } from "@mui/material";
import SpecificationsAndFeesTabPanel from "./components/SpecificationsAndFeesTabPanel";
import AmenitiesAndFeaturesTabPanel from "./components/AmenitiesAndFeaturesTabPanel";
import SystemsTabPanel from "./components/SystemsTabPanel";
import ContactTabPanel from "./components/ContactTabPanel";
import PropertyFormHeader from "./components/PropertyFormHeader";
import {
  useBlocker,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Button, Col } from "react-bootstrap";

import { useForm } from "react-hook-form";

import { PROPERTY_DEFAULT_VALUES } from "../../static/Data";
import useViewport from "../../hooks/useViewport";
import { convertFileToSrc } from "../../utils/utils";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useGeneral } from "../../context/GeneralContext";
import LoaderRing from "../../components/LoaderRing";
import { useProperty } from "../../context/PropertyContext";
import Alert from "../../components/Alert";

const StyledButton = styled.button`
  background-color: gray !important;
  border-color: gray !important;
  transition: border 0.3s;

  &:hover {
    border-color: white !important;
  }
`;

export const FormTitle = styled.h4({
  color: "#5d5fef",
});
export const Card = styled.div({
  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25);",
  borderRadius: "5px",
  margin: "20px",
  padding: "15px",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});
export const FormSubTitle = styled.span({
  color: "#66676B",
  textTransform: "none",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "140%",
  letterSpacing: "0.14px",
});
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const PropertyFormTab = () => {
  const formMethods = useForm({ defaultValues: PROPERTY_DEFAULT_VALUES });
  const {
    handleSubmit,
    register,
    trigger,
    setValue,
    getValues,
    formState: { errors, isDirty, isValid },
    watch,
    reset,
  } = formMethods;

  const [isEditing, setIsEditing] = useState(false);
  const [creatingProperty, setCreatingProperty] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();
  const isEditPage = location.pathname.includes("edit");
  const { width: screenWidth } = useViewport();
  const { setAlertModal } = useGeneral();
  const { setProperty, property } = useProperty();
  const [isSavingOrCreating, setIsSavingOrCreating] = useState(false);
  const [isLoardingProperty, setIsLoardingProperty] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [navigateTo, setNavigateTo] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  const filterPropertyData = (receivedData) => {
    const filteredData = {};

    for (const key in PROPERTY_DEFAULT_VALUES) {
      if (receivedData.hasOwnProperty(key)) {
        filteredData[key] = receivedData[key];
        if (key === "dateAvailableTs") {
          filteredData[key] = formatTimestamp(receivedData[key]);
        }
      }
    }
    if (receivedData.kit) {
      filteredData.kit = receivedData.kit._id.toString();
    }
    return filteredData;
  };

  const getProperty = async () => {
    setIsLoardingProperty(true);

    try {
      const token = await Auth.currentSession();
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_API_URL}/api/property/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        }
      );
      setProperty(response.data);
      reset(filterPropertyData(response.data));
    } catch (error) {
      console.log(error);
      setAlertModal({
        show: true,
        title: "Error",
        content: "Error Getting property",
      });
    }

    setIsLoardingProperty(false);
  };

  const saveFormData = async () => {
    setIsEditing(false);
    const formData = getValues();
    onSubmit(formData);
  };

  const handleNavigation = ({ nextLocation }) => {
    if (isEditing) {
      setProceed(true);
      setNavigateTo(nextLocation.pathname);
      return true;
    }
    return false;
  };

  useBlocker(handleNavigation);

  function formatTimestamp(timestamp) {
    const date = new Date(Number(timestamp));

    // Extract year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate() + 1).padStart(2, "0");

    // Return formatted date
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (isEditPage) {
      getProperty();
    }
  }, []);

  useEffect(() => {
    setIsEditing(isDirty);
  }, [isDirty]);

  const onSubmit = async (data) => {
    const form = new FormData();
    const dateAvailableTs = watch("dateAvailableTs")
      ? new Date(watch("dateAvailableTs")).getTime()
      : null;

    if (data.images) {
      data.images.forEach((file) => {
        form.append("propertyImage", file);
      });
    }

    form.append("data", JSON.stringify({ ...data, dateAvailableTs }));
    setIsEditing(false);

    const methodForm = isEditPage ? "put" : "post";
    const url = `${process.env.REACT_APP_NODE_API_URL}/api/property${
      isEditPage ? `/${params.id}` : ""
    }`;

    setIsSavingOrCreating(true);
    Auth.currentSession()
      .then((token) => {
        setCreatingProperty(true);
        axios({
          method: methodForm,
          url: url,
          data: form,
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            "Content-Type": "multipart/form-data"
          },
        })
          .then((response) => {
            setAlertModal({
              show: true,
              title: "Success",
              content: isEditPage
                ? "Property saved successfully"
                : "Property created successfully",
            });
            navigate("/property/list");
          })
          .catch((error) => {
            console.error(error);
            setAlertModal({
              show: true,
              title: "Error",
              content: isEditPage
                ? "Error in saving the property"
                : "Error in creating the property",
            });
          })
          .finally(() => {
            setCreatingProperty(false);
            setIsSavingOrCreating(false);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = async (event, newValue) => {
    trigger();
    if (isValid) {
      setTabValue(newValue);
    } else {
      setAlertModal({
        show: true,
        title: "Warning",
        content: "The required fields must be filled out with valid vaule.",
      });
    }
    return;
  };

  const next = async () => {
    trigger();
    if (isValid) {
      setTabValue((prev) => prev + 1);
    } else {
      setAlertModal({
        show: true,
        title: "Warning",
        content: "The required fields must be filled out.",
      });
    }
    return;
  };

  return (
    <>
      <Sidebar>
        <PropertyFormHeader isEditPage={isEditPage} />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="PROPERTY DETAILS" {...a11yProps(0)} />
              <Tab label="SPECIFICATIONS & FEES" {...a11yProps(1)} />
              <Tab label="FEATURES & AMENITIES" {...a11yProps(2)} />
              <Tab label="DELET SYSTEM SETTINGS" {...a11yProps(3)} />
              <Tab label="PROPERTY MANAGEMENT" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <form>
            <DetailsTabPanel
              value={tabValue}
              register={register}
              setValue={setValue}
              errors={errors}
              trigger={trigger}
              watch={watch}
              isEditPage={isEditPage}
              saveFormData={saveFormData}
              index={0}
            />
            <SpecificationsAndFeesTabPanel
              value={tabValue}
              register={register}
              setValue={setValue}
              errors={errors}
              trigger={trigger}
              watch={watch}
              saveFormData={saveFormData}
              index={1}
            />
            <AmenitiesAndFeaturesTabPanel
              value={tabValue}
              register={register}
              setValue={setValue}
              errors={errors}
              trigger={trigger}
              watch={watch}
              saveFormData={saveFormData}
              index={2}
            />
            <SystemsTabPanel
              value={tabValue}
              register={register}
              setValue={setValue}
              errors={errors}
              trigger={trigger}
              watch={watch}
              saveFormData={saveFormData}
              index={3}
            />
            <ContactTabPanel
              value={tabValue}
              register={register}
              setValue={setValue}
              errors={errors}
              trigger={trigger}
              watch={watch}
              saveFormData={saveFormData}
              index={4}
            />
          </form>
        </Box>

        {screenWidth > 755 ? (
          <div className="px-5 d-flex justify-content-between position-sticky bottom-0 bg-white py-3">
            <Col xs="auto">
              <Button
                className={`px-5 tab-footer-button`}
                onClick={() => setTabValue((prev) => prev - 1)}
                disabled={tabValue === 0}
              >
                {"< Back"}
              </Button>
            </Col>
            <Col xs="auto" className="gap-2 d-flex">
              {tabValue === 4 ? (
                <></>
              ) : (
                <Button
                  className="px-4"
                  as={StyledButton}
                  onClick={saveFormData}
                >
                  Save and Close
                </Button>
              )}

              <Button
                className={`px-5 tab-footer-button`}
                onClick={tabValue < 4 ? next : handleSubmit(onSubmit)}
                disabled={creatingProperty}
              >
                {tabValue === 4 ? "Finish" : "Continue >"}
              </Button>
            </Col>
          </div>
        ) : (
          <div className="d-flex flex-column px-3">
            <Col xs={12}>
              <Button
                className="btn tab-footer-button d-flex col justify-content-center"
                onClick={tabValue < 4 ? next : handleSubmit(onSubmit)}
                disabled={creatingProperty}
                style={{ width: "100%" }}
              >
                {tabValue === 4 ? "Finish" : "Continue >"}
              </Button>
            </Col>
            {tabValue === 4 ? (
              <></>
            ) : (
              <Col xs={12}>
                <Button
                  className="px-4 mt-2"
                  as={StyledButton}
                  onClick={saveFormData}
                  style={{ width: "100%" }}
                >
                  Save and Close
                </Button>
              </Col>
            )}

            <Col xs={12}>
              <Button
                className="btn tab-footer-button d-flex col mt-2 justify-content-center"
                onClick={() => setTabValue((prev) => prev - 1)}
                disabled={tabValue === 0}
                style={{ width: "100%" }}
              >
                {"< Back"}
              </Button>
            </Col>
          </div>
        )}
      </Sidebar>
      {(isSavingOrCreating || isLoardingProperty) && (
        <div className="modal-outside">
          <LoaderRing height="100" width="100" />
        </div>
      )}
      {proceed && (
        <Alert
          show={proceed}
          setShow={() => {
            saveFormData();
            setProceed(false);
          }}
          navigateTo={navigateTo}
          title={"You have unsaved changes. Are you sure you want to exit?"}
          content={"All unsaved changes will be lost."}
        />
      )}
    </>
  );
};

export default PropertyFormTab;
