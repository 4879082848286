import { Auth } from "aws-amplify";
import axios from "axios";

export const getNotificationsList = async (page, filters) => {
  const url = new URL(`${process.env.REACT_APP_NODE_API_URL}/api/notifications`);
  url.searchParams.set('page', page);
  url.searchParams.set('limit', 10);

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      url.searchParams.set(key, value);
    }
  });

  try {
    const token = await Auth.currentSession()

    const res = await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })

    return res.data

  } catch (error) {
    console.log("ERROR GETTING USERS: ", error)
  }
}

export const getUnreadCount = async () => {
  const url = new URL(`${process.env.REACT_APP_NODE_API_URL}/api/notifications/unread-count`);

  try {
    const token = await Auth.currentSession()

    const res = await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })
    return res.data

  } catch (error) {
    console.log("ERROR GETTING USERS: ", error)
  }
}

export const patchReadNotification = async (notificationId) => {
  const url = new URL(`${process.env.REACT_APP_NODE_API_URL}/api/notifications/read/${notificationId}`);

  try {
    const token = await Auth.currentSession()

    const res = await axios
      .patch(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })
    if (res.status === 200) return res.data
    throw new Error(res.data)

  } catch (error) {
    console.log("ERROR GETTING USERS: ", error)
  }
}

export const getLockNotifications = async (page, lockId) => {
  const url = new URL(`${process.env.REACT_APP_NODE_API_URL}/api/notifications/lock/${lockId}`);
  url.searchParams.set('page', page);
  url.searchParams.set('limit', 10);

  try {
    const token = await Auth.currentSession()
    const res = await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })
    return res.data

  } catch (error) {
    console.log("ERROR GETTING USERS: ", error)
  }
}