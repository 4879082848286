import React, { useEffect } from "react";
import styled from "styled-components";

const StyledInputText = styled.input`
  color: #000000;
  
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.16px;
  border: 1px solid #bdbebf;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  ${(props) =>
    props.textAtBeginning &&
    ` padding-left: 25px;
    `}
  box-sizing: border-box;
  &:focus {
    border: 1px solid #5d5fef;
    box-shadow: 0px 0px 4px rgba(93, 95, 239, 0.25);
  }
  /* disabled styles */
  &:disabled {
    background-color: #eaeaeb;
    border: 1px solid #eaeaeb;
  }
  /* disabled placeholder */
  &:disabled::placeholder {
    color: #bdbebf;
  }
`;

const StyledLabel = styled.label`
  color: #5f5f5f;
  
  font-size: 16px;
  font-weight: ${(props) =>
    props.labelType === "primary"
      ? "700"
      : props.labelType === "secondary"
      ? "400"
      : "700"};
  line-height: 140%;
  letter-spacing: 0.16px;

  ${(props) =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const TextAtBeginning = styled.span`
  position: absolute;
  left: 10px;
  top: 50px;
  transform: translateY(-50%);
  color: #66676b;
  
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.28px;
  text-transform: uppercase;

  input:disabled + & {
    color: #bdbfbf;
  }
`;

const TextAtEnd = styled.span`
  position: absolute;
  right: 10px;
  top: 50px;
  transform: translateY(-50%);
  color: #66676b;
  
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.28px;
  text-transform: uppercase;

  input:disabled + & {
    color: #bdbfbf;
  }
`;

const InputPhoneNumberComponent = ({
  register,
  setValue,
  watch,
  trigger,
  required = false,
  requiredField,
  name,
  textAtBeginning,
  textAtEnd,
  disabled = false,
  registerFields,
  error = {},
  ...props
}) => {
  const nameArray = name.split(".");

  const handleTrigger = (event) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedValue = formatPhoneNumber(value);
    setValue(name, formattedValue, { shouldValidate: true, shouldDirty: true });
    trigger(name);
  };

  useEffect(() => {
    if (watch(name) && watch(name).includes("+")) {
      const formattedValue = formatPhoneNumber(watch(name).slice(1));
      setValue(name, formattedValue);
    }
  }, [name, setValue, watch]);

  const formatPhoneNumber = (value) => {
    if (value.length === 0) return ""; // Return empty string if no input
    if (value.length < 2) return value; // Just return the first digit
    if (value.length < 5) return `${value[0]}-${value.slice(1)}`; // x-xxx
    if (value.length < 8)
      return `${value[0]}-${value.slice(1, 4)}-${value.slice(4)}`; // x-xxx-xxx
    return `${value[0]}-${value.slice(1, 4)}-${value.slice(4, 7)}-${value.slice(
      7,
      11
    )}`; // x-xxx-xxx-xxxx
  };

  return (
    <InputContainer className="w-100">
      <StyledLabel
        labelType={props?.labelType}
        className="pb-1"
        htmlFor={props?.name}
        disabled={disabled}
      >
        {props?.label}
      </StyledLabel>
      <StyledInputText
        className="form-control"
        type="text"
        textAtBeginning={textAtBeginning}
        {...(required
          ? requiredField
            ? register(name, requiredField)
            : register(name, {
                pattern: {
                  value: /^1-\d{3}-\d{3}-\d{4}$/,
                  message: "Invalid phone number format (1-xxx-xxx-xxxx)",
                },
              })
          : register(name))}
        onChange={handleTrigger}
        onBlur={() => trigger(name)}
        {...props}
        disabled={disabled}
      />
      {textAtBeginning && <TextAtBeginning>{textAtBeginning}</TextAtBeginning>}
      {textAtEnd && <TextAtEnd>{textAtEnd}</TextAtEnd>}
      {nameArray.length === 1
        ? error[name] &&
          error[name].message && (
            <span className="error">{error[name].message}</span>
          )
        : error[nameArray[0]] &&
          error[nameArray[0]][nameArray[1]] &&
          error[nameArray[0]][nameArray[1]].message && (
            <span className="error">
              {error[nameArray[0]][nameArray[1]].message}
            </span>
          )}
    </InputContainer>
  );
};

export default InputPhoneNumberComponent;
