import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import React, { useContext } from "react";
import {
  getLockNotifications,
  getNotificationsList,
} from "../../api/notifications";
import { SocketContext } from "../../context/SocketContext";
import Loading from "../Banners/Loading";
import { format } from "date-fns-tz";
import { augustId, deviceActions } from "../../constants";
import NotificationTitle from "../../pages/Notifications/components/NotificationTitle";
import LockNotificationTitle from "./LockNotificationTitle";
import { useInView } from "react-intersection-observer";

const LockHistory = ({ lockId }) => {
  const socket = useContext(SocketContext);
  const queryClient = useQueryClient();

  const queryKey = ["lock-history"];
  const {
    data: notifications,
    isLoading,
    isFetchingNextPage,
    isError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: queryKey,
    queryFn: ({ pageParam = 1 }) => getLockNotifications(pageParam, lockId),
    getNextPageParam: (lastPage, pages) =>
      lastPage.hasMore ? pages.length + 1 : undefined,
  });

  const observer = useInView({
    onChange: (inView) => {
      if (inView && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <span>Error getting notifications</span>
  ) : (
    <ul className="d-flex flex-column list-unstyled ps-3">
      <li className="row border-bottom py-3 justify-content-lg-between justify-content-xxl-start gap-xxl-5">
        <h4 className="col-lg-2 table-h">Date</h4>
        <h4 className="col-lg-2 table-h">Action</h4>
        <h4 className="col-lg-4 col-xxl-3 table-h">Message</h4>
        <h4 className="col-2 table-h">User</h4>
      </li>
      {notifications.pages.map((notificationsPage, page) =>
        notificationsPage.data.map((notification, index) => (
          <li
            className="row border-bottom py-3 justify-content-lg-between justify-content-xxl-start gap-xxl-5"
            ref={
              page === notifications.pages.length - 1 &&
              index === notificationsPage.data.length - 1
                ? observer.ref
                : undefined
            }
          >
            <span className="col-lg-2 d-flex align-items-center text-nowrap">
              {format(notification.createdAt * 1000, "MMMM d,", {
                timeZone: "America/Los_Angeles",
              })}
              <br />
              {format(notification.createdAt * 1000, "yyyy 'at' h:mm a zzz", {
                timeZone: "America/Los_Angeles",
              })}
            </span>

            <div className="col-lg-2">
              <span
                className={`rounded-5 d-flex justify-content-center align-items-center text-capitalize h-content py-2 px-4 fw-bold action-${
                  deviceActions[notification.type].action
                } d-block`}
              >
                {deviceActions[notification.type].action.toLowerCase()}ed
              </span>
            </div>

            <span className="col-lg-4 col-xxl-3 d-flex align-items-center">
              The door was{" "}
              {deviceActions[notification.type].action.toLowerCase()}ed at{" "}
              {notification.property.shortAddress}
            </span>

            <span className="col-2 d-flex align-items-center">
              {augustId[notification.metadata.user.firstName] ??
                notification.metadata.user.firstName}
            </span>
          </li>
        ))
      )}
    </ul>
  );
};

export default LockHistory;
