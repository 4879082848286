import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

const CustomToggle = styled(Dropdown.Toggle)`
  &::after {
    display: none;
  }
`;


const CustomDropdown = ({ icon, menuItems, onMenuItemClick, ...props }) => {

  return (
    <Dropdown>
      <CustomToggle
        variant="link"
        id="dropdown-basic"
        {...props}
      >
        {icon}
      </CustomToggle>

      <Dropdown.Menu>
        {menuItems.map((item, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => onMenuItemClick(item.label)}
            className='z-2'
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;