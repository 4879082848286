import React, { useEffect, useRef, useState } from "react";
import { FiltersModal } from "./FiltersModal";
import { Button } from "react-bootstrap";
import { BsSliders2 } from "react-icons/bs";

const OpenFilterButton = ({
  handleApplyFilters,
  text = "",
  byProperty,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <Button onClick={() => setShow(!show)} ref={buttonRef} {...props}>
      <BsSliders2 size="14px" /> <span className="d-none d-lg-inline small-text">{text}</span>
      <FiltersModal
        show={show}
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        onClick={(e) => e.stopPropagation()}
        byProperty={byProperty}
      />
    </Button>
  );
};

export default OpenFilterButton;
