import "./Leads.scss";

import React, { useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { Auth } from "aws-amplify";
import Sidebar from "../../components/SideBar";
import PagePagination from "../../components/PagePagination";
import _ from "lodash";
import { useGeneral } from "../../context/GeneralContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getContacts } from "../../api/contacts";
import { updateBookingStatus } from "../../api/bookings";
import LeadsFilter from "./components/LeadsFilter";
import LeadsTable from "./components/LeadsTable";
import PageHeader from "../../components/Headers/PageHeader";
import NotificationsButton from "../../components/Notifications/NotificationsButton";

const Leads = () => {
  const [showAlert, setShowAlert] = useState(false);
  const { setAlertModal } = useGeneral();

  const [pageLimit, setPageLimit] = useState(7);
  const [searchParam, setSearchParam] = useState("");
  const [page, setPage] = useState(1);
  const [listOrder, setListOrder] = useState("desc");
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    selectedProperties: null,
    search: "",
  });
  const memoizedFilters = useMemo(() => filters, [filters]);

  const {
    data = { data: [] },
    isLoading,
    isError,
  } = useQuery({
    queryKey: [
      "my-leads",
      page,
      pageLimit,
      memoizedFilters.startDate,
      memoizedFilters.endDate,
      memoizedFilters.selectedProperties,
      memoizedFilters.search,
    ],
    queryFn: () =>
      getContacts({
        page,
        limit: pageLimit,
        order: listOrder,
        ...memoizedFilters,
      }),
  });

  const handleApplyFilters = ({ selectedProperties, startDate, endDate }) => {
    const newFilters = {};
    if (startDate)
      newFilters.startDate = moment(startDate).format("YYYY-MM-DD");
    if (endDate) newFilters.endDate = moment(endDate).format("YYYY-MM-DD");
    if (selectedProperties)
      newFilters.selectedProperties = selectedProperties
        ?.map((property) => property._id)
        .join();

    setFilters(newFilters);
    setPage(1);
  };

  const handleResetFilters = () => {
    setFilters({
      startDate: null,
      endDate: null,
      selectedProperties: null,
    });
  };

  const [exportProperties, setExportProperties] = useState([]);
  const [bookingsUpdating, setBookingsUpdating] = useState({});

  const handleExport = (e, exportStartDate, exportEndDate) => {
    e.preventDefault();
    Auth.currentSession().then((token) => {
      let url = `${process.env.REACT_APP_NODE_API_URL}/api/contact/toCsv?`;

      if (exportProperties.length) {
        url = `${url}&propertyIds=${exportProperties
          .map((property) => property._id)
          .join()}`;
      }

      if (exportStartDate) {
        url = `${url}&startDate=${moment(exportStartDate).format(
          "YYYY-MM-DD"
        )}`;
      }
      if (exportEndDate) {
        url = `${url}&endDate=${moment(exportEndDate).format("YYYY-MM-DD")}`;
      }

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            var blob = new Blob([response.data], {
              type: "text/csv",
            });

            const a = document.createElement("a");
            a.download = "leads";
            a.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();
          }
          setExportProperties([]);
        })
        .catch((error) => {
          console.log(error);
          setAlertModal({
            show: true,
            title: "Error",
            content: "There's no data to export",
          });
        });
    });
  };

  const handleSearchTerm = (e, search) => {
    if (e.target.value === "") setFilters({ ...filters, search: "" });
    setSearchParam(e.target.value);
    if (search && e.target.value !== "") setFilters({ ...filters, search: searchParam });
  };

  const handleReset = () => {
    setPage(1);
    setSearchParam("");
    setFilters({
      ...filters,
      search: "",
    });
  };

  const handleNextPage = (nextPage) => {
    if (data.pages >= nextPage && nextPage > 0) {
      setPage(nextPage);
    }
  };

  const queryClient = useQueryClient();

  const { mutateAsync: updateStatus } = useMutation({
    mutationFn: async ({ bookingId, newStatus }) => {
      setBookingsUpdating((prev) => ({ ...prev, [bookingId]: true }));
      await updateBookingStatus(bookingId, newStatus);
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        [
          "my-leads",
          page,
          pageLimit,
          memoizedFilters.startDate,
          memoizedFilters.endDate,
          memoizedFilters.selectedProperties,
          memoizedFilters.search,
        ],
        (oldData) => {
          const updatedData = {
            ...oldData,
            data: oldData.data.map((booking) =>
              booking.id === variables.bookingId
                ? { ...booking, status: variables.newStatus }
                : booking
            ),
          };
          return updatedData;
        }
      );
    },
    onSettled: (_, error, variables) => {
      setBookingsUpdating((prev) => ({
        ...prev,
        [variables.bookingId]: false,
      }));
    },
  });

  const handleKeyDown = (e) => {
    const { key } = e;
    if (key === "Enter") {
      e.preventDefault();
      setFilters({ ...filters, search: searchParam });
      setPage(1);
    }
  };

  return (
    <Sidebar>
      {
        // MARK: Header
      }

      <PageHeader>
        <div className="d-flex justify-content-between">
          <h1 className="fs-4">Leads</h1>
          <NotificationsButton />
        </div>
        <div className="d-lg-none pt-3">
          <LeadsFilter
            handleKeyDown={handleKeyDown}
            searchParam={searchParam}
            handleSearchTerm={handleSearchTerm}
            handleReset={handleReset}
            handleResetFilters={handleResetFilters}
            handleExport={handleExport}
            handleApplyFilters={handleApplyFilters}
          />
        </div>
      </PageHeader>

      <div className="content-div py-3 px-4 px-lg-5">
        <div className="total-contacts pt-3 d-none d-lg-block">
          {isLoading ? (
            <div>Getting contacts...</div>
          ) : data?.total > 0 ? (
            <p>Total Contacts: {data.total}</p>
          ) : (
            <p>No Records Found</p>
          )}
        </div>
        {
          // MARK: Search and Filters
        }

        <div className="d-none d-lg-block">
          <LeadsFilter
            handleKeyDown={handleKeyDown}
            searchParam={searchParam}
            handleSearchTerm={handleSearchTerm}
            handleReset={handleReset}
            handleResetFilters={handleResetFilters}
            handleExport={handleExport}
            handleApplyFilters={handleApplyFilters}
          />
        </div>

        {
          // MARK: Lead Table
        }

        <LeadsTable
          isLoading={isLoading}
          leads={data?.data}
          bookingsUpdating={bookingsUpdating}
          updateStatus={updateStatus}
        />

        {data?.pages > 1 ? (
          <div className="py-3">
            <PagePagination
              totalPages={data.pages}
              actualPage={page}
              handlePageChange={handleNextPage}
            />
          </div>
        ) : null}

        {showAlert ? (
          <Modal show={showAlert}>
            <Modal.Header>
              <Modal.Title>Alert</Modal.Title>
              <button
                className="btn-close"
                aria-label="Close"
                onClick={() => setShowAlert(false)}
              ></button>
            </Modal.Header>
            <Modal.Body>Something Went Wrong</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowAlert(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ""
        )}
      </div>
    </Sidebar>
  );
};

export default Leads;
