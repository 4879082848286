import React, { useRef } from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`;

const StyledInputText = styled.input`
  color: #000000;
  
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.16px;
  border: 1px solid #bdbebf;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;

  &:focus {
    border: 1px solid #5d5fef;
    box-shadow: 0px 0px 4px rgba(93, 95, 239, 0.25);
  }

  &:disabled {
    background-color: #eaeaeb;
    border: 1px solid #eaeaeb;
    cursor: not-allowed;
  }

  &:disabled::placeholder {
    color: #bdbebf;
  }
`;

const StyledLabel = styled.label`
  color: #000;
  
  font-size: 16px;
  font-weight: ${(props) =>
    props.labelType === "primary"
      ? "700"
      : props.labelType === "secondary"
      ? "400"
      : "700"};
  line-height: 140%;
  letter-spacing: 0.16px;

  ${(props) =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const InputDateComponent = ({
  name,
  register,
  disabled = false,
  registerFields,
  error = {},
  errorMessage = "This field is required",
  ...props
}) => {
  const inputRef = useRef(null);

  const registerWithRef = {
    ...register(name, registerFields),
    ref: (element) => {
      inputRef.current = element;
      const { ref } = register(name, registerFields);
      if (typeof ref === "function") {
        ref(element);
      }
    },
  };

  const handleClick = () => {
    if (disabled || !inputRef.current) return;

    try {
      if (
        inputRef.current.showPicker &&
        typeof inputRef.current.showPicker === "function"
      ) {
        inputRef.current.showPicker();
      } else {
        inputRef.current.click();
      }
    } catch (error) {
      if (inputRef.current) {
        inputRef.current.click();
      }
    }
  };
  return (
    <InputContainer className="w-100">
      <StyledLabel
        labelType={props?.labelType}
        className="pb-1"
        htmlFor={props?.name}
        disabled={disabled}
      >
        {props?.label}
      </StyledLabel>
      <InputWrapper onClick={handleClick}>
        <StyledInputText
          className="form-control"
          type="date"
          {...registerWithRef}
          {...props}
          disabled={disabled}
        />
      </InputWrapper>
      {error[name] && <span className="error">{errorMessage}</span>}
    </InputContainer>
  );
};

export default InputDateComponent;
