import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import axios from "axios";
import { Center, Loader } from "@mantine/core";
import { IoIosInformationCircle } from "react-icons/io";
import { AppButton } from "../../../../designSystem/AppButton";
import useViewport from "../../../../hooks/useViewport";
import { useGeneral } from "../../../../context/GeneralContext";
import InputTextComponent from "../../../../components/InputTextComponent";
import InputPhoneNumberComponent from "../../../../components/InputPhoneNumberComponent";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";
import { USER_SETTING_EDIT_AGENT_PROFILE } from "../../../../static/Data";
import ChangePassword from "./ChangePassword";
import UseHttp from "../../../../hooks/UseHttp";
import { useProfile } from "../../../../context/UserProfileContext";
import { emailPattern } from "../../utils";

// Styled components
const StyledInputText = styled.input`
  color: #000000;
  
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.16px;
  border: 1px solid #bdbebf;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  ${(props) => props.textAtBeginning && `padding-left: 25px;`}
  box-sizing: border-box;
  &:focus {
    border: 1px solid #5d5fef;
    box-shadow: 0px 0px 4px rgba(93, 95, 239, 0.25);
  }
  &:disabled {
    background-color: #eaeaeb;
    border: 1px solid #eaeaeb;
  }
  &:disabled::placeholder {
    color: #bdbfbf;
  }
`;

const StyledLabel = styled.label`
  color: ${(props) => (props.disabled ? "#bdbfbf" : "#5f5f5f")};
  
  font-size: 16px;
  font-weight: ${(props) =>
    props.labelType === "primary"
      ? "700"
      : props.labelType === "secondary"
      ? "400"
      : "700"};
  line-height: 140%;
  letter-spacing: 0.16px;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

// Utility function to filter user profile data
const filterUserProfileData = (receivedData, defaultValues) => {
  const filteredData = {};
  for (const key in defaultValues) {
    if (receivedData.hasOwnProperty(key)) {
      filteredData[key] = receivedData[key];
    }
  }
  return filteredData;
};

// Utility function to format phone numbers
const formatPhoneNumber = (phoneNumber) => {
  return `+${phoneNumber.replace(/-/g, "")}`;
};

const UserDetail = forwardRef((props, ref) => {
  const { agentId, setPersonalUserId } = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isDirtyPassword, setIsDirtyPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const { width: screenWidth } = useViewport();
  const { setAlertModal } = useGeneral();
  const { error, requestData: changePassword } = UseHttp();
  const { data = {} } = useProfile();
  const [currentUser, setCurrentUser] = useState(null);
  const formMethods = useForm({
    defaultValues: USER_SETTING_EDIT_AGENT_PROFILE,
  });
  const {
    handleSubmit,
    register,
    trigger,
    setValue,
    getValues,
    formState: { errors, isValid },
    watch,
    reset,
  } = formMethods;

  const showAlert = (title, content) => {
    setAlertModal({ show: true, title, content });
  };

  const fetchUserDetails = async (id) => {
    try {
      const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/admin/${id}`;
      const token = await Auth.currentSession();

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      });

      if (response.data) {
        reset(
          filterUserProfileData(response.data, USER_SETTING_EDIT_AGENT_PROFILE)
        );
        setCurrentUser(response.data);
        setIsLoading(true);
      } else {
        showAlert("Error", "Error getting user details.");
      }
    } catch (error) {
      handleFetchError(error);
    }
  };

  const handleFetchError = (error) => {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 401) {
        Auth.signOut()
          .then(() => navigate("/login"))
          .catch((signOutError) =>
            console.error("Error signing out:", signOutError)
          );
      } else if (status === 404) {
        showAlert("Error", data?.message || "User not found.");
      } else {
        showAlert("Error", "Unexpected error occurred.");
      }
    } else {
      showAlert("Error", error.message || "Unknown error.");
    }
  };

  useEffect(() => {
    if (agentId) {
      fetchUserDetails(agentId);
    } else {
      navigate("/");
    }
  }, [agentId]);

  useImperativeHandle(ref, () => ({
    async saveChagnes() {
      return await onSubmit(getValues());
    },
  }));

  const onSubmit = async (data) => {
    trigger();

    if (!isValid) {
      showAlert("Error", "Please input the fields correctly.");
      return false;
    }
    const phoneNumber = watch("phone");
    const formattedData = { ...data, phone: formatPhoneNumber(phoneNumber) };

    try {
      setIsLoading(false);
      const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/agent/${agentId}`;
      const token = await Auth.currentSession();

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
        data: formattedData,
      };

      const response = await axios.request(config);

      if (response.status === 200 || response.status === 201) {
        showAlert("Success", "User Updated Successfully");
        setIsLoading(true);
      }
    } catch (error) {
      setIsLoading(true);
      if (error?.response?.status === 401) {
        Auth.signOut()
          .then(() => {
            navigate("/admin/login");
          })
          .catch((error) => {
            console.log("Error signing out: ", error);
          });
      } else {
        console.log(error);
        showAlert("Error", "Error on edit user");
      }
    }

    return true;
  };

  const handleDeleteUser = async (e) => {
    setIsDelete(false);
    try {
      e.preventDefault();

      const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/admin/${agentId}`;
      const token = await Auth.currentSession();
      setIsLoading(false);
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      });

      if (response.status === 200) {
        setIsLoading(true);
        showAlert("Success", "User Deleted Successfully");
        setPersonalUserId(false);
      } else {
        setIsLoading(true);
        showAlert("Error", "Error Deleting User");
      }
    } catch (error) {
      setIsLoading(true);
      console.log(error);
      if (error?.response?.status === 401) {
        Auth.signOut()
          .then(() => {
            navigate("/admin/login");
          })
          .catch((error) => {
            console.log("Error signing out: ", error);
          });
      } else {
        showAlert("Error", "Error Deleting User");
      }
    }
  };

  const handleChangePassword = () => {
    if (!isValidPassword) {
      showAlert("Error", "Please input the password correctly and confirm it.");
      return;
    }

    setIsLoading(false);
    Auth.currentSession()
      .then((token) => {
        return changePassword({
          method: "POST",
          url: `${process.env.REACT_APP_NODE_API_URL}/api/users/change-password/${agentId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
          body: {
            password: newPassword,
          },
        });
      })
      .then((response) => {
        setIsLoading(true);
        setAlertModal({
          show: true,
          title: "Success",
          content: "Your password was changed",
        });
        return 1;
      })
      .catch((error) => {
        console.error("Error changing password:", error);
        showAlert(
          "Error",
          "Failed to change password. Please try again later."
        );
        setIsLoading(true);
      });

    setIsChangePassword(false);
  };

  return (
    <>
      {!isLoading ? (
        <Center h={100}>
          <Loader />
        </Center>
      ) : (
        <div className="d-flex flex-column gap-3">
          <div className="d-flex col-12 gap-3 justify-content-end">
            {currentUser.email !== data.email && (
              <>
                <Link
                  className="btn btn-changepassword"
                  onClick={() => setIsChangePassword(true)}
                >
                  Change Password
                </Link>
                <Link
                  onClick={() => setIsDelete(true)}
                  className="btn btn-primary"
                >
                  Delete User
                </Link>
              </>
            )}
          </div>
          <InputContainer className="w-100">
            <StyledLabel className="pb-1" disabled>
              Company Name
            </StyledLabel>
            <StyledInputText
              className="form-control"
              type="text"
              disabled
              placeholder={data?.company?.name ?? ""}
            />
          </InputContainer>
          <InputTextComponent
            required
            label="First Name"
            name="firstName"
            register={register}
            trigger={trigger}
            setValue={setValue}
            error={errors}
            placeholder="Enter first name"
          />
          <InputTextComponent
            required
            label="Last Name"
            name="lastName"
            register={register}
            trigger={trigger}
            setValue={setValue}
            error={errors}
            placeholder="Enter last name"
          />
          <InputPhoneNumberComponent
            required
            label="Phone Number"
            name="phone"
            register={register}
            trigger={trigger}
            watch={watch}
            setValue={setValue}
            error={errors}
            placeholder="1-xxx-xxx-xxxx"
          />
          <InputTextComponent
            required
            register={register}
            trigger={trigger}
            setValue={setValue}
            label="Email"
            name="email"
            error={errors}
            placeholder="example@email.com"
            requiredField={{
              required: "This field is required",
              pattern: emailPattern,
            }}
          />
          <AutocompleteSelect
            required
            register={register}
            trigger={trigger}
            setValue={setValue}
            label="Role"
            name="role"
            placeholder="Select"
            options={[
              { id: "agent", description: "Agent - Agent cannot invite new users" },
              {
                id: "admin",
                description: "Admin - Admin can invite new users",
              },
            ]}
            error={errors}
          />
        </div>
      )}
      {isDelete && (
        <div className={`modal-outside ${screenWidth > 755 ? "" : "px-3"}`}>
          <div className="modal-inside">
            <IoIosInformationCircle size={50} color="#5271ff" />
            <p className="modal-title">You're About To Remove This User</p>
            <p className="expired-content">
              <p>Are you sure you want to proceed?</p>{" "}
              <p>Please note that this action cannot be undone.</p>
            </p>
            <div className="d-flex gap-3 w-100">
              <AppButton
                className="bg-white border text-dark remove-btn"
                onClick={() => setIsDelete(false)}
              >
                Cancel
              </AppButton>
              <AppButton className="remove-btn" onClick={handleDeleteUser}>
                Remove
              </AppButton>
            </div>
          </div>
        </div>
      )}
      {isChangePassword && (
        <div className={`modal-outside ${screenWidth > 755 ? "" : "px-3"}`}>
          <div className="modal-changepassword-inside">
            <div className="d-flex flex-column gap-3 w-100">
              <p
                style={{
                  textTransform: "uppercase",
                  fontWeight: "700",
                  color: "#959595",
                }}
              >
                chagne password
              </p>
              <ChangePassword
                setIsDirtyPassword={setIsDirtyPassword}
                setIsValidPassword={setIsValidPassword}
                setNewPassword={setNewPassword}
              />
              <div>
                <AppButton
                  className="remove-btn"
                  onClick={() => {
                    handleChangePassword();
                  }}
                >
                  Change Password
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default UserDetail;
