import { useCallback, useEffect, useRef, useState } from "react";
import "./Showings.scss";
import "react-datepicker/dist/react-datepicker.css";

import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Pagination,
  Row,
} from "react-bootstrap";

import dayGridPlugin from "@fullcalendar/daygrid/index.js";
import interactionPlugin from "@fullcalendar/interaction/index.js";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid/index.js";

import Sidebar from "../../components/SideBar";
import { Center, Loader } from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createMeeting, getBookings, postBooking } from "../../api/bookings";
import { toZonedTime } from "date-fns-tz";
import BookingInfo from "./components/BookingInfo";
import NewBookingForm from "./components/NewBookingForm";
import useViewport from "../../hooks/useViewport";
import PageHeader from "../../components/Headers/PageHeader";
import NotificationsButton from "../../components/Notifications/NotificationsButton";

const borderColors = [
  "#00ba08",
  "#4ee0a8",
  "#11e5f1",
  "#5271ff",
  "#9747ff",
  "#b93580",
  "#ff3094",
  "#ff0004",
  "#ff9900",
  "#fee400",
];

const Showings = () => {
  const queryClient = useQueryClient();
  const { width: screenwidth } = useViewport();
  const [search, setSearch] = useState("");
  const [selectedBooking, setSelectedBooking] = useState({});
  const [showBookingInfo, setShowBookingInfo] = useState(false);
  const [showNewBooking, setShowNewBooking] = useState(false);
  const [monthOffset, setMonthOffset] = useState(0);
  const [offsetDirection, setOffsetDirection] = useState("");
  const [currentViewType, setCurrentViewType] = useState("dayGridMonth");

  const [initialDate, setInitialDate] = useState(() => {
    const currentDate = new Date();
    return toZonedTime(currentDate, "America/Los_Angeles");
  });
  const [currentMonth, setCurrentMonth] = useState("");

  const calculateCurrentMonth = useCallback(() => {
    const today = new Date().toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });
    const currentDay = new Date(today);
    const calulatedDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth() + monthOffset
    );
    const options = { month: "long", year: "numeric" };
    const formattedMonth = calulatedDay.toLocaleDateString("en-US", options);
    return formattedMonth;
  }, [monthOffset]);

  const calendarRef = useRef(null);

  function getLastDateOfMonth(year, month) {
    // Month is 0-indexed (0 = January, 11 = December)
    return new Date(year, month + 1, 0).getDate();
  }

  useEffect(() => {
    if (offsetDirection === "prev")
      setInitialDate(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() + monthOffset,
          getLastDateOfMonth(
            new Date().getFullYear(),
            new Date().getMonth() + monthOffset
          )
        )
      );
    else if (offsetDirection === "next")
      setInitialDate(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() + monthOffset,
          1
        )
      );
    else
      setInitialDate(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() + monthOffset,
          new Date().getDate()
        )
      );
    setCurrentMonth(calculateCurrentMonth());
  }, [monthOffset, calculateCurrentMonth, offsetDirection]);

  const handleClickViewTypeButton = (viewType) => {
    let calendarApi = calendarRef.current.getApi();
    switch (viewType) {
      case "day":
        calendarApi.changeView("timeGridDay");
        setCurrentViewType("timeGridDay");
        break;
      case "week":
        calendarApi.changeView("timeGridWeek");
        setCurrentViewType("timeGridWeek");
        break;
      case "month":
        calendarApi.changeView("dayGridMonth");
        setCurrentViewType("dayGridMonth");
        break;
      case "today":
        setMonthOffset(0);
        setOffsetDirection("");
        calendarApi.changeView("timeGridDay");
        setCurrentViewType("timeGridDay");
        calendarApi.today();
        break;
      default:
        break;
    }
  };

  function goNext() {
    let calendarApi = calendarRef.current.getApi();
    if (calendarApi.currentData.currentViewType === "dayGridMonth") {
      setMonthOffset((prev) => prev + 1);
      setOffsetDirection("next");
    } else {
      calendarApi.next();
      const nextMonth =
        calendarApi.currentData.dateProfile.activeRange.end.getMonth();
      const currentMonth = initialDate.getMonth();
      if (nextMonth !== currentMonth) {
        setMonthOffset((prev) => prev + 1);
        setOffsetDirection("next");
      }
    }
  }

  function goPrev() {
    let calendarApi = calendarRef.current.getApi();
    if (calendarApi.currentData.currentViewType === "dayGridMonth") {
      setMonthOffset((prev) => prev - 1);
      setOffsetDirection("prev");
    } else {
      calendarApi.prev();
      const nextMonth =
        calendarApi.currentData.dateProfile.activeRange.end.getMonth();
      const currentMonth = initialDate.getMonth();
      if (nextMonth !== currentMonth) {
        setMonthOffset((prev) => prev - 1);
        setOffsetDirection("prev");
      }
    }
  }

  const {
    data: bookings,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["bookings", monthOffset],
    queryFn: async () => await getBookings(monthOffset),
  });

  const {
    mutateAsync: createBooking,
    isPending: creatingBook,
    error: errorCreatingBooking,
  } = useMutation({
    mutationFn: async (booking) => await postBooking(booking),
    onSuccess: (data) => {
      queryClient.setQueryData(["bookings"], (prev) => [...prev, data]);
      setShowNewBooking(false);
    },
  });

  const getClassNameFromId = (id) => {
    const lastTwoDigits = parseInt(id.slice(-1), 16);
    const tenDecimal = lastTwoDigits;
    const remainder = tenDecimal % 10;
    return `fc-event-bg-${remainder}`;
  };

  const getBorderColorFromId = (id) => {
    const lastTwoDigits = parseInt(id.slice(-1), 16);
    const tenDecimal = lastTwoDigits;
    const remainder = tenDecimal % 10;
    return borderColors[remainder];
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        {eventInfo.view.type === "timeGridDay" ? (
          <div
            className={eventInfo.event.classNames && eventInfo.event.classNames}
            style={{
              borderLeft: `3px solid ${eventInfo.borderColor}`,
              borderRadius: "3px",
              boxShadow: "0 0 0 1px white",
              lineHeight: "normal",
              padding: "6px",
              overflow: "hidden",
            }}
          >
            <div
              className="fc-event-title text-nowrap"
              style={{ paddingBottom: "3px" }}
            >
              {eventInfo.event.title}
            </div>
            <div className="d-flex">
              <div className="fc-event-time text-nowrap">
                {new Date(eventInfo.event.startStr).toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}{" "}
              </div>
              <div className="fc-event-title text-nowrap">
                {eventInfo.event.extendedProps.name}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="d-flex align-items-center "
            style={{
              overflow: "hidden",
              boxShadow: "0 0 0 1px white",
              width: "100%",
              borderRadius: "3px",
            }}
          >
            <div
              className="fc-daygrid-event-dot mx-1"
              style={{ color: `${eventInfo.borderColor}` }}
            ></div>
            <div
              className="fc-event-time text-center"
              style={{ lineHeight: "25px" }}
            >
              {new Date(eventInfo.event.startStr).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}{" "}
              {eventInfo.event.title}
            </div>
          </div>
        )}
      </>
    );
  };
  const handleClickBooking = (bookingId) => {
    setSelectedBooking(bookings.find((booking) => booking._id === bookingId));
    setShowBookingInfo(true);
  };

  return (
    <Sidebar>
      <BookingInfo
        show={showBookingInfo}
        handleClose={() => setShowBookingInfo(false)}
        booking={selectedBooking}
      />

      <NewBookingForm
        show={showNewBooking}
        handleClose={() => setShowNewBooking(false)}
        handleSubmit={createBooking}
        isLoading={creatingBook}
        error={errorCreatingBooking}
      />
      <PageHeader>
        <div className="d-flex justify-content-between">
          <h1 className="color-black w-content fs-4">Showings</h1>
          <NotificationsButton />
        </div>
      </PageHeader>
      <div className="pt-2">
        <Button
          variant="primary"
          onClick={() => console.log("handleShow")}
          id="canvasbtn"
          className="d-none"
        >
          Launch
        </Button>
        <div className="panels px-4 px-lg-5 py-3">
          {isLoading ? (
            <Center h={100}>
              <Loader />
            </Center>
          ) : isError ? (
            <div className="d-flex p-5 justify-content-center align-items-center">
              <div className="text-center">
                <h2 className="text-danger">Error Getting Bookings</h2>
                <p className="text-muted">
                  There was an issue fetching your bookings. Please try again
                  later.
                </p>
                <button
                  className="btn btn-primary my-3"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div
                className={`${
                  screenwidth > 755
                    ? "justify-content-between"
                    : "justify-content-end"
                } d-flex`}
              >
                {screenwidth > 755 && (
                  <div className="d-flex algin-items-content">
                    <Pagination>
                      <Pagination.Prev onClick={() => goPrev()} />
                      <p
                        className="px-4 py-2 fw-bold"
                        style={{ color: "#5f5f5f" }}
                      >
                        {currentMonth}
                      </p>
                      <Pagination.Next onClick={() => goNext()} />
                    </Pagination>
                  </div>
                )}
                <div className="fc-button-group">
                  <button
                    type="button"
                    title="Today"
                    aria-pressed="false"
                    className="fc-today-button fc-button fc-button-primary"
                    onClick={() => handleClickViewTypeButton("today")}
                    disabled=""
                  >
                    today
                  </button>
                  <button
                    type="button"
                    title="day view"
                    aria-pressed="true"
                    className={`${
                      currentViewType === "timeGridDay"
                        ? "fc-button-active"
                        : ""
                    } fc-timeGridDay-button fc-button fc-button-primary `}
                    onClick={() => handleClickViewTypeButton("day")}
                  >
                    day
                  </button>
                  <button
                    type="button"
                    title="week view"
                    aria-pressed="false"
                    className={`${
                      currentViewType === "timeGridWeek"
                        ? "fc-button-active"
                        : ""
                    } fc-timeGridWeek-button fc-button fc-button-primary `}
                    onClick={() => handleClickViewTypeButton("week")}
                  >
                    week
                  </button>
                  <button
                    type="button"
                    title="month view"
                    aria-pressed="false"
                    className={`${
                      currentViewType === "dayGridMonth"
                        ? "fc-button-active"
                        : ""
                    } fc-dayGridMonth-button fc-button fc-button-primary `}
                    onClick={() => handleClickViewTypeButton("month")}
                  >
                    month
                  </button>
                </div>
              </div>
              <FullCalendar
                ref={calendarRef}
                key={initialDate.toString()}
                height={`${screenwidth > 755 ? "100vh" : "800px"} `}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={currentViewType}
                headerToolbar={{
                  left: "",
                  center: "",
                  right: "",
                }}
                selectMirror={true}
                dayMaxEvents={true}
                themeSystem="Simplex"
                eventClick={(calEvent) => handleClickBooking(calEvent.event.id)}
                eventContent={(eventInfo) => renderEventContent(eventInfo)}
                events={bookings?.map((booking) => ({
                  id: booking._id,
                  title: booking.property?.address,
                  description: booking.property?.address,
                  extendedProps: {
                    name: `${booking.contact.firstName} ${booking.contact.lastName}`,
                  },
                  start: toZonedTime(
                    new Date(booking.startTime * 1000),
                    booking.localTimeZone
                  ),
                  end: toZonedTime(
                    new Date((booking.startTime + 15 * 60) * 1000),
                    booking.localTimeZone
                  ),
                  classNames:
                    booking.status === "archived"
                      ? "fc-event-bg-default"
                      : booking.property
                      ? getClassNameFromId(booking.property._id)
                      : "fc-event-bg-default",
                  borderColor:
                    booking.status === "archived"
                      ? "#646464"
                      : booking.property
                      ? getBorderColorFromId(booking.property._id)
                      : "#646464",
                  textColor: "#1F2327",
                }))}
                dateClick={(info) => console.log({ info })}
                initialDate={initialDate}
                eventTimeFormat={{
                  hour: "numeric",
                  minute: "2-digit",
                }}
                // timeZone="America/Los_Angeles"
                dayHeaderFormat={
                  currentViewType === "dayGridMonth"
                    ? {
                        weekday: "short",
                        // day: "numeric",
                        // month: "short",
                        // separator: "/",
                      }
                    : {
                        weekday: "short",
                        day: "numeric",
                        month: "short",
                        separator: "/",
                      }
                }
              />
            </div>
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default Showings;
