import React from "react";

const NewNotificationsCounter = ({ newNotifications = 0 }) => {
  return (
    <span className="notifications-counter position-absolute rounded-circle text-white">
      {newNotifications > 9 ? "+9" : newNotifications}
    </span>
  );
};

export default NewNotificationsCounter;
