import React from 'react'

function StatusText({ status, className }: { status: string, className: string }) {
  return (
    <div className="d-flex gap-2 align-items-center">
      <span
        className={`circle circle-${status ?? "received"} `}
      ></span>
      <span className={"text-capitalize " + className}>
        {status ?? "received"}
      </span>
    </div>

  )
}

export default StatusText