import { Auth } from "aws-amplify";
import axios from "axios";
import {
  hasLength,
  isEmail,
  isNotEmpty,
  matchesField,
  useForm,
} from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Button,
  Title,
  Text,
  Image,
  Container,
  SimpleGrid,
  Select,
  Stepper,
  Stack,
  Group,
  Center,
  Space,
  Box,
  Progress,
  Modal,
  Flex,
} from "@mantine/core";
import logo from "../../assests/images/logo-dark.svg";
import { useState } from "react";
import classes from "./SignUp.module.css";
import { Link, useNavigate } from "react-router-dom";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import { RiErrorWarningFill } from "react-icons/ri";
import { IMaskInput } from "react-imask";
import { auto } from "@popperjs/core";
import { AppButton } from "../../designSystem/AppButton";
import { CUSTOMER_SUPP_PHONE } from "../../constants";


function SignUp() {
  const [active, setActive] = useState(0);
  const [error, setError] = useState({ show: false, message: "" });
  const [success, setSuccess] = useState({ show: false, message: "" });
  const navigate = useNavigate();

  const websiteRegEx =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/;

  const phoneRegEx = /^\+\d{1,3}[- ]?\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;

  const requirements = [
    { re: /[0-9]/, label: "Includes number" },
    { re: /[a-z]/, label: "Includes lowercase letter" },
    { re: /[A-Z]/, label: "Includes uppercase letter" },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
  ];

  const isValidPassword = (password) =>
    requirements.find((regex) => !regex.re.test(password)) === undefined;

  const form = useForm({
    initialValues: {
      companyName: "",
      website: "",
      units: "",
      firstName: "",
      lastName: "",
      jobTitle: "",
      phone: "",
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
    },

    validate: {
      companyName: (value) => {
        if (active === 3 && value.length > 0) {
          return hasLength(
            { min: 1, max: 100 },
            "Company name must be 1-100 characters long"
          )(value);
        }
        return null;
      },
  
      website: (value) => {
        if (active === 4 && value.length > 0) {
          return websiteRegEx.test(value)
            ? null
            : "Invalid URL";
        }
        return null;
      },
  
      units: (value) => {
        if (active === 3 && value.length > 0) {
          return isNotEmpty("Select number of units")(value);
        }
        return null;
      },
  
      firstName: (value) => {
        if (active === 2 && value.length > 0) {
          return hasLength(
            { min: 2, max: 100 },
            "Name must be 2-100 characters long"
          )(value);
        }
        return null;
      },
  
      lastName: (value) => {
        if (active === 2 && value.length > 0) {
          return hasLength(
            { min: 2, max: 100 },
            "Last name must be 2-100 characters long"
          )(value);
        }
        return null;
      },
  
      jobTitle: (value) => {
        if (active === 3 && value.length > 0) {
          return hasLength(
            { max: 100 },
            "Job title must be less than 100 characters long"
          )(value);
        }
        return null;
      },
  
      phone: (value) => {
        if (active === 3 && value.length > 0) {
          return phoneRegEx.test(value) ? null : "Invalid phone";
        }
        return null;
      },
  
      code: (value) => {
        if (active === 1 && value.length > 0) {
          return hasLength({ min: 6, max: 6 }, "Confirmation Code must be 6 characters long")(value)
        }
        return null;
      },
  
      email: (value) => {
        if (active === 0 && value.length > 0) {
          return isEmail("Invalid email")(value);
        }
        return null;
      },
  
      password: (value) => {
        if (active === 2 && value.length > 0) {
          return isValidPassword(value) ? null : "Invalid password";
        }
        return null;
      },
  
      confirmPassword: (value) => {
        if (active === 2 && value.length > 0) {
        if (value !== form.values.password) {
          return "Passwords do not match"; 
        }
        return null
        }
      }
    },
  });

  const sendConfirmationEmail = async (email) => {
    let url = process.env.REACT_APP_NODE_API_URL + "/api/users/request-code";
    await axios
      .post(url, { email })
      .then(async (response) => {
        setSuccess({ show: true, message: response?.data?.message });
        setActive(active + 1);
      })
      .catch((error) => {
        setActive(active)
        console.log(error.response);
        setError({ show: true, message: error?.response?.data?.message });
      });
  };

  const verifyEmail = async (email, code) => {
    let url = process.env.REACT_APP_NODE_API_URL + "/api/users/verify-code";
    await axios
      .post(url, { email, code })
      .then(async (response) => {
        setSuccess({ show: true, message: response?.data?.message });
        setActive(active + 1);
      })
      .catch((error) => {
        setActive(active)
        console.log(error.response);
        setError({ show: true, message: error?.response?.data?.message });
      });
  };

  const nextStep = async() => {
    form.validate()
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 4 ? current + 1 : current;
    });
    if (active === 0 && !form.validate().hasErrors) {
      await sendConfirmationEmail(form.values.email);
    }
    if(active === 1 && !form.validate().hasErrors) {
      await verifyEmail(form.values.email, form.values.code)
    }
  };

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const handleNextDisabled = () => {
    if (active === 1 && form.values.code === "") return true;
    if (active === 2 && (form.values.firstName === "" || form.values.lastName === "" || form.values.password === "" || form.values.confirmPassword === "")) return true;
    if (active === 3 && (form.values.companyName === "" || form.values.units === "" || form.values.phone === "")) return true;
    return false;
  };

  function PasswordRequirement({ meets, label }) {
    return (
      <Text component="div" c={meets ? "teal" : "red"} mt={5} size="sm">
        <Center inline>
          {meets ? (
            <CiCircleCheck size="0.9rem" stroke={1.5} />
          ) : (
            <CiCircleRemove size="0.9rem" stroke={1.5} />
          )}
          <Box ml={7}>{label}</Box>
        </Center>
      </Text>
    );
  }

  function getStrength(password) {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
      if (!requirement.re.test(password)) {
        multiplier += 1;
      }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
  }

  const strength = getStrength(form.values.password);
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(form.values.password)}
    />
  ));
  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ section: { transitionDuration: "0ms" } }}
        value={
          form.values.password.length > 0 && index === 0
            ? 100
            : strength >= ((index + 1) / 4) * 100
            ? 100
            : 0
        }
        color={strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"}
        key={index}
        size={4}
      />
    ));

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.validate().hasErrors) {
      return;
    }
    let url = process.env.REACT_APP_NODE_API_URL + "/api/users/register";
    if(active === 3) {
      await axios
        .post(url, {
          ...form.values,
          phone: form.values.phone.replace(/[^\d+]/g, ""),
        })
        // .then((response) => {
        //   console.log(response);
        //   Auth.signIn(form.values.email, form.values.password);
        // })
        .then(() => {
          navigate("/approval");
        })
        .catch((error) => {
          console.log(error.response);
          setError({ show: true, message: error?.response?.data?.message });
        });
    }
  };

  return (

    <Container p={0} m={0} fluid>
  <Flex justify="space-between" align="flex-start" className={classes.content} gap={20}>
    <Container style={{ flex: 2 }} >
      {/* Logo and Title */}
      <SimpleGrid dir="row" cols={2} mb={"xl"} w={"100%"} mt={"20px"}>
        <Title order={2}>
          <Image src={logo} alt="Logo" w={100} />
        </Title>
      </SimpleGrid>
      
      {/* Create Account Header */}
      <Flex direction="column" justify="center" align="center" className={classes.leftContainer} >  
        <Title className={classes.title} order={3} align="center">
          Create your account
        </Title>
        <Text align="center" size="sm" className={classes.subtitle}>Welcome to Delet, your hub for easy property management!</Text>
        <Space h="xl" />
        
        {/* Form Section */}
        <form onSubmit={handleSubmit} className={classes.form}>
          <Stepper 
              active={active} 
              size="xs"
              styles={{
                step: {
                  display: 'none',
                }, 
                separator: {
                  display: 'none'
                }
              }}
          >
            {/* Step 1 - Email Verification */}
            <Stepper.Step>
              <Text align="start" weight={500} size="lg" className={classes.labelStep}>Email Verification</Text>
              <div className={classes.bottomLine}></div>
              <TextInput
                classNames={{
                  label: classes.textInputLabel,
                  input: classes.textInputInput,
                }}
                label="Email"
                // size="md"
                radius="md"
                placeholder="name@company.com"
                {...form.getInputProps("email")}
              />
            </Stepper.Step>
            
            {/* Step 2 - Confirmation Code */}
            <Stepper.Step>
              <Text align="start" weight={500} size="lg" className={classes.labelStep}>Email Verification</Text>
              <div className={classes.bottomLine}></div>
              <p className={classes.description}>We will send you an email with a 6 digit code</p>
              <TextInput
                classNames={{
                  label: classes.textInputLabel,
                  input: classes.textInputInput,
                }}
                label="Code"
                // size="md"
                radius="md"
                placeholder="000000"
                maxLength={6}
                {...form.getInputProps("code")}
              />
            </Stepper.Step>
            
            {/* Step 3 - Personal Information */}
            <Stepper.Step>
              <Text align="left" weight={500} size="lg" className={classes.labelStep}>Personal Information</Text>
              <div className={classes.bottomLine}></div>
              
                <TextInput
                  classNames={{
                    label: classes.textInputLabel,
                    input: classes.textInputInput,
                    required: classes.textInputRequired
                  }}
                  label="First name"
                  // size="md"
                  radius="md"
                  placeholder="Your first name"
                  withAsterisk
                  {...form.getInputProps("firstName")}
                />
                <TextInput
                  classNames={{
                    label: classes.textInputLabel,
                    input: classes.textInputInput,
                    required: classes.textInputRequired
                  }}
                  label="Last name"
                  // size="md"
                  radius="md"
                  mt="md"
                  placeholder="Your last name"
                  withAsterisk
                  {...form.getInputProps("lastName")}
                />

              <PasswordInput
                classNames={{
                  label: classes.textInputLabel,
                  input: classes.textInputInput,
                  required: classes.textInputRequired
                }}
                label="Password"
                type="password"
                // size="md"
                radius="md"
                mt="md"
                withAsterisk
                placeholder="Enter a password"
                {...form.getInputProps("password")}
              />
              {form.values.password.length ? (
                  <>
                    <Group gap={5} grow mt="xs" mb="md">
                      {bars}
                    </Group>

                    <PasswordRequirement
                      label="Has at least 6 characters"
                      meets={form.values.password.length > 5}
                    />
                    {checks}
                  </>
                ) : null}
              <PasswordInput
                classNames={{
                  label: classes.textInputLabel,
                  input: classes.textInputInput,
                  required: classes.textInputRequired
                }}
                label="Confirm Password"
                type="password"
                // size="md"
                radius="md"
                mt="md"
                withAsterisk
                placeholder="Confirm password"
                {...form.getInputProps("confirmPassword")}
              />
            </Stepper.Step>

            {/* Step 4 - Company Information */}
            <Stepper.Step>
              <Text align="left" weight={500} size="lg" className={classes.labelStep}>Company Information</Text>
              <div className={classes.bottomLine}></div>
              <TextInput
                classNames={{
                  label: classes.textInputLabel,
                  input: classes.textInputInput,
                  required: classes.textInputRequired
                }}
                label="Company name"
                // size="md"
                radius="md"
                placeholder="Your company"
                withAsterisk
                {...form.getInputProps("companyName")}
              />
              <TextInput
                classNames={{
                  label: classes.textInputLabel,
                  input: classes.textInputInput,
                }}
                label="Job Title"
                // size="md"
                radius="md"
                mt="md"
                placeholder="Your position in the company"
                {...form.getInputProps("jobTitle")}
              />
              <Select
                classNames={{
                  label: classes.textInputLabel,
                  input: classes.textInputInput,
                  required: classes.textInputRequired
                }}
                label="Number of units"
                // size="md"
                radius="md"
                mt="md"
                data={["0-1,000 units", "1,000-10,000 units", "10,000+ units"]}
                withAsterisk
                {...form.getInputProps("units")}
              />
              <TextInput
              classNames={{
                label: classes.textInputLabel,
                input: classes.textInputInput,
                required: classes.textInputRequired
              }}
                label="Phone number"
                // size="md"
                radius="md"
                mt="md"
                placeholder="Your contact number"
                withAsterisk
                component={IMaskInput}
                mask="+0 (000) 000-0000"
                {...form.getInputProps("phone")}
              />
            </Stepper.Step>

            {/* Completed */}
            <Stepper.Completed>
              <Container my={50}>
                <Stack align="center">
                  <Title ta="center">Welcome!</Title>
                  <Space h="xl" />
                  <Button ta="center" size="md" onClick={() => navigate("/property/add")}>
                    Create your first property
                  </Button>
                  <Space h="xl" />
                  <Text ta="center">If you have any further question please contact us!</Text>
                  <Text ta="center">Email: sales@delet.com or call {CUSTOMER_SUPP_PHONE}</Text>
                </Stack>
              </Container>
            </Stepper.Completed>
          </Stepper>
          <Group justify="center" className="button-container" mt="xl" mb="lg">
            {/* {active !== 0 && active !== 4 && (
              <AppButton className={classes.button} onClick={prevStep}>Back</AppButton>
            )} */}
            {active === 3 && active !== 4 && (
              <AppButton className={classes.buttonSign}  disabled={handleNextDisabled()} type="submit">{"Sign Up >"}</AppButton>
            )}
            {active !== 3 && active !== 4 && (
              <AppButton  className={classes.button} disabled={handleNextDisabled()} onClick={nextStep}>{"Continue >"}</AppButton>
            )}
          </Group>
            <Text size="sm" ta="center" className={classes.bottomText}>
              Already have an account? <Link to="/login" className={classes.linkLogin}>Log in</Link>
            </Text>
        </form>
      </Flex>
    </Container>

    {/* Image Section */}
    <Container className={`${classes.image} ${classes.hideOnSmall}`} style={{ flex: 1 }}>
      <SimpleGrid cols={1}>
        <Image
          src="/images/sign-up-image.png"
          // fit="contain"
          style={{
            height: "1000px",
            width: "100%"
          }}
          p={0}
          m={0}
        />
      </SimpleGrid>
    </Container>
  </Flex>

  {/* Modals */}
  <Modal
    size="md"
    // title="Error Signing Up"
    opened={error.show}
    onClose={() => setError({ show: false, message: "" })}
    centered
  >
    <div className={classes.signModal}>
      <RiErrorWarningFill size={50} color="#5271FF"/>
      <h2 className={classes.modalTitle}>Error Signing Up</h2>
      <p className={classes.modalDesc}>{error.message}</p>
      {error.message === "User already exists" ? 
      <>
        <AppButton onClick={() => navigate('/login')} style={{ margin: '1rem 0', width:'80%'}}>
          Login
        </AppButton>
      </>
      : <></>}
     
    </div>
  </Modal>

  <Modal
    size="md"
    opened={success.show}
    centered
    onClose={() => setSuccess({ show: false, message: "" })}
  >
    <div className={classes.signModal}>
      <RiErrorWarningFill size={50} color="#5271FF"/>
      <h2 className={classes.modalTitle}>Signing Up Success</h2>
      <p className={classes.modalDesc}>{success.message}</p>
    </div>
  </Modal>
</Container>
   
  );
}

export default SignUp;
