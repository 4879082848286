import React, { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { Col, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { Auth } from "aws-amplify";
import useViewport from "../../../hooks/useViewport";
import MobileAccountUsers from "../../../components/Mobile-AccountUsers";
import { Center, Loader, rem, Select, TextInput } from "@mantine/core";
import { IoMdClose, IoMdRefresh, IoMdSearch } from "react-icons/io";
import { BsSliders2 } from "react-icons/bs";
import { useGeneral } from "../../../context/GeneralContext";
import { useProfile } from "../../../context/UserProfileContext";
import { Button } from "@mui/material";
// import Sidebar from "../../../components/SideBar";

const Users = ({ setIsInviteNewUser, setPersonalUserId }) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [selectValue, setselectValue] = useState([]);
  const [Value, setValue] = useState(true);
  const [error, setError] = useState("");
  const [q, setQ] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setAlertModal } = useGeneral();
  const { data = {}, refetch } = useProfile();
  const [searchParam] = useState([
    "email",
    "firstName",
    "lastName",
    "role",
    "active",
  ]);
  const options = [
    {
      value: "admin",
      label: "Admin",
    },
    {
      value: "agent",
      label: "Agent",
    },
  ];

  const { width: screenWidth } = useViewport();

  const showAlert = useCallback(
    (title, content) => {
      setAlertModal({ show: true, title, content });
    },
    [setAlertModal]
  );

  const getUserList = useCallback(
    (token) => {
      var url = process.env.REACT_APP_NODE_API_URL + "/api/users/agent/list";
      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          if (response.status === 200) {
            setList(response.data);
            setIsLoading(true);
          } else {
            showAlert("Error", "Error Getting Users");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut()
              .then(() => {
                navigate("/admin/login");
              })
              .catch((error) => {
                console.log("Error signing out: ", error);
              });
          } else {
            showAlert("Error", "Error Getting Users");
          }
        });
    },
    [navigate, showAlert]
  );

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }

  function handleorder(e) {
    if (e === "") {
    } else {
      setselectValue(e);
      setValue(false);
    }
  }

  useEffect(() => {
    refetch();
    async function getTokenForUserList() {
      try {
        const token = await Auth.currentSession();
        if (!token) {
          setError(error);
          navigate("/admin/login");
        }
        getUserList(token.getIdToken().getJwtToken());
      } catch (error) {
        setError(error);
        navigate("/admin/login");
      }
    }
    getTokenForUserList();
  }, [error, getUserList, navigate, refetch]);

  return (
    <>
      <div className="align-items-center">
        <Row className="align-items-center">
          <Col xl={6}>
            <div className="d-flex align-items-center flex-wrap flex-md-nowrap pb-1">
              <TextInput
                placeholder="Search..."
                className="flex-grow-1 w-75"
                style={{ borderRadius: "20px" }}
                name="search"
                leftSection={
                  <IoMdSearch
                    style={{
                      width: rem(24),
                      height: rem(24),
                      cursor: "pointer",
                    }}
                    stroke={1.5}
                  />
                }
                value={q}
                onChange={(e) => setQ(e.target.value)}
                rightSection={
                  <IoMdClose
                    style={{
                      width: rem(24),
                      height: rem(24),
                      cursor: "pointer",
                    }}
                    onClick={() => setQ("")}
                  />
                }
              />
            </div>
          </Col>
          <Col xl={6} className="d-flex justify-content-between pb-1">
            <div className="d-flex col-12 align-items-center justify-content-end col-9">
              <div className="me-2" style={{ width: rem(32), height: rem(32) }}>
                <IoMdRefresh
                  className="refresh"
                  onClick={() => {
                    setQ("");
                    setselectValue("");
                    setValue(true);
                  }}
                />
              </div>
              <Select
                style={{ maxWidth: "150px", marginRight: "12px" }}
                value={selectValue}
                data={options}
                onChange={(e) => handleorder(e)}
                placeholder="Filter By"
                leftSection={
                  <BsSliders2
                    style={{
                      width: rem(15),
                      height: rem(15),
                      cursor: "pointer",
                    }}
                    stroke={1.5}
                  />
                }
              />

              {["admin", "superadmin"].includes(data.role) && (
                <Button
                  // to="/account/user/create"
                  variant="contained"
                  onClick={() => setIsInviteNewUser(true)}
                  size="sm"
                  className=" invite-new-user "
                >
                  Invite User
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>

      {screenWidth > 755 ? (
        <>
          {isLoading === false ? (
            <Center h={100}>
              <Loader />
            </Center>
          ) : (
            <Table responsive className="property-table">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {search(
                  list.filter((list) => {
                    if (Value === false) {
                      return list.role === selectValue;
                    } else {
                      return true;
                    }
                  })
                ).map((element, index) => (
                  <tr
                    key={element._id}
                    onClick={
                      () =>
                        ["admin", "superadmin"].includes(data?.role)
                          ? setPersonalUserId(element._id)
                          : ""
                      // navigate(`/account/user/details/${element._id}`)
                    }
                  >
                    <td>
                      <Link className="properties-details-link text-decoration-none text-black">
                        <p style={{ fontSize: "15px" }}>
                          {element.firstName.charAt(0).toUpperCase() +
                            element.firstName.slice(1)}{" "}
                          {element.lastName.charAt(0).toUpperCase() +
                            element.lastName.slice(1)}{" "}
                          <span style={{ color: "#959595" }}>
                            {data.email === element.email && "( You )"}
                          </span>
                        </p>
                      </Link>
                    </td>
                    <td>
                      <span className="table-phone color-black py-2">
                        {element.phone}
                      </span>
                    </td>
                    <td>
                      <span className="text-nowrap">
                        <span className="table-mail ">{element.email}</span>
                      </span>
                    </td>
                    <td>
                      <span
                        className={`border rounded-5 text-capitalize px-3 py-1 role-badge ${element.role}`}
                      >
                        {element.role}
                      </span>
                    </td>
                    <td>
                      <span
                        className={`rounded-5 text-capitalize px-3 py-1 role-status ${
                          element.pending === true ? "pending" : "active"
                        }`}
                      >
                        {element.pending === true ? "Pending" : "Active"}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </>
      ) : (
        <MobileAccountUsers
          Value={Value}
          setPersonalUserId={setPersonalUserId}
          selectValue={selectValue}
          q={q}
        />
      )}
    </>
  );
};

export default Users;
