import React from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import styled from 'styled-components';

const StyledTabs = styled(ReactBootstrap.Tabs)`
    display: flex;
    align-items: center;
    justify-content:space-evenly;
    & .nav-item > .nav-link {
        
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        display: flex;
        padding: 8px 8px;
        justify-content: center;
        align-items: center;
        gap: 0px;
        color: #000000;
        &.active   {
            background: #4C4D52;
            color: #FFFFFF;
        }
        &:hover   {
            background: #FFFFFF;
            color: #000000;
        }
        /* content-div bg-white */
    }
`

const MobileTabs = (props) => <StyledTabs {...props}>{props.children}</StyledTabs>

export default MobileTabs;
