import React, { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Auth } from "aws-amplify";
import { IoMdPerson } from "react-icons/io";
import { Center, Loader } from "@mantine/core";
import { useGeneral } from "../context/GeneralContext";
import { useProfile } from "../context/UserProfileContext";

const MobileAccountUsers = ({ setPersonalUserId, selectValue, Value, q }) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [error, setError] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const { setAlertModal } = useGeneral();
  const { data = {}, refetch } = useProfile();

  const [searchParam] = useState([
    "email",
    "firstName",
    "lastName",
    "role",
    "active",
  ]);

  const showAlert = (title, content) => {
    setAlertModal({ show: true, title, content });
  };

  const getUserList = useCallback(
    (token) => {
      var url = process.env.REACT_APP_NODE_API_URL + "/api/users/agent/list";
      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          if (response.status === 200) {
            setList(response.data);
            setIsLoader(true);
          } else {
            showAlert("Error", "Error Getting Users");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut()
              .then(() => {
                navigate("/admin/login");
              })
              .catch((error) => {
                console.log("Error signing out: ", error);
              });
          } else {
            showAlert("Error", "Error Getting Users");
          }
        });
    },
    [navigate]
  );

  const search = (items) => {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  };

  useEffect(() => {
    async function getTokenForUserList() {
      try {
        const token = await Auth.currentSession();
        if (!token) {
          setError(error);
          navigate("/admin/login");
        }
        getUserList(token.getIdToken().getJwtToken());
      } catch (error) {
        setError(error);
        navigate("/admin/login");
      }
    }
    getTokenForUserList();
  }, [error, getUserList, navigate]);

  return (
    <>
      {isLoader === false ? (
        <Center h={100}>
          <Loader />
        </Center>
      ) : (
        <div className="pt-4">
          {search(
            list.filter((list) => {
              if (Value === false) {
                return list.role === selectValue;
              } else {
                return true;
              }
            })
          ).map((element) => (
            <div
              key={element._id}
              className="d-flex flex-column py-4 border-bottom justify-content-center gap-2"
              onClick={() => {
                setPersonalUserId(element._id);
                // navigate(`/account/user/details/${element._id}`);
              }}
            >
              <span
                className={`w-content border rounded-5 text-capitalize px-3 py-1 role-badge ${element.role}`}
              >
                {element.role}
              </span>
              <div className="d-flex flex-column">
                <div className="text-nowrap">
                  <IoMdPerson className="icon" />
                  <span className="mx-2">
                    {element.firstName} {element.lastName}{" "}
                    <span style={{ color: "#959595" }}>
                      {data.email === element.email && "( You )"}
                    </span>
                  </span>
                </div>

                <div className=" color-black">{element.phone}</div>

                <div className="text-nowrap table-mail">{element.email}</div>
              </div>
              <div className="d-flex align-items-center">
                <span
                  className={`border rounded-5 text-capitalize px-3 py-1 role-status ${
                    element.pending === true ? "pending" : "active"
                  }`}
                >
                  {element.pending === true ? "Pending" : "Active"}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default MobileAccountUsers;
