import { rem, TextInput } from "@mantine/core";
import { Button, CloseButton } from "react-bootstrap";
import { IoMdSearch } from "react-icons/io";
import { CiExport } from "react-icons/ci";
import OpenFilterButton from "./OpenFilterButton";

const LeadsFilter = ({
  handleKeyDown,
  searchParam,
  handleSearchTerm,
  handleReset,
  handleResetFilters,
  handleExport,
  handleApplyFilters,
  byProperty,
}) => {
  return (
    <div className="d-flex gap-2 gap-lg-5 justify-content-between">
      {handleKeyDown && (
        <TextInput
          placeholder="Search..."
          className="col col-lg-6"
          radius={6}
          name="search"
          onKeyDown={handleKeyDown}
          leftSection={
            <IoMdSearch
              style={{ width: rem(16), height: rem(16), cursor: "pointer" }}
              stroke={1.5}
              onClick={(e) => handleSearchTerm(e, true)}
              className="d-none d-lg-block"
            />
          }
          value={searchParam}
          onChange={handleSearchTerm}
          rightSection={
            <CloseButton onClick={handleReset} className="d-none d-lg-block" />
          }
        />
      )}

      <div className="d-flex justify-content-end gap-3 col-lg">
        <button
          onClick={handleResetFilters}
          className="border-0 no-bg text-body-tertiary small-text"
        >
          Clear All
        </button>

        <OpenFilterButton
          handleApplyFilters={handleApplyFilters}
          className="px-lg-5 bg-secondary-subtle border border-dark-subtle position-relative"
          variant="light"
          text="Filter by"
          byProperty={byProperty}
        />

        {handleExport && (
          <Button
            onClick={handleExport}
            variant="light"
            className="border bg-secondary-subtle border-dark-subtle"
          >
            <CiExport size="14px" className="mr-4" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default LeadsFilter;
