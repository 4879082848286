import React from "react";
import { augustId } from "../../../constants";

const NotificationTitle = ({ notification }) => {
  const { type } = notification;
  const [device, action] = type.split("_");
  const title =
    action === "LOCK" ? (
      `Lock ${notification.device.name} at ${
        notification.property?.address
      } was locked by ${
        augustId[notification.metadata.user.firstName] ??
        notification.metadata.user.firstName ??
        "unknown"
      } ${device === "KEYPAD" ? "code" : ""}`
    ) : action === "UNLOCK" ? (
      `Lock ${notification.device.name} at ${
        notification.property?.address
      } was unlocked by ${
        augustId[notification.metadata.user.firstName] ??
        notification.metadata.user.firstName ??
        "unknown"
      } ${device === "KEYPAD" ? `code` : ""}`
    ) : action === "LOAD" ? (
      <>
        New access code: {notification.metadata.user.firstName}
        <br />
        Code: {notification.metadata.pin.code}
      </>
    ) : action === "DELETE" ? (
      <>
        Access code deleted: {notification.metadata.user.firstName}
        <br />
        code: {notification.metadata.pin.code}
      </>
    ) : (
      "Unknown notification"
    );
  return <span className="fw-semibold lh-sm text-wrap">{title}</span>;
};

export default NotificationTitle;
