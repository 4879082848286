import { useQuery } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import React, { createContext, useContext, useState } from 'react';
import { SocketContext } from './SocketContext';

const ProfileContext = createContext();

const ProfileProvider = ({ children }) => {
  const socket = useContext(SocketContext);
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const user = await Auth.currentAuthenticatedUser();
      const attributes = user.attributes;
      const groups = user.signInUserSession.idToken.payload['cognito:groups'];

      const userInfo = {
        name: attributes.name,
        familyName: attributes.family_name,
        email: attributes.email,
        role: groups && groups.length > 0 ? groups[0] : null,
      };

      const response = await fetch(`${process.env.REACT_APP_NODE_API_URL}/api/users/profile`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user profile');
      }

      const dbUserData = await response.json();

      socket.emit('join', dbUserData._id);

      return {
        ...userInfo,
        ...dbUserData,
      };
    },
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    enabled: Boolean(Auth.currentUserInfo),
  });


  return (
    <ProfileContext.Provider value={{ data, isLoading, error, refetch }}>
      {children}
    </ProfileContext.Provider>
  );
};

const useProfile = () => {
  return useContext(ProfileContext);
};

export { ProfileProvider, useProfile };