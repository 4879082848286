import React from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import styled from 'styled-components';

const StyledTabs = styled(ReactBootstrap.Tabs)`
    display: flex;
    align-items: flex-end;
    gap: 8px;
    padding-right: -12px;
    & .nav-item > .nav-link {
        
        font-size: 12.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.14px;

        display: flex;
        padding: 6px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #4C4D52;
        &.active   {
            background: #4C4D52;
            color: #FFFFFF;
        }
        &:hover   {
            background: #FFFFFF;
            color: #4C4D52;
        }  
        /* content-div bg-white */
    }
`

const Tabs = (props) => <StyledTabs {...props}>{props.children}</StyledTabs>

export default Tabs;
