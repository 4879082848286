import React from "react";
import NotificationTitle from "./NotificationTitle";
import { format } from "date-fns-tz";
import { FaLock, FaUnlock } from "react-icons/fa6";
import { useInView } from "react-intersection-observer";
import { TbDeviceRemote } from "react-icons/tb";

const NotificationCard = React.forwardRef(
  ({ notification, page, index, markAsRead, ...props }, ref) => {
    const [device, action] = notification.type.split("_");
    const { ref: inViewRef } = useInView({
      threshold: 0.5,
      triggerOnce: true,
      skip: notification.read,
      onChange: (inView) => {
        if (inView && !notification.read) {
          markAsRead({
            notificationId: notification._id,
            pageIndex: page,
            notificationIndex: index,
          });
        }
      },
    });

    const setRefs = React.useCallback(
      (node) => {
        if (typeof ref === "function") ref(node);
        else if (ref) ref.current = node;
        if (!notification.read) inViewRef(node);
      },
      [ref, inViewRef, notification.read]
    );

    return (
      <div
        className={`py-3 d-flex align- gap-3 border-bottom ${
          notification.read ? "text-secondary" : ""
        }`}
        key={notification._id}
        ref={setRefs}
        {...props}
      >
        <div className="rounded-5 d-flex justify-content-center align-items-center bg-secondary bg-opacity-75 h-content notification-icon-container flex-shrink-0">
          {action === "UNLOCK" ? (
            <FaUnlock size={24} color="white" />
          ) : action === "LOCK" ? (
            <FaLock size={24} color="white" />
          ) : (
            <TbDeviceRemote size={30} color="white" />
          )}
        </div>

        <p className="d-flex align-items-center gap-3">
          <div className="w-100 d-flex flex-column gap-2">
            <NotificationTitle notification={notification} />
            <span className="fs-7 text-body-tertiary d-flex gap-3">
              {format(
                new Date(notification.createdAt * 1000),
                "MMM d hh:mm aaa"
              )}{" "}
              <span>•</span>Property
            </span>
          </div>
        </p>

        <div
          className={`d-flex justify-content-end col ${
            notification.read && "d-none"
          }`}
        >
          <span className="circle bg-delet"></span>
        </div>
      </div>
    );
  }
);

export default NotificationCard;
