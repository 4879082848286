import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { useProperty } from "../../../context/PropertyContext";
import ButtonUnderlineText from "../../../components/ButtonUnderlineText";
import { Copy } from "react-feather";
import { Link } from "react-router-dom";
import moment from "moment";

const StyledLink = styled(Link)`
  display: flex;
  height: 25px;
  justify-content: center;
  align-items: center;
  ${"" /* gap: 8px; */}

  white-space: nowrap;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.16px;
  text-decoration: underline;
  color: #5d5fef;

  &:hover,
  &:focus {
    color: #4143a7;
  }
  &:disabled {
    color: #bdbebf;
    background-color: #eaeaeb;
  }
  & > svg {
    height: 16px;
    width: 16px;
  }
`;

const CopyIcon = styled(Copy)`
  display: flex;
  flex-direction: row;
  color: #5d5fef;
  border: 1px solid;
  border-radius: 4px;
  padding: 4px;
  & svg {
    height: 16px;
    width: 16px;
  }
`;

const ContainerLink = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  gap: 8px;
  &:hover {
    ${CopyIcon} {
      color: #ffffff;
      background-color: #4143a7;
    }
    a {
      color: #4143a7;
    }
  }
`;

const MobileCard = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  background: #ffffff;
  border: none;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.25);
`;

const MobileCardSection = styled.div`
  display: flex;
  padding: 0px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
`;

const StyledSpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #4C4D52;
  text-transform: uppercase;
  letter-spacing: 0.22px;
`;

const MobileCardDetails = () => {
  const { property } = useProperty();

  return (
    <div className="">
      <Row className="">
        <Col xl={8} className="mb-3 mb-xl-0 pe-2">
          <MobileCard className="h-100">
            <h4 className="fs-6 color-gray fw-semibold">Features & Amenities</h4>
            <MobileCardSection>
              {property.amenitiesAndFeatures &&
              property.amenitiesAndFeatures.floorPlanHighlights.length > 0 ? (
                <Row className="d-flex flex-wrap justify-content-between w-100 gap-2 gap-xl-0">
                  <Col md={6} xl={4}>
                    <ul className="list-unstyled">
                      {property.amenitiesAndFeatures.floorPlanHighlights
                        .slice(0, 5)
                        .map((option, index) => {
                          return (
                            <li className="body-xs" key={index}>
                              <StyledSpan>
                                {option}
                              </StyledSpan>
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                  <Col md={6} xl={4}>
                    <ul className="list-unstyled">
                      {property.amenitiesAndFeatures.floorPlanHighlights
                        .slice(5, 10)
                        .map((option, index) => {
                          return (
                            <li className="body-xs" key={index}>
                              <StyledSpan className="tags-xs">
                                {option}
                              </StyledSpan>
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                  <Col md={6} xl={4}>
                    <ul className="list-unstyled">
                      {property.amenitiesAndFeatures.floorPlanHighlights
                        .slice(10, 16)
                        .map((option, index) => {
                          return (
                            <li className="body-xs" key={index}>
                              <StyledSpan className="tags-xs">
                                {option}
                              </StyledSpan>
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                </Row>
              ) : (
                <StyledSpan className="tags-xs">N/A</StyledSpan>
              )}
            </MobileCardSection>
            <MobileCardSection>
              {property.amenitiesAndFeatures &&
              property.amenitiesAndFeatures.kitchenFeatures.length > 0 ? (
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col sm={6} xl={4} className="d-flex align-items-center">
                    <ul className="list-unstyled">
                      {property.amenitiesAndFeatures.kitchenFeatures
                        .slice(0, 3)
                        .map((option, index) => {
                          return (
                            <li className="body-xs" key={index}>
                              <StyledSpan className="tags-xs">
                                {option}
                              </StyledSpan>
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                  <Col sm={6} xl={4} className="d-flex align-items-center">
                    <ul className="list-unstyled">
                      {property.amenitiesAndFeatures.kitchenFeatures
                        .slice(3, 6)
                        .map((option, index) => {
                          return (
                            <li className="body-xs" key={index}>
                              <StyledSpan className="tags-xs">
                                {option}
                              </StyledSpan>
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                  <Col sm={6} xl={4} className="d-flex align-items-center ">
                    <ul className="list-unstyled">
                      {property.amenitiesAndFeatures.kitchenFeatures
                        .slice(6, 8)
                        .map((option, index) => {
                          return (
                            <li className="body-xs" key={index}>
                              <StyledSpan className="tags-xs">
                                {option}
                              </StyledSpan>
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                </Row>
              ) : (
                <StyledSpan className="tags-xs">N/A</StyledSpan>
              )}
            </MobileCardSection>
            <MobileCardSection>
              <StyledSpan className="tags-xs">Building Features</StyledSpan>
              {property.amenitiesAndFeatures &&
              property.amenitiesAndFeatures.buildingFeatures.length > 0 ? (
                <Row className="d-flex justify-content-between w-100">
                  <Col sm={6} xl={4} className="d-flex align-items-center">
                    <ul className="list-unstyled">
                      {property.amenitiesAndFeatures.buildingFeatures
                        .slice(0, 3)
                        .map((option, index) => {
                          return (
                            <li className="body-xs" key={index}>
                              <StyledSpan className="tags-xs">
                                {option}
                              </StyledSpan>
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                  <Col sm={6} xl={4} className="d-flex align-items-center">
                    <ul className="list-unstyled">
                      {property.amenitiesAndFeatures.buildingFeatures
                        .slice(3, 6)
                        .map((option, index) => {
                          return (
                            <li className="body-xs" key={index}>
                              <StyledSpan className="tags-xs">
                                {option}
                              </StyledSpan>
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                  <Col sm={6} xl={4} className="d-flex align-items-center">
                    <ul className="list-unstyled">
                      {property.amenitiesAndFeatures.buildingFeatures
                        .slice(6, 10)
                        .map((option, index) => {
                          return (
                            <li className="body-xs" key={index}>
                              <StyledSpan className="tags-xs">
                                {option}
                              </StyledSpan>
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                </Row>
              ) : (
                <StyledSpan className="tags-xs">N/A</StyledSpan>
              )}
            </MobileCardSection>
          </MobileCard>
        </Col>
        <Col xl={8} className="mb-3 mb-xl-0 pe-2">
          <MobileCard className="h-100">
            <h4 className="fs-6 color-gray fw-semibold">Pet Policies</h4>
            <MobileCardSection>
              <StyledSpan className="tags-xs">Pets Allowed?</StyledSpan>

              <StyledSpan className="tags-xs">
                {property.pets && property.pets.allowed
                  ? "Yes"
                  : property.pets && !property.pets.allowed
                  ? "No"
                  : "N/A"}
              </StyledSpan>
            </MobileCardSection>

            <MobileCardSection>
              <StyledSpan className="tags-xs">Monthly Pet Rent</StyledSpan>
                <StyledSpan className="tags-xs">
                  {property.pets && property.pets.monthlyPetRent
                    ? `$${property.pets.monthlyPetRent}`
                    : "N/A"}
                </StyledSpan>

            </MobileCardSection>
            <MobileCardSection>
              <StyledSpan className="tags-xs">Dog Policies</StyledSpan>
              <Row className="d-flex gap-2 gap-xl-0">
                <StyledSpan className="tags-xs">
                  {property.pets && property.pets.dogs.allowed
                    ? `${property.pets.dogs.maxAllowed}`
                    : property.pets && !property.pets.dogs.allowed
                    ? "N/A"
                    : "N/A"}
                </StyledSpan>
              </Row>
            </MobileCardSection>
            <MobileCardSection>
              <StyledSpan className="tags-xs">Cat policies</StyledSpan>
              <Row className="d-flex gap-2 gap-xl-0">
                <StyledSpan className="tags-xs">
                  {property.pets && property.pets.dogs.allowed
                    ? `${property.pets.dogs.maxAllowed}`
                    : property.pets && !property.pets.dogs.allowed
                    ? "N/A"
                    : "N/A"}
                </StyledSpan>
              </Row>
            </MobileCardSection>
            <MobileCardSection>
              <StyledSpan className="tags-xs">Pet Limit</StyledSpan>
              <Row className="d-flex gap-2 gap-xl-0">
                <StyledSpan className="tags-xs">
                  {property.pets && property.pets.cats.allowed
                    ? `${property.pets.cats.maxAllowed}`
                    : property.pets && !property.pets.cats.allowed
                    ? "N/A"
                    : "N/A"}
                </StyledSpan>
              </Row>
            </MobileCardSection>
            <MobileCardSection>
              <StyledSpan className="tags-xs">Weight Limit</StyledSpan>
              <Row className="d-flex gap-2 gap-xl-0">
                <StyledSpan className="tags-xs">
                  {property.pets && property.pets.cats.allowed
                    ? `${property.pets.cats.weight} lbs`
                    : property.pets && !property.pets.cats.allowed
                    ? "N/A"
                    : "N/A"}
                </StyledSpan>
              </Row>
            </MobileCardSection>

            <MobileCardSection>
              <StyledSpan className="tags-xs">Notes</StyledSpan>
              <Row className="d-flex gap-2 gap-xl-0">
                <StyledSpan className="tags-xs">
                  {property.pets && property.pets.notes
                    ? property.pets.notes
                    : "N/A"}
                </StyledSpan>
              </Row>
            </MobileCardSection>
          </MobileCard>
        </Col>
      </Row>

      <Row>
        <Col xl={8} className="mb-3 mb-xl-0 pe-2">
          <div className="d-flex flex-column gap-3 h-100">
            <MobileCard className="h-100">
              <h4 className="fs-6 color-gray fw-semibold">Lease Options</h4>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Lease Term(s)</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.leaseTermOptions &&
                    property.leaseTermOptions.length > 0
                      ? property.leaseTermOptions.join(", ")
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Listed Date</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.listedTs
                      ? moment(property.listedTs).format("MM/DD/YY")
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Available</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.dateAvailableTs
                      ? moment(property.dateAvailableTs).format("MM/DD/YY")
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              {property?.status && property.status === "rented" && (
                <MobileCardSection>
                  <StyledSpan className="tags-xs">Rented Date</StyledSpan>
                  <Row className="d-flex gap-2 gap-xl-0">
                    <StyledSpan className="tags-xs">
                      {property.rentedDate
                        ? moment(property.rentedDate).format("MM/DD/YY")
                        : "N/A"}
                    </StyledSpan>
                  </Row>
                </MobileCardSection>
              )}
              <MobileCardSection>
                <StyledSpan className="tags-xs">Application URL</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  {property.rentalApplicationForm &&
                  property.rentalApplicationForm.url ? (
                    <ContainerLink>
                      <ButtonUnderlineText
                        variantType="primary"
                        to={property.rentalApplicationForm.url}
                        target="_blank"
                      >
                        Application Form
                      </ButtonUnderlineText>

                      <CopyIcon />
                    </ContainerLink>
                  ) : (
                    <StyledSpan className="tags-xs">N/A</StyledSpan>
                  )}
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Application Fee</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.rentalApplicationForm &&
                    property.rentalApplicationForm.fee
                      ? `$${property.rentalApplicationForm.fee}`
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
            </MobileCard>
            <MobileCard className="h-100 mb-3 mb-xl-0">
              <h4 className="fs-6 color-gray fw-semibold">Property Management Contacts </h4>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Leasing Agent</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.leasingAgent &&
                    (property.leasingAgent.firstName ||
                      property.leasingAgent.lastName)
                      ? `${property.leasingAgent.firstName || ""}${
                          property.leasingAgent.firstName &&
                          property.leasingAgent.lastName
                            ? " "
                            : ""
                        }${property.leasingAgent.lastName || ""}`
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Phone</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.leasingAgent && property.leasingAgent.phone ? (
                      <StyledLink className="body-xs">
                        {property.leasingAgent.phone}
                      </StyledLink>
                    ) : (
                      "N/A"
                    )}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Email</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.leasingAgent && property.leasingAgent.email ? (
                      <StyledLink className="body-xs">
                        {property.leasingAgent.email}
                      </StyledLink>
                    ) : (
                      "N/A"
                    )}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">
                  <strong>Property Manager</strong>
                </StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.propertyManager &&
                    (property.propertyManager.firstName ||
                      property.propertyManager.lastName)
                      ? `${property.propertyManager.firstName || ""}${
                          property.propertyManager.firstName &&
                          property.propertyManager.lastName
                            ? " "
                            : ""
                        }${property.propertyManager.lastName || ""}`
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">On Site?</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.propertyManager && property.propertyManager.onSite
                      ? "Yes"
                      : property.propertyManager &&
                        !property.propertyManager.onSite
                      ? "No"
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Email</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.leasingAgent && property.leasingAgent.email ? (
                      <StyledLink className="body-xs">
                        {property.leasingAgent.email}
                      </StyledLink>
                    ) : (
                      "N/A"
                    )}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Phone</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.propertyManager &&
                    property.propertyManager.phone ? (
                      <StyledLink className="body-xs">
                        {property.propertyManager.phone}
                      </StyledLink>
                    ) : (
                      "N/A"
                    )}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">
                  <strong>Maintenance Manager</strong>
                </StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.maintenanceManager &&
                    (property.maintenanceManager.firstName ||
                      property.maintenanceManager.lastName)
                      ? `${property.maintenanceManager.firstName || ""}${
                          property.maintenanceManager.firstName &&
                          property.maintenanceManager.lastName
                            ? " "
                            : ""
                        }${property.maintenanceManager.lastName || ""}`
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">On Site?</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.maintenanceManager &&
                    property.maintenanceManager.onSite
                      ? "Yes"
                      : property.maintenanceManager &&
                        !property.maintenanceManager.onSite
                      ? "No"
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Phone</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.maintenanceManager &&
                    property.maintenanceManager.phone ? (
                      <StyledLink className="body-xs">
                        {property.maintenanceManager.phone}
                      </StyledLink>
                    ) : (
                      "N/A"
                    )}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Phone</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.maintenanceManager &&
                    property.maintenanceManager.phone ? (
                      <StyledLink className="body-xs">
                        {property.maintenanceManager.phone}
                      </StyledLink>
                    ) : (
                      "N/A"
                    )}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Email</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.maintenanceManager &&
                    property.maintenanceManager.email ? (
                      <StyledLink className="body-xs">
                        {property.maintenanceManager.email}
                      </StyledLink>
                    ) : (
                      "N/A"
                    )}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Management Co.</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.company && property.company.name
                      ? property.company.name
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Company Website</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  {property.company && property.company.website ? (
                    <StyledLink
                      className="body-xs"
                      to={property.company.website}
                      target="_blank"
                    >
                      {property.company.website}
                    </StyledLink>
                  ) : (
                    <StyledSpan className="tags-xs">N/A</StyledSpan>
                  )}
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Company Phone</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.company && property.company.phone ? (
                      <StyledLink className="body-xs">
                        {property.company.phone}
                      </StyledLink>
                    ) : (
                      "N/A"
                    )}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
            </MobileCard>
          </div>
        </Col>
        <Col xl={8} className="mb-3 mb-xl-0 pe-2">
          <div className="d-flex flex-column gap-3 h-100">
            <MobileCard className="h-100">
              <h4 className="fs-6 color-gray fw-semibold">Utilities</h4>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Water</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.utilities && property.utilities.water
                      ? property.utilities.water
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Electricity</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.utilities && property.utilities.electricity
                      ? property.utilities.electricity
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Gas</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.utilities && property.utilities.gas
                      ? property.utilities.gas
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Trash</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.utilities && property.utilities.trash
                      ? property.utilities.trash
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Sewage</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.utilities && property.utilities.sewage
                      ? property.utilities.sewage
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              {/* <MobileCardSection>
                <span className="tags-xs">Notes</span>
                <StyledSpan className="tags-xs">
                  {property.utilities && property.utilities.notes
                    ? property.utilities.notes
                    : "N/A"}
                </StyledSpan>
              </MobileCardSection> */}
            </MobileCard>

            <MobileCard className="h-100">
              <h4 className="fs-6 color-gray fw-semibold">Parking</h4>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Parking Type</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.parking && property.parking.type
                      ? property.parking.type
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              <MobileCardSection>
                <StyledSpan className="tags-xs">Parking Spaces</StyledSpan>
                <Row className="d-flex gap-2 gap-xl-0">
                  <StyledSpan className="tags-xs">
                    {property.parking && property.parking.spacesNumber
                      ? property.parking.spacesNumber
                      : "N/A"}
                  </StyledSpan>
                </Row>
              </MobileCardSection>
              {/* <MobileCardSection>
                <span className="tags-xs">Notes</span>
                <StyledSpan className="tags-xs">
                  {property.parking && property.parking.notes
                    ? property.parking.notes
                    : "N/A"}
                </StyledSpan>
              </MobileCardSection> */}
            </MobileCard>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MobileCardDetails;
