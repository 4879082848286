import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useGeneral } from "../../../context/GeneralContext";
import LeadsTable from "../../Leads/components/LeadsTable";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getContacts } from "../../../api/contacts";
import LeadsFilter from "../../Leads/components/LeadsFilter";
import moment from "moment";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useProperty } from "../../../context/PropertyContext";
import PagePagination from "../../../components/PagePagination";
import { updateBookingStatus } from "../../../api/bookings";

const Leads = () => {
  const params = useParams();
  const propertyId = params.id;
  const queryClient = useQueryClient();

  const { setLeadsCount } = useProperty();

  const { setAlertModal } = useGeneral();
  const [pageLimit, setPageLimit] = useState(7);
  const [searchParam, setSearchParam] = useState("");
  const [page, setPage] = useState(1);
  const [listOrder, setListOrder] = useState("desc");
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    search: "",
  });
  const [bookingsUpdating, setBookingsUpdating] = useState({});

  const memoizedFilters = useMemo(() => filters, [filters]);

  const {
    data = { data: [] },
    isLoading,
    isError,
  } = useQuery({
    queryKey: [
      "my-leads",
      page,
      pageLimit,
      memoizedFilters.startDate,
      memoizedFilters.endDate,
      memoizedFilters.search,
      [propertyId],
    ],
    queryFn: () =>
      getContacts({
        page,
        limit: pageLimit,
        order: listOrder,
        ...memoizedFilters,
        selectedProperties: [propertyId],
      }),
  });

  const { mutateAsync: updateStatus } = useMutation({
    mutationFn: async ({ bookingId, newStatus }) => {
      setBookingsUpdating((prev) => ({ ...prev, [bookingId]: true }));
      await updateBookingStatus(bookingId, newStatus);
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        [
          "my-leads",
          page,
          pageLimit,
          memoizedFilters.startDate,
          memoizedFilters.endDate,
          memoizedFilters.search,
          [propertyId],
        ],
        (oldData) => {
          const updatedData = {
            ...oldData,
            data: oldData.data.map((booking) =>
              booking.id === variables.bookingId
                ? { ...booking, status: variables.newStatus }
                : booking
            ),
          };
          return updatedData;
        }
      );
    },
    onSettled: (_, error, variables) => {
      setBookingsUpdating((prev) => ({
        ...prev,
        [variables.bookingId]: false,
      }));
    },
  });

  useEffect(() => {
    setLeadsCount({ all: data?.total });
  }, [data, setLeadsCount]);

  const handleKeyDown = (e) => {
    const { key } = e;
    if (key === "Enter") {
      e.preventDefault();
      setFilters({ ...filters, search: searchParam });
      setPage(1);
    }
  };

  const handleExport = (e, exportStartDate, exportEndDate) => {
    e.preventDefault();
    Auth.currentSession().then((token) => {
      let url = `${process.env.REACT_APP_NODE_API_URL}/api/contact/toCsv?`;

      url = `${url}&propertyIds=${propertyId}`;

      if (exportStartDate) {
        url = `${url}&startDate=${moment(exportStartDate).format(
          "YYYY-MM-DD"
        )}`;
      }
      if (exportEndDate) {
        url = `${url}&endDate=${moment(exportEndDate).format("YYYY-MM-DD")}`;
      }

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            var blob = new Blob([response.data], {
              type: "text/csv",
            });

            const a = document.createElement("a");
            a.download = "leads";
            a.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();
          }
        })
        .catch((error) => {
          console.log(error);
          setAlertModal({
            show: true,
            title: "Error",
            content: "There's no data to export",
          });
        });
    });
  };

  const handleSearchTerm = (e) => {
    if (e.target.value === "") setFilters({ ...filters, search: "" });
    setSearchParam(e.target.value);
  };

  const handleReset = () => {
    setPage(1);
    setSearchParam("");
    setFilters({
      ...filters,
      search: "",
    });
  };

  const handleNextPage = (nextPage) => {
    if (data.pages >= nextPage && nextPage > 0) {
      setPage(nextPage);
    }
  };

  const handleResetFilters = () => {
    setFilters({
      startDate: null,
      endDate: null,
    });
  };

  const handleApplyFilters = ({ selectedProperties, startDate, endDate }) => {
    const newFilters = {};
    if (startDate)
      newFilters.startDate = moment(startDate).format("YYYY-MM-DD");
    if (endDate) newFilters.endDate = moment(endDate).format("YYYY-MM-DD");
    if (selectedProperties)
      newFilters.selectedProperties = selectedProperties
        ?.map((property) => property._id)
        .join();

    setFilters(newFilters);
    setPage(1);
  };

  return (
    <div className="px-4 px-lg-5 pt-4">
      <LeadsFilter
        handleKeyDown={handleKeyDown}
        searchParam={searchParam}
        handleSearchTerm={handleSearchTerm}
        handleReset={handleReset}
        handleResetFilters={handleResetFilters}
        handleExport={handleExport}
        handleApplyFilters={handleApplyFilters}
        byProperty={false}
      />
      <LeadsTable
        isLoading={isLoading}
        leads={data?.data}
        bookingsUpdating={bookingsUpdating}
        updateStatus={updateStatus}
      />

      {data?.pages > 1 ? (
        <div className="py-3">
          <PagePagination
            totalPages={data.pages}
            actualPage={page}
            handlePageChange={handleNextPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Leads;
